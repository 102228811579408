import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Souscription } from 'src/app/_models/Souscription';
import { SouscriptionService } from 'src/app/_services/souscription.service';
import { PaymentMethodModalComponent } from '../../../_modals/payment-method-modal/payment-method-modal.component';

@Component({
  selector: 'app-souscription-third-step',
  templateUrl: './souscription-third-step.component.html',
  styleUrls: ['./souscription-third-step.component.scss']
})
export class SouscriptionThirdStepComponent implements OnInit {

  souscription: Souscription;
  id: string;
  constructor(private dialog: MatDialog, private souscriptionService: SouscriptionService, private router: Router, private route:ActivatedRoute) {
    this.id = this.route.snapshot.params.uid;
  }

  ngOnInit() {
    this.souscription = this.souscriptionService.getSouscription();
    if (!this.souscription) {
      this.souscriptionService.getSouscriptionByUID(this.id).subscribe(
        (souscription) => this.souscription = souscription,
        () => this.router.navigate(['error'])
      )
    }
  }

  openPayment(type: string) {
    if (type == 'mensuel') {
      this.router.navigate(['/souscription/paiement/banque/mensuel'])
    } else {
      this.dialog.open(PaymentMethodModalComponent, {
        id: type,
        height: 'max-content',
        width: 'max-content',
        data: { 'type': type }
      });
    }
  }

}
