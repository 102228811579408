import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-icon-list',
  templateUrl: './card-icon-list.component.html',
  styleUrls: ['./card-icon-list.component.scss']
})
export class CardIconListComponent implements OnInit {

  @Input() items: any[];

  constructor() { }

  ngOnInit() {
    this.items.forEach(item => {
      for (const [key, value] of Object.entries(item.parameters)) {
        let needle = new RegExp('#' + key, 'g');
        item.text = item.text.replace(needle, value['value']);
      }
    });
  }


}
