<label class="link">
   <div class="circle">
      <p class="pos">
         {{firstPartPrice}}<small *ngIf='secondPartPrice'>.{{secondPartPrice}}</small>€
         <br /><small>{{small}}</small>
      </p>
   </div>

   <div class="box align-items-center text-uppercase">
      <ng-content></ng-content>
   </div>

   <div class="small-box align-items-center">
      <span>
         Choisir
      </span>
   </div>
</label>