import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Error } from 'src/app/_models/Error';
import { Input } from 'src/app/_models/Input';
import { Souscription } from 'src/app/_models/Souscription';
import { SouscriptionService } from 'src/app/_services/souscription.service';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-quote-sixth-step',
  templateUrl: './quote-sixth-step.component.html',
  styleUrls: ['./quote-sixth-step.component.scss']
})


export class QuoteSixthStepComponent implements OnInit {

  souscription: Souscription;
  selectedInputValue: string;
  errors: Error[] = [];

  constructor(public souscriptionService: SouscriptionService,
    private router: Router) { }

  ngOnInit() {
    this.souscription = this.souscriptionService.getSouscription();
    this.souscriptionService.setCurrentInputs([
      new Input("preference_product", null, this.souscription?.product?.toString(), true, null, "401000"),
    ])
    this.setInputValue(this.souscription?.product?.toString());
    if(this.souscription?.locataire === false) {
      this.setInputValue('8c5af5cb');
     this.nextStep();
    }
  }

  setInputValue(value: any) {
    this.selectedInputValue = value;
    this.souscriptionService.setInputValue('preference_product', value, true);
  }

  nextStep() {
    if (this.souscriptionService.isCurrentStepValid && this.selectedInputValue)
      this.souscriptionService.nextStep(5).subscribe(
        () => { this.errors = []; this.router.navigate(['/demande-devis/informations-de-contact']); },
        err => { this.errors = err },
      );
  }
}
