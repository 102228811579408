<div class="quota-wrapper">
    <div class="quota-question">
        <app-agent imageSRC="../../../assets/spbfamily/Image 2@2x.png" agentSpeech="Bonjour, je m'appelle Claire.<br/>
                        Je vais réaliser votre devis, cela ne me prendra que 2 minutes.
                        Faisons connaissance !">
        </app-agent>
    </div>

    <div class="quota-reply">
        <div class="flex-50">
            <app-input-field [placeholder]="souscriptionService.currentInputs[0].placeholder"
                [name]="souscriptionService.currentInputs[0].name" [value]="souscriptionService.currentInputs[0].value"
                [pattern]="souscriptionService.currentInputs[0].pattern"
                [required]="souscriptionService.currentInputs[0].required"
                (valueEmitter)="souscriptionService.setInputValue($event.inputName, $event.inputValue, $event.isValid)">
            </app-input-field>
        </div>
        <div class="flex-50">
            <app-input-field [placeholder]="souscriptionService.currentInputs[1].placeholder"
                [name]="souscriptionService.currentInputs[1].name" [value]="souscriptionService.currentInputs[1].value"
                [pattern]="souscriptionService.currentInputs[1].pattern"
                [required]="souscriptionService.currentInputs[1].required"
                (valueEmitter)="souscriptionService.setInputValue($event.inputName, $event.inputValue, $event.isValid)">
            </app-input-field>
        </div>
    </div>

    <div class="quota-reply">
        <div class="center-item mt-2 text-center">
            <ng-container *ngIf='errors'>
                <ng-container *ngFor='let error of errors'><i class="fas fa-exclamation-circle"></i> &nbsp; <span
                        class="text-danger">{{
                        error.message }}</span>
                </ng-container>
                <br /><br />
            </ng-container>

            <app-global-button-small [disabled]='!souscriptionService.isCurrentStepValid'
                *ngIf='souscriptionService.isCurrentStepValid != null' (click)='nextStep()'>
                C'est parti
            </app-global-button-small>
        </div>
    </div>
</div>