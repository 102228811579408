<img src="./assets/spbfamily/home_bg.png" [hidden]='true' (load)='ImgLoaded = true'>

<ng-container *ngIf='ImgLoaded'>
<app-side-cookie-modal></app-side-cookie-modal>
<app-hero-header></app-hero-header>
<app-testimonials-header></app-testimonials-header>
<app-assurence-expo></app-assurence-expo>
<app-comparison-table></app-comparison-table>
<app-spbf-granties></app-spbf-granties>
<app-footer></app-footer>
</ng-container>


<ng-container *ngIf='!ImgLoaded'>
    <div class="spinner-wrapper">

        <div class="center-box">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      
      </div>
</ng-container>
