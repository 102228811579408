<div class="container">
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="highlight">
        <div class="icon">
          <img src="./assets/agreement.svg" width="40px" />
        </div>
        <p>
          Contrat conclu !<br />Vous êtes officiellement un de nos adhérents !
        </p>
      </div>
    </div>

    <div class="col-sm-12 col-md-6 mt-4 mb-5">
      <br />
      <h1>J'ai assuré !</h1>
      <br />
      <p>
        Un mail vient de vous être envoyé avec vos identifiants de connexion à
        votre espace personnel à partir duquel vous pourrez télécharger vos
        documents contractuels.
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <h2 class="primary-color underline">Récapitulons ensemble :</h2>
      <p><strong>Votre garantie</strong></p>
      
    </div>
  </div>

  <div class="row" style="margin-left: 2px;">
    <div class="col-sm-12 p-0 col-md-6">
      <p class="box">
        Vous avez choisi <strong>l'{{ souscription.nameProduct }}</strong> d'un
        montant de
        <strong>{{ souscription.tarifMensuel }} &euro;/ mois.</strong>
      </p>

      <div class="mt-3 mb-3"></div>

      <p><strong>Vos coordonnées personnelles</strong></p>
      <ul class="box min-height ">
        <li>Civilité : {{ souscription.civilite }}</li>
        <li>Nom : {{ souscription.nom }}</li>
        <li>Prénom : {{ souscription.prenom }}</li>
        <li>Date et lieu de naissance : le <strong>{{ souscription.dateNaissance | date: 'dd/MM/yyyy' }}</strong> à
          <strong>{{ souscription.lieuNaissance }}</strong>
        </li>
        <li>Email : {{ souscription.email }}</li>
        <li>
          Adresse postale : {{ souscription.adresse }}
          {{ souscription.codePostal }} {{ souscription.ville }}
        </li>
        <li>Téléphone : {{ souscription.mobile }}</li>
      </ul>
    </div>
  </div>


  <hr />

  <div class="center mt-4">
    <h1>Merci de votre confiance</h1>
    <hr />
    <br />

    <p>Une question ? Besoin d'aide ?</p>
    <p>Nous restons à votre écoute au <strong>05 24 44 19 54</strong></p>
    <p>
      Un conseiller SPB Family prendra votre appel du lundi au vendredi de 9h à
      18h.
    </p>
    <p>
      Vous pouvez également nous envoyer un mail à
      <strong>serviceclient@spbfamily.eu</strong>
    </p>
    <br />
    <button (click)="toOney()">
      <i class="fas fa-caret-right"></i> &nbsp; Accéder au site Oney.fr
    </button>
  </div>
</div>
