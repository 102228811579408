<div class="sepa">
  <div class="title-wrap d-none d-md-block">
    <h1>Je remplis mon mandat SEPA</h1>
  </div>

  <div class="d-block d-md-none icon">
    <img src="./assets/euro.svg" width="50px" />
  </div>

  <p class="text-justify">
    <strong>Le mandat SEPA</strong>
    <br />
    Ce mandat vaut pré-notification pour les prochains prélévements SEPA qui
    seront effectués dans les conditions décrites dans votre bulletin d'adhésion
    ou certificat d'adhésion et dans votre Notice d'information.
  </p>

  <div class="row">
    <div class="col-12">
      <mat-form-field class="full-width" appearance="legacy">
        <input matInput placeholder="Votre IBAN*" [(ngModel)]="iban" />
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <mat-form-field class="full-width" appearance="legacy">
        <input matInput placeholder="Votre BIC*" [(ngModel)]="bic" />
      </mat-form-field>
    </div>
  </div>

  <br />
  <strong>Date de prélévement :</strong>
  <br />

  <div class="row">

    <div class="col-sm-12 col-md-6">
      <label class="checkbox">
        <input class="apple-switch" type="radio" name="radio-invalidite" id="invalidite" value="5" [(ngModel)]="date">
        <p class="m-3">le 5 du mois</p>
      </label>
    </div>

    <div class="col-sm-12 col-md-6">
      <label class="checkbox">
        <input class="apple-switch" type="radio" name="radio-invalidite" id="invalidite" value="12" [(ngModel)]="date">
        <p class="m-3">le 12 du mois</p>
      </label>
    </div>
  </div>


  <!-- <br />
    <br /> -->
  <!-- <label>
        <input type="checkbox">
        En cochant cette case j'autorise SPB Family Courtage, partenaire d'Oney, à envoyer des instructions à ma banque pour débiter mon compte, et ma banque à débiter mon compte conformément aux instructions de SPB Family Courtage.
        <br/>
        Je bénéficie du droit d'être remboursé.e par ma manque selon les conditions décrites dans la convention que j'ai passé avec elle.
        Une demande de remboursement doit être présentée dans les 8 semaines suivantla date de débit de votre compte pour un prélèvement autorisé.
        Mes droits concernant le présent mandat sont expliqués dans un document que je peux obtenir auprès de ma banque.
        Les informations contenues dans le présent document sont destinée à n'être utilisées que par SPB Family Courtage pour la gestion de la relation avec son client.
        Elles pourront donner lieu à l'exercice par ce dernier de ses droits d'opposition, d'accès et de rectification tels que prévus aux articles 38 et suivants de la Loi n°78-17 du 6 janvier 1978 modifiée relative à l'informatique, aux fichiers et aux libertés*.
    </label> -->

  <br />
  <label>
    <input type="checkbox" [(ngModel)]="isAccept" />
    J'accepte de démarrer les garanties pendant le délai de renonciation du 14
    jours tel que prévu dans la Notice d'Information.*
  </label>

  <br /><br />
  * : Champs obligatoires
</div>