<div class="gradient-background pt-5">
    <app-steps-bar [steps]="['réalisation de votre devis' , 'DOCUMENTATION  PRECONTRACTUELLE' , 'CHOIX ET PAIEMENT  DE VOS COTISATIONS' , 'CONFIRMATION
        D\'ADHéSION' ]" [currentStep]="1"></app-steps-bar>
</div>

<div class="container pt-4">
    <app-agent imageSRC="../../../assets/spbfamily/Image 2@2x.png"
        agentSpeech="{{souscription?.prenom}}, je vous invite à prendre connaissance de la documentation précontractuelle<br/>
        qui vous a été envoyée avec votre devis à l'adresse <strong>{{souscription?.email}}</strong>. Conservez la précieusement. ">
    </app-agent>

    <div class="text-center w-100 mb-4">
        <app-download-button (click)='downloadFiles()'>Télécharger tous les documents</app-download-button>
    </div>

    <div class="d-none d-md-block">
        <app-panel [items]="items"></app-panel>
    </div>

    <app-global-checkbox name='optim' (valueEmitter)='checked = $event.inputValue'>Je confirme avoir bien reçu par mail ou avoir téléchargé et/ou imprimé, et avoir bien pris
        connaissance de ma Fiche
        d'Information et de Conseil personnalisée, de la Notice d'Information et du Document d'Information de
        l'assurance
        choisie. *
    </app-global-checkbox>

    <small>* Champ requis</small>

    <div class="center-item mb-5">
        <ng-container *ngIf='errors'>
            <ng-container *ngFor='let error of errors'><i class="fas fa-exclamation-circle"></i> &nbsp; <span
                    class="text-danger">{{
                    error.message }}</span>
            </ng-container>
            <br /><br />
        </ng-container>

        <app-global-button-small [disabled]='!checked' (click)="nextStep()">Je continue</app-global-button-small>
    </div>

</div>