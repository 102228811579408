import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-birthdate',
  templateUrl: './birthdate.component.html',
  styleUrls: ['./birthdate.component.scss']
})
export class BirthdateComponent implements OnInit {

  @Input() name: string = "Date de naissance";
  @Input() icon: string = "/assets/spbfamily/Groupe158.png";
  @Input() disableYear: boolean = false;

  currentYear: number = (new Date()).getFullYear();

  constructor() { }

  ngOnInit() {

  }

}
