<div class="fixed-wrapper">
    <app-side-cookie-modal></app-side-cookie-modal>

    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="75" height="75"
        viewBox="0 0 93 93">
        <defs>
            <filter id="Tracé_138" x="0" y="0" width="93" height="93" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="3" result="blur" />
                <feFlood flood-opacity="0.161" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g id="Composant_22_61" data-name="Composant 22 – 61" transform="translate(9 6)">
            <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Tracé_138)">
                <path id="Tracé_138-2" data-name="Tracé 138" d="M37.5,0A37.5,37.5,0,1,1,0,37.5,37.5,37.5,0,0,1,37.5,0Z"
                    transform="translate(9 6)" fill="#17ab88" />
            </g>
            <g id="check" transform="translate(22.5 25.853)">
                <path id="check-2" data-name="check"
                    d="M11.488,23.252a1.531,1.531,0,0,1-2.166,0L.673,14.6a2.3,2.3,0,0,1,0-3.249L1.756,10.27a2.3,2.3,0,0,1,3.249,0l5.4,5.4L24.995,1.079a2.3,2.3,0,0,1,3.249,0l1.083,1.083a2.3,2.3,0,0,1,0,3.249Zm0,0"
                    transform="translate(0 -0.406)" fill="#fff" />
            </g>
        </g>
    </svg>
</div>