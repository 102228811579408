export class Souscription {
    id: string;
    civilite: string;
    nom: string;
    nomJeuneFille: string;
    prenom: string;
    adresse: string;
    codePostal: string;
    dateNaissance: string;
    lieuNaissance: string;
    ville: string;
    mobile: string;
    partner: string;
    email: string;
    step: number;
    invalidite: boolean;
    locataire: boolean;
    salarie: boolean;
    optimOney: boolean;
    optimSpbf: boolean;
    partenaire: string;
    product: string;
    nameProduct: string;
    tarifMensuel: string;
    tarifAnnuel: string;
    iban: string;
    method: string; // Methode de paiement (CB, IBAN)
    prelevement: string; // Mensuel ou annuel
    debit: string; // Jour de débit
    
    constructor() {
        this.id = null;
        this.civilite = null;
        this.nom = null;
        this.nomJeuneFille = null;
        this.prenom = null;
        this.adresse = null;
        this.codePostal = null;
        this.dateNaissance = null;
        this.lieuNaissance = null;
        this.ville = null;
        this.mobile = null;
        this.partner = null;
        this.email = null;
        this.invalidite = null;
        this.locataire = null;
        this.salarie = null;
        this.optimOney = null;
        this.optimSpbf = null;
        this.partenaire = null;
        this.product = null;
        this.step = 0;
    }

    static clone(s:Souscription): Souscription {
        s.dateNaissance = (new Date(s.dateNaissance)).getDate() 
        + '/' + (new Date(s.dateNaissance)).getMonth()
        + '/' + (new Date(s.dateNaissance)).getFullYear()
        return s;
    }
}



