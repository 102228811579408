<div class="row mt-5">

  <div class="section col-sm-12 col-md-6">

    <ul>
      <div class="icon"><img src="./assets/like.svg" width="40px" /></div>
      <li>Civilité : <strong>{{ souscription.civilite }}</strong> </li>
      <li>Nom : <strong>{{ souscription.nom }}</strong></li>
      <li>Prénom : <strong>{{ souscription.prenom }}</strong></li>
      <li>Date et lieu de naissance : le <strong>{{ souscription.dateNaissance | date: 'dd/MM/yyyy' }}</strong> à
        <strong>{{ souscription.lieuNaissance }}</strong></li>
      <li>Email : <strong>{{ souscription.email }}</strong> </li>
      <li>Adresse postale : <strong>{{ souscription.adresse }} {{ souscription.codePostal }} {{ souscription.ville
          }}</strong></li>
      <li>Téléphone : <strong>{{ souscription.mobile }}</strong></li>
    </ul>
  </div>

  <div class="section col-sm-12 col-md-6">
    <h1>Je vérifie et je valide !</h1>
    <p>
      <strong>Veuillez à bien vérifier vos coordonnées afin de faciliter la gestion
        de votre contrat.</strong>
    </p>

    <p>
      <strong>Tout est correct ?</strong>
      <br />Cliquez simplement sur le bouton "Je continue"
    </p>

    <p>
      <strong>Une erreur ?</strong>
      <br />Modifiez vos coordonnées en cliquant sur "Je modifie"
    </p>

    <ng-container *ngIf='error'>
      <span class="text-danger">
        {{ error }}
      </span>
      <br />
      <br />
    </ng-container>
    
    <button class="col-sm-12 col-md-5 secondary" (click)="startEdit()"><i class="fas fa-caret-left"></i>&nbsp; Je
      modifie</button>
    <button class="col-sm-12 col-md-5 primary" (click)="nextStep()">Je continue &nbsp; <i
        class="fas fa-caret-right"></i></button>
  </div>
</div>