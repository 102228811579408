import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Error } from 'src/app/_models/Error';
import { Souscription } from 'src/app/_models/Souscription';
import { SouscriptionService } from 'src/app/_services/souscription.service';
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-souscription-second-step',
  templateUrl: './souscription-second-step.component.html',
  styleUrls: ['./souscription-second-step.component.scss']
})
export class SouscriptionSecondStepComponent implements OnInit {

  checked: boolean;
  souscription: Souscription;
  items: any = [];
  errors: Error[];
  
  constructor(private souscriptionService: SouscriptionService, private sanitizer: DomSanitizer, private router: Router) {
  }

  ngOnInit() {
    this.souscription = this.souscriptionService.getSouscription();

    this.souscriptionService.getDocuments().subscribe(
      (response) => {
        this.items = (Array.isArray(response)) ? response : [];
        this.items.forEach((tab) => {
          tab.file = this.sanitizer.bypassSecurityTrustResourceUrl(tab.file);
        })
      },
      () => { }
    )
  }

  downloadFiles() {
    window.open(environment.url + '/document/' + this.souscription.id, "_blank");
  }

  nextStep() {
    if (this.checked) {
      this.errors = [];
      this.souscriptionService.setDocumentsReceived().subscribe(
        () => { this.router.navigate(['/souscription/paiement']) },
        () => {
          this.errors = [new Error('global', 'Vous devez confirmer avoir reçu, téléchargé et/ou imprimé les documents pré-contractuels.')];
        },
      )
    }
    else
      this.errors = [new Error('global', 'Vous devez confirmer avoir reçu, téléchargé et/ou imprimé les documents pré-contractuels.')];
  }

}
