<div class="container" *ngIf="form">
  <div class="chat">
    <div class="discussion" #scroll [scrollTop]="scroll.scrollHeight">
      <ng-container *ngFor="let message of messages">
        <div
          [ngClass]="{
            other: message.type === 'question' || message.type === 'error',
            mines: message.type === 'reply'
          }"
        >
          <div
            class="picture"
            *ngIf="
              (message.type === 'question' || message.type === 'error') &&
              message.isLast
            "
            [innerHTML]="form.messenger.agent.status"
          ></div>

          <div
            [ngClass]="{
              'agent messages':
                message.type === 'question' || message.type === 'error',
              'mine messages': message.type === 'reply'
            }"
          >
            <div
              class="message"
              [ngClass]="{ last: message.isLast, 'not-last': !message.isLast }"
              [innerHTML]="message.value"
            ></div>
          </div>

          <ng-container
            *ngIf="message.type === 'reply' && message.step !== null"
          >
            <div class="edit-reply" (click)="backTo(message.step)">
              <i
                mat-raised-button
                #tooltip="matTooltip"
                matTooltip="Modifier cette réponse"
                matTooltipPosition="left"
                matTooltipShowDelay="0"
                matTooltipHideDelay="1000"
                aria-label="Modifier cette réponse"
                class="fas fa-edit"
              ></i>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <div class="row-input">
      <ng-container *ngFor="let field of fields">
        <ng-container *ngIf="field.type == 'button'">
          <div
            [class]="field.class"
            (click)="
              this[field.events.onClick.functionName].apply(
                this,
                field.events.onClick.functionParameters
              )
            "
          >
            {{ field.value }}
          </div>
        </ng-container>
        <ng-container *ngIf="field.type == 'text' || field.type == 'date'">
          <input
            [type]="field.type"
            [class]="field.class"
            [placeholder]="field.placeholder"
            required
          />
        </ng-container>
      </ng-container>
    </div>

    <ng-container *ngIf="step?.actionButton.isEnabled">
      <div class="replyBtns">
        <div
          [class]="step?.actionButton.class"
          (click)="this[step?.actionButton.value]()"
        >
          <strong
            ><i class="fas fa-paper-plane"></i>
            {{ step?.actionButton.text }}</strong
          >
        </div>
      </div>
    </ng-container>
  </div>
</div>
