<div class="container">
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>

    <div class="et_pb_module et_pb_text et_pb_text_19  et_pb_text_align_left et_pb_bg_layout_light">
        <div class="et_pb_text_inner">
            <h3 style="text-align: center;"><strong style="font-weight: 700; color: #333;">Se faire rappeler
                    gratuitement</strong></h3>
            <p style="text-align: center;">Un conseiller SPB Family partenaire de la société Oney vous
                rappelle
                pour répondre à vos questions !</p>
        </div>
    </div>
</div>

<div class="overflow-auto" style="height: 500px;">

    <div class="et_pb_module et_pb_dcgd_gravity_divi_module et_pb_dcgd_gravity_divi_module_2">

        <div class="container">

            <input class="d-none" type="text" [(ngModel)]="hp" />

            <div class="row">
                <div class="col-sm">
                    <!-- col one-->
                    <span class="w_100 mx_8">
                        <td> <label class="TC_Litegreen">Civilité :*</label> </td>
                        <select class="BG_Litegrey" [(ngModel)]='callbackForm.civilite' type="text" name="Civilite"
                            id="Civilite" aria-required="true" aria-invalid="false">
                            <option></option>
                            <option>M</option>
                            <option>Mme</option>
                        </select>
                    </span>
                </div>
                <div class="col-sm">
                    <!-- col two-->
                    <span class="w_100 mx_8">
                        <td> <label class="TC_Litegreen">Prénom :*</label> </td>
                        <input class="BG_Litegrey" type="text" [(ngModel)]='callbackForm.prenom' name="firstname"
                            id="name" aria-required="true" aria-invalid="false">
                    </span>
                </div>
                <div class="col-sm">
                    <!--coll three-->
                    <span class="w_100 mx_8">
                        <td> <label class="TC_Litegreen">Nom :*</label></td>
                        <input class="BG_Litegrey" type="text" name="lastename" [(ngModel)]='callbackForm.nom'
                            id="lastename" aria-required="true" aria-invalid="false">
                    </span>

                </div>
            </div>

            <div class="row">
                <div class="col">
                    <span class="w_100 mx_8">
                        <td> <label class="TC_Litegreen">Téléphone :*</label></td>
                        <input class="BG_Litegrey" type="text" [(ngModel)]='callbackForm.tel' name="lastename"
                            id="lastename" aria-required="true" aria-invalid="false">
                    </span>

                </div>
                <div class="col">
                    <span class="w_100 mx_8">
                        <td>
                            <label class="TC_Litegreen">Je souhaite être recontacté le :<span
                                    class="gfield_required">*</span></label>
                        </td>
                        <div id="input_3_2" class="ginput_container ginput_container_date">
                            <div class="form-group">
                                <div class="input-group">
                                    <input type="date" class="form-control BG_Litegrey w-100" name="dp"
                                        [(ngModel)]='callbackForm.callbackDate'>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>
            </div>

            <label class="gfield_label mx_up">Je suis disponible de préférence:*</label>
            <div class="row">
                <div class="col">
                    <label class="checkbox">
                        <input class="apple-switch" type="radio" name="radio-cats" id="matin" value="matin"
                            [(ngModel)]='callbackForm.preference'>
                        <p class="text_Toggel" for="matin">Matin</p>
                    </label>
                </div>

                <div class="col">
                    <label class="checkbox">
                        <input class="apple-switch" type="radio" name="radio-cats" id="midi" value="midi"
                            [(ngModel)]='callbackForm.preference'>
                        <p class="text_Toggel" for="midi">Midi</p>
                    </label>
                </div>
                <div class="col">
                    <label class="checkbox">
                        <input class="apple-switch" type="radio" name="radio-cats" id="apMidi" value="apres midi"
                            [(ngModel)]='callbackForm.preference'>
                        <p class="text_Toggel" for="apMidi">Après-midi</p>
                    </label>
                </div>
            </div>

            <div>
                <label class="checkbox">
                    <input class="apple-switch" type="radio" name="confirmRadio" id="confirm" [value]="true"
                        [(ngModel)]='callbackForm.optim'>
                    <p class="text_Toggel" for="confirm">En cliquant sur Être rappelé, j'accepte d'être
                        recontacté par un
                        conseiller SPB Family partenaire de la société Oney**.*</p>
                </label>
            </div>

            <label class="gfield_label mx_up">Champs obligatoires*</label>

            <div class="text-danger text-center" *ngIf='error && !ok'>
                {{ error }}
            </div>

            <div class="text-success text-center" *ngIf='ok'>
                Votre demande a bien été prise en compte.
            </div>

            <div class="bt_soft_tr" *ngIf='!ok'>
                <button class="T_alignC D_button" target="_blank" data-icon="E" (click)="send()">
                    Être rappelé <small>(1)</small>
                </button>
            </div>

            <div class="et_pb_module et_pb_text et_pb_text_20  et_pb_text_align_justified et_pb_bg_layout_light">
                <div class="et_pb_text_inner">
                    <p>** Conformément à la réglementation, les informations demandées sont nécessaires au
                        traitement de
                        votre demande. Ces informations sont destinées à Oney et à son mandataire
                        d’intermédiaire en
                        assurance SPB Family, responsable du traitement, à des fins de conseil et de
                        gestion de
                        votre
                        demande.<br>Nous vous rappelons que vous disposez d’un droit d’accès, de
                        rectification,
                        d’effacement, d’opposition, et de portabilité des données vous concernant, d’un
                        droit
                        d’opposition et de limitation aux traitements effectués par Oney ou son mandataire
                        d’intermédiaire en assurance SPB Family ainsi que d’un droit d’opposition à la
                        prospection
                        commerciale auprès de Oney à l’adresse suivante Oney – CS 60006 – 59895 Lille Cedex
                        9 ou
                        encore
                        auprès de SPB Family Courtage à l’adresse suivante 208 Quai de Paludate – 33800
                        BORDEAUX
                        ou par
                        email : serviceclient@spbfamily.eu</p>
                    <p>(1) En cliquant sur “Être rappelé”, &nbsp;j’accepte d’être recontacté par SPB Family
                        pour
                        qu’il
                        puisse répondre à ma demande et me proposer un conseil personnalisé du produit
                        d’assurance
                        adapté à mes besoins.</p>
                    <p>SPB Family Courtage : SAS de courtage d’assurance au capital de 50.000 euros dont le
                        siège social
                        est situé 208 Quai de Paludate, 33800 Bordeaux – www.spbfamily.eu, immatriculée au
                        RCS
                        de
                        Bordeaux sous le n°821 784 840 et à l’ORIAS sous le n° 16004724 (www.orias.fr). SPB
                        Family
                        Courtage ne détient aucune participation directe ou indirecte d’une compagnie
                        d’assurance.
                        Aucune entreprise d’assurance ne détient de participation directe ou indirecte dans
                        la
                        société
                        SPB Family Courtage. Elle ne réalise pas plus de 33% de son chiffre d’affaires avec
                        Oradéa Vie,
                        CNP, IMA. Elle exerce comme courtier en assurance conformément aux dispositions de
                        l’article
                        L521-2, II b et travaille ou est susceptible de travailler avec les compagnies
                        d’assurances
                        suivantes : Prévoir Risques Divers, IMA Assurances, Auxia Assurances, CFDP
                        Assurances,
                        CNP
                        Assurances SPB Family Courtage ne fournit pas de service de recommandation
                        personnalisée. SPB
                        Family Courtage est rémunérée sous forme de commissions.</p>
                    <p>WAKAM S.A. au capital de 4 514 512 € – 562 117 085 R.C.S Paris – 120-122, rue Réaumur
                        –
                        75002
                        PARIS – Entreprise régie par le code des assurances, Numéro SIREN 562 117 085.</p>
                    <p>Oney Bank – Société de courtage d’assurance – SA au capital de 51 286 585 € – Siège
                        social : 34
                        avenue de Flandre 59170 Croix – RCS Lille Métropole 546 380 197 – N° ORIAS 07 023
                        261(www.orias.fr).</p>
                    <p>Oney Bank, SPB Family Courtage et Wakam sont soumises au contrôle de l’Autorité de
                        Contrôle
                        Prudentiel et de Résolution – 4 Place de Budapest, CS 92459, 75436 Paris Cedex 09.
                    </p>
                </div>
            </div> <!-- .et_pb_text -->
        </div> <!-- .et_pb_column -->


    </div>
</div>