import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spbf-granties',
  templateUrl: './spbf-granties.component.html',
  styleUrls: ['./spbf-granties.component.scss']
})
export class SpbfGrantiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
