import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { SouscriptionService } from 'src/app/_services/souscription.service';

@Component({
  selector: 'app-check-icon',
  templateUrl: './check-icon.component.html',
  styleUrls: ['./check-icon.component.scss']
})
export class CheckIconComponent implements OnInit {

  @Input() isChecked: boolean = false;
  @Input() iconCheck: string;
  @Input() icon: string;
  @Input() value: string;
  @Input() name: string;
  @Input() displayTheme: string;
  @Output() valueEmitter = new EventEmitter<any>();

  constructor(public souscriptionService: SouscriptionService) { }

  emiteValue(value: string) {
    this.valueEmitter.emit({
      'inputName': this.name,
      'inputValue': value,
      'isValid': true
    });
  }

  ngOnInit() {
  }

}
