import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Souscription } from 'src/app/_models/Souscription';
import { CacheService } from 'src/app/_services/cache.service';
import { SouscriptionService } from 'src/app/_services/souscription.service';
import { CallBackFormComponent } from '../oney-page/call-back-form/call-back-form.component';
import { ContactFormComponent } from '../oney-page/contact-form/contact-form.component';
import { CookiesModalComponent } from '../oney-page/cookies-modal/cookies-modal.component';

@Component({
  selector: 'app-oney-form-page',
  templateUrl: './oney-form-page.component.html',
  styleUrls: ['./oney-form-page.component.scss']
})
export class OneyFormPageComponent implements OnInit {

  error: string;
  errno: number;
  souscription: Souscription;
  cookies: boolean;

  constructor(
    public dialog: MatDialog,
    private souscriptionService: SouscriptionService,
    private cacheService: CacheService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  callBack() {
    this.dialog.closeAll();
    this.dialog.open(CallBackFormComponent);
  }

  contactForm() {
    this.dialog.closeAll();
    this.dialog.open(ContactFormComponent);
  }

  acceptCookies() {
    this.cookies = true;
    this.cacheService.set('cookies', true);
  }

  configCookies() {
    this.cookies = true;
    this.dialog.open(CookiesModalComponent);
  }

  ngOnInit(): void {
    // Init Souscription
    this.souscription = new Souscription();

    // Load Souscription from cache
    if (this.route.snapshot.queryParamMap.get('mode') == 'edit') {
      let cachedSouscription = this.cacheService.get('souscription');
      if (cachedSouscription)
        this.souscription = Souscription.clone(cachedSouscription)
    }

    // this.cookies = this.cacheService.get('cookies');
  }

  toTop() {
    document.getElementById("top").scrollIntoView({ behavior: "smooth" });
  }

  toFormulaire() {
    document.getElementById("formulaire").scrollIntoView({ behavior: "smooth" });
  }

  validateForm() {
    this.errno = 0;
    this.error = null;

    if (!this.souscription.civilite) {
      this.error = 'Le champ "Civilite" est invalide.';
      this.errno = 1;
      return;
    }

    if (!this.souscription.prenom || !this.souscription.prenom.trim().match(/[a-zA-Z \-\']+/g)) {
      this.error = 'Le champ "Prénom" est invalide.';
      this.errno = 2;
      return;
    }

    // Valider le nom
    if (!this.souscription.nom || !this.souscription.nom.trim().match(/[a-zA-Z \-\']+/g)) {
      this.error = 'Le champ "Nom" est invalide.';
      this.errno = 3;
      return;
    }

    if (!this.souscription.adresse || !this.souscription.adresse.trim().match(/[a-zA-Z1-9 ]+/g)) {
      this.error = 'Le champ "Adresse" est invalide.';
      this.errno = 4;
      return;
    }

    // Valider le ville
    if (!this.souscription.ville || !this.souscription.ville.trim().match(/[a-zA-Z \-\']+/g)) {
      this.error = 'Le champ "Ville" est invalide.';
      this.errno = 5;
      return;
    }

    // Valider le num code postal
    if (!this.souscription.codePostal || !this.souscription.codePostal.trim().match(/[0-9]{5}/g)) {
      this.error = 'Le champ "Code postal" est invalide.';
      this.errno = 6;
      return;
    }

    // Valider le lieu de naissance 
    if (!this.souscription.dateNaissance) {
      this.error = 'Le champ "Date de naissance" est invalide.';
      this.errno = 7;
      return;
    }

    // Valider le lieu de naissance 
    if (!this.souscription.lieuNaissance || !this.souscription.lieuNaissance.trim().match(/[a-zA-Z \-\'\,]+/g)) {
      this.error = 'Le champ "Lieu de naissance" est invalide.';
      this.errno = 8;
      return;
    }


    // Valider le num mobile
    if (!this.souscription.mobile || !this.souscription.mobile.trim().match(/^((\+)33|0|0033)[1-9](\d{2}){4}$/g)) {
      this.error = 'Le champ "Numéro de téléphone" est invalide.';
      this.errno = 9;
      return;
    }

    //valide email 
    if (!this.souscription.email || !this.souscription.email.trim().match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g)) {
      this.error = 'Le champ "E-mail" est invalide.';
      this.errno = 10;
      return;
    }

    if (this.souscription.invalidite === null) {
      this.error = 'Veuillez spécifier votre situation.';
      this.errno = 11;
      return;
    }

    if (this.souscription.locataire === null) {
      this.error = 'Veuillez spécifier votre situation.';
      this.errno = 12;
      return;
    }

    if (this.souscription.salarie === null) {
      this.error = 'Veuillez spécifier votre situation.';
      this.errno = 13;
      return;
    }

    if (this.souscription.product === null) {
      this.error = 'Veuillez choisir un produit.';
      this.errno = 14;
      return;
    }

    // Préparation des données pour l'envoi
    let data = new FormData();

    if (this.souscription.id)
      data.append("id", this.souscription.id)

    data.append("civilite", this.souscription.civilite)
    data.append("prenom", this.souscription.prenom)
    data.append("nom", this.souscription.nom)
    data.append("adresse", this.souscription.adresse)
    data.append("ville", this.souscription.ville)
    data.append("code_postal", this.souscription.codePostal)
    data.append("nom_jeune_fille", this.souscription.nomJeuneFille)
    data.append("date_naissance", this.souscription.dateNaissance)
    data.append("lieu_naissance", this.souscription.lieuNaissance)
    data.append("mobile", this.souscription.mobile)
    data.append("email", this.souscription.email)
    data.append("invalidite", this.souscription.invalidite?.toString())
    data.append("locataire", this.souscription.locataire?.toString())
    data.append("salarie", this.souscription.salarie?.toString())
    data.append("optim_oney", this.souscription.optimOney?.toString())
    data.append("optim_spbf", this.souscription.optimSpbf?.toString())
    data.append("partenaire", "f98402a0e8fa"); // Static pour Oney

    if (this.souscription.product == '981cb9ee')
      data.append("produit", "981cb9ee"); // 13.9
    else
      data.append("produit", "8c5af5ca"); // 5.5

    // UTM
    this.route.queryParams.subscribe(
      (params) => {
        data.append("utm_source", params['utm_source']);
        data.append("utm_medium", params['utm_medium']);
        data.append("utm_campaign", params['utm_campaign']);
        data.append("site_id", params['siteid']);
      },
      () => {},
    )

    // Envoyer à l'API
    this.souscriptionService.postSouscription(data).subscribe(
      (souscription) => {
        // Gérer le retour pour rediriger vers la page de souscription
        this.router.navigate(['/souscription/' + souscription.id + '/validation']);
      },
      (err) => {
        // Echèc
        this.error = err.error.error;
      },
    );

  }

  changeDate(date) {
    let dateValue = (date.target as HTMLInputElement).value; // yyyy-mm-dd
    this.souscription.dateNaissance = dateValue;
  }

}
