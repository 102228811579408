<div class="wrapper">
    <ul class="progressbar">
        <li *ngFor="let step of steps; index as i;" id={{i}} [ngStyle]="calculWidth()"
            [ngClass]="{'selected': i == currentStep ,'active' : i < currentStep }">

            <span class="d-none d-sm-block">
                {{step}}
            </span>
        </li>
    </ul>
    <p class="title d-xs-block d-sm-none text-center primary-color">{{steps[this.currentStep]}}</p>
</div>