import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { NavigationEnd, Router } from '@angular/router';
import { HelpModalComponent } from 'src/app/_partners/_spbfamily/_modals/help-modal/help-modal.component';
import { SouscriptionService } from 'src/app/_services/souscription.service';
import { ModalRappelComponent } from '../modal-rappel/modal-rappel.component';

@Component({
  selector: 'app-global-header',
  templateUrl: './global-header.component.html',
  styleUrls: ['./global-header.component.scss']
})
export class GlobalHeaderComponent implements OnInit {


  constructor(private location: Location, private dialog: MatDialog, private souscriptionService: SouscriptionService, private router: Router) {
  }

  @Input() returnBtnOption: string;
  @Input() isModalOpened: boolean;
  @Output() returnBtnEmitter: EventEmitter<any> = new EventEmitter<any>();

  progress: number = (1 / 8) * 100;
  step: number = 1;
  isShowProgress: boolean = false;

  ngOnInit() {
    this.changeProgress(this.router.url);

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd)
        this.changeProgress(val.url);
    });

    this.isShowProgress = ([
      '/demande-devis/identite',
      '/demande-devis/adresse',
      '/demande-devis/logement',
      '/demande-devis/situation-professionnelle',
      '/demande-devis/naissance',
      '/demande-devis/preference-produit',
      '/demande-devis/informations-de-contact',
    ].includes(this.router.url))
  }

  changeProgress(url) {
    switch (url) {
      case '/demande-devis/identite':
        this.step = 1;
        break;
      case '/demande-devis/adresse':
        this.step = 2;
        break;
      case '/demande-devis/logement':
        this.step = 3;
        break;
      case '/demande-devis/situation-professionnelle':
        this.step = 4;
        break;
      case '/demande-devis/naissance':
        this.step = 5;
        break;
      case '/demande-devis/preference-produit':
        this.step = 6;
        break;
      case '/demande-devis/informations-de-contact':
        this.step = 7;
        break;
    }
    this.progress = (this.step / 8) * 100;
  }

  back() {
    let souscription = this.souscriptionService.getSouscription();
    if (this.router.url == '/demande-devis/informations-de-contact' && souscription?.locataire === false) {
      this.souscriptionService.currentStep -= 2;
      this.souscriptionService.currentStep$.next(this.souscriptionService.currentStep);
      window.history.go(-2);
    } else {
      if (this.isShowProgress)
        this.souscriptionService.stepBack();
      this.location.back();
    }
  }

  openHelpModal() {
    let dialogRef = this.dialog.open(HelpModalComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isModalOpened = false;
    });


    this.isModalOpened = true;
  }

}
