import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Souscription } from 'src/app/_models/Souscription';
import { SouscriptionService } from 'src/app/_services/souscription.service';

@Component({
  selector: 'app-souscription-third-step-signature',
  templateUrl: './souscription-third-step-signature.component.html',
  styleUrls: ['./souscription-third-step-signature.component.scss']
})
export class SouscriptionThirdStepSignatureComponent implements OnInit {

  souscription: Souscription;
  url: SafeResourceUrl;
  constructor(public souscriptionService: SouscriptionService, private sanitize: DomSanitizer, private router: Router) { }

  ngOnInit() {
    this.souscription = this.souscriptionService.getSouscription();
    this.souscriptionService.getSignatureUrl().subscribe(
      (resp) => this.url = this.sanitize.bypassSecurityTrustResourceUrl(resp.url),
      () => { },
    )

    setInterval(
      () => (this.souscriptionService.getStep().subscribe(
        (resp) => {
          if (resp.step == 11)
            this.router.navigate(['/souscription/final'])
        }, () => { } )), 5000);
  }

}
