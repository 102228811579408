import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SouscriptionService } from 'src/app/_services/souscription.service';

@Component({
  selector: 'app-result-section',
  templateUrl: './result-section.component.html',
  styleUrls: ['./result-section.component.scss']
})
export class ResultSectionComponent implements OnInit {


  souscription;
  uid;

  constructor(private souscriptionService: SouscriptionService, private route: ActivatedRoute) {
    this.uid = this.route.snapshot.parent.params.uid;
  }

  ngOnInit() {
    this.souscription = this.souscriptionService.getSouscription();
  }

  toOney() {
    window.location.href = 'https://www.oney.fr';
  }
}
