<div class="w-75 mx-auto accordion" id="accordion">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-spbf collapsed" type="button" data-bs-toggle="collapse" attr.data-bs-target="#Collape{{id}}"
        aria-expanded="false" attr.aria-controls="Collape{{id}}">
        <img class="arrow pr-3" src="../../../assets/spbfamily/arrow.svg " height="15px">{{ itemTitle }}
      </button>
    </h2>
    <div id="Collape{{id}}" class="accordion-collapse collapse" aria-labelledby="headingTwo"
      data-bs-parent="#accordionExample">
      <div class="accordion-body" [innerHTML]="itemBody">
      </div>
    </div>
  </div>
</div>