<section class="header">
    <div class="logo">
        <img src="/assets/spbfamily/logo.png" alt="Protect Habitat & Facture - SPB Family">
    </div>
    <div class="title">
        <h1>Assurer son toit en toutes circonstances jusqu'à 1 an !</h1>
        <p>En cas de perte d'emploi ou d'accident de la vie*, préserver son budget et sa famille, c'est possible !</p>
    </div>
    <div class="button">
        <app-button-g link="/demande-devis/identite">OBTENIR UN DEVIS</app-button-g>
    </div>
</section>