import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Error } from 'src/app/_models/Error';
import { Input } from 'src/app/_models/Input';
import { Souscription } from 'src/app/_models/Souscription';
import { SouscriptionService } from 'src/app/_services/souscription.service';

@Component({
  selector: 'app-modal-rappel',
  templateUrl: './modal-rappel.component.html',
  styleUrls: ['./modal-rappel.component.scss']
})
export class ModalRappelComponent implements OnInit {
  errors: Error[] = [];
  pref1: string = (new Date().getDay() != 0 && new Date().getDay() != 6) ? "aujourd'hui" : "dans quelques jours";
  pref2: string = (new Date().getHours() < 11) ? "le matin" : "l'après-midi";
  souscription: Souscription;
  success: boolean = false;
  precisDate: Date;

  constructor(public dialogRef: MatDialogRef<ModalRappelComponent>, public souscriptionService: SouscriptionService, private httpClient: HttpClient) { }

  ngOnInit() {
    this.souscription = this.souscriptionService.getSouscription();

    this.souscriptionService.setCurrentInputs([
      new Input('firstname', 'Prénom', (this.souscription) ? this.souscription.prenom : null, true, null, '8100x100', null),
      new Input('lastname', 'Nom', (this.souscription) ? this.souscription.nom : null, true, null, '8100x200', null),
      new Input('mobile', 'N° de téléphone', (this.souscription) ? this.souscription.mobile : null, true, null, '8100x300', null),
    ])
  }

  close() {
    this.dialogRef.close();
  }

  isValidDate(date) {
    console.log(date)
    return true;
  }

  nextStep() {
    if (this.souscriptionService.isCurrentStepValid) {
      let data = new FormData();
      data.append('firstname', this.souscriptionService.getInputByName('firstname')?.value);
      data.append('lastname', this.souscriptionService.getInputByName('lastname')?.value);
      data.append('mobile', this.souscriptionService.getInputByName('mobile')?.value);
      data.append('pref1', this.pref1);
      data.append('pref2', this.pref2);
      this.httpClient.post(`/call`, data).subscribe(
        () => this.success = true,
        (err) => {
          this.errors = [err.error];
        }
      );
    }

  }

}
