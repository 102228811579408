import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { CallbackForm } from 'src/app/_models/CallbackForm';
import { FormsService } from 'src/app/_services/forms.service';


@Component({
  selector: 'app-call-back-form',
  templateUrl: './call-back-form.component.html',
  styleUrls: ['./call-back-form.component.scss'],
  providers: []
})

export class CallBackFormComponent implements OnInit {

  callbackForm: CallbackForm;
  error: string;
  ok: boolean;
  hp: string; // HoneyPot

  constructor(
    public dialog: MatDialog,
    private formsService: FormsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.callbackForm = new CallbackForm();
  }

  closeModal() {
    this.dialog.closeAll();
  }

  send() {
    if (this.hp)
      return;
    
    // Valider le num mobile
    if (!this.callbackForm.tel || !this.callbackForm.tel.match(/^((\+)33|0|0033)[1-9](\d{2}){4}$/g)) {
      this.error = 'Le champ "Téléphone" est invalide.';
      return;
    }

       // UTM
       this.route.queryParams.subscribe(
        (params) => {
          this.callbackForm.utm_source = params['utm_source'];
          this.callbackForm.utm_medium = params['utm_medium'];
          this.callbackForm.utm_campaign = params['utm_campaign'];
          this.callbackForm.site_id = params['siteid'];
        },
        () => {},
      )
  

    this.formsService.sendForm('callback', this.callbackForm).subscribe(
      () => this.ok = true,
      (err) => this.error = err.error.error,
    );
  }

}
