<div class="quota-wrapper">
    <div class="quota-question">
        <app-agent imageSRC="/assets/spbfamily/Image 2@2x.png"
            agentSpeech="Super !<br/>Vous êtes locataire ou propriétaire à {{souscription?.ville}} ?">
        </app-agent>
    </div>

    <div class="quota-reply">
        <div class="flex-50">
            <app-check-icon displayTheme="lg-radio-button-with-icon" icon="/assets/spbfamily/proprietaire.svg"
                iconCheck="/assets/spbfamily/locataire@2x.png"
                [isChecked]="souscriptionService.getInputByName('locataire').value == '2'" value="2" name="locataire"
                (valueEmitter)="setInputValue($event.inputValue)">Propriétaire</app-check-icon>
        </div>
        <div class="flex-50">
            <app-check-icon displayTheme="lg-radio-button-with-icon" icon="/assets/spbfamily/locataire.png"
                iconCheck="/assets/spbfamily/proprietaire-locataire@2x.png"
                [isChecked]="souscriptionService.getInputByName('locataire').value == '1'" value="1" name="locataire"
                (valueEmitter)="setInputValue($event.inputValue)">Locataire</app-check-icon>
        </div>
    </div>

    <div class="quota-reply center-item mt-2">
        <ng-container *ngIf='errors'>
            <ng-container *ngFor='let error of errors'><i class="fas fa-exclamation-circle"></i> &nbsp; <span
                    class="text-danger">{{
                    error.message }}</span>
                <br><br>
            </ng-container>
        </ng-container>
    </div>
    
    <div class="quota-reply center-item mt-2">
        <app-global-button-small [disabled]='!souscriptionService.isCurrentStepValid'
            *ngIf='souscriptionService.isCurrentStepValid != null' (click)='nextStep()'>Continuer
        </app-global-button-small>
    </div>
</div>