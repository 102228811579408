<div class="gradient-background pt-5">
    <app-steps-bar [steps]="['VALIDATION DE VOS COORDONNEES' , 'DOCUMENTATION  PRECONTRACTUELLE' , 'CHOIX ET PAIEMENT  DE VOS COTISATIONS' , 'CONFIRMATION
          D\'ADHéSION' ]" [currentStep]="0"></app-steps-bar>
</div>

<div class="container pt-4">
    <app-agent imageSRC="../../../assets/spbfamily/Image 2@2x.png"
        agentSpeech="Marie, nous allons vérifiez vos coordonnées.<br/> Si tout est correct, cliquez sur le bouton <strong>JE CONTINUE</strong>, sinon sur l'icône en forme de stylo.">
    </app-agent>

    <div class="block-center">
        <p>
            Vous vous appelez <strong>Marie CHABANNE</strong> &nbsp; <img src="/assets/spbfamily/edit_default.svg"
                alt="Modifier" class="link" height="27px">
        </p>

        <p>
            Vous résidez à <strong>Bordeaux (33800)</strong> au <strong>208 Quai de Paludate</strong>&nbsp; <img
                src="/assets/spbfamily/edit_default.svg" alt="Modifier" class="link" height="27px">
        </p>

        <p>
            Pour vous joindre nous pouvons vous envoyer un email à <strong>mchabanne@spbfamily.eu</strong>&nbsp; <img
                src="/assets/spbfamily/edit_default.svg" alt="Modifier" class="link" height="27px">
        </p>

        <p>
            ou vous appeler au <strong>06 12 85 41 22</strong>&nbsp; <img src="/assets/spbfamily/edit_default.svg"
                alt="Modifier" class="link" height="27px">
        </p>

        <div class="center-item">
            <app-global-button-small link="/souscription/paiement">Je continue</app-global-button-small>
        </div>
    </div>

</div>