<div class="position-relative">
    <label class="w-100"><img *ngIf='icon' [src]="icon">

        <input #inputField matInput (click)='inputCursorPos(inputField)' type="text" *ngIf='isDate' [textMask]="mask" [name]="name" [value]="value"
            [placeholder]="placeholder"
            [ngClass]="{'icon': icon, 'error': souscriptionService.getInputByName(this.name).isValid === false, 'valid': souscriptionService.getInputByName(this.name).isValid}"
            [pattern]="pattern" (input)='emiteValue(inputField.value, minInputLength)' />

        <input #inputField matInput type="text" *ngIf='!isDate' [name]="name" [value]="value"
            [placeholder]="placeholder"
            [ngClass]="{'icon': icon, 'error': souscriptionService.getInputByName(this.name).isValid === false, 'valid': souscriptionService.getInputByName(this.name).isValid}"
            [pattern]="pattern" (input)='emiteValue(inputField.value, minInputLength)' />

    </label>
</div>