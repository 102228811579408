<div class="quota-wrapper">
    <div class="quota-question">
        <app-agent imageSRC="/assets/spbfamily/Image 2@2x.png" agentSpeech="Parfait, merci !<br/>
                        Une question indiscrète :  Quel est votre âge et votre lieu de naissance ?">
        </app-agent>
    </div>

    <div class="quota-reply">
        <div class="flex-100">
            <app-input-field isDate="true"  icon="/assets/spbfamily/Groupe158.png" [placeholder]="souscriptionService.currentInputs[0].placeholder" [name]="souscriptionService.currentInputs[0].name"
                [value]="souscriptionService.currentInputs[0].value" [pattern]="souscriptionService.currentInputs[0].pattern" [required]="souscriptionService.currentInputs[0].required"
                (valueEmitter)="souscriptionService.setInputValue($event.inputName, $event.inputValue, $event.isValid)">
            </app-input-field>
        </div>
    </div>
    <div class="quota-reply">
        <div class="flex-100">
            <app-input-field icon="/assets/spbfamily/pin.svg" [placeholder]="souscriptionService.currentInputs[1].placeholder" [name]="souscriptionService.currentInputs[1].name"
                [value]="souscriptionService.currentInputs[1].value" [pattern]="souscriptionService.currentInputs[1].pattern" [required]="souscriptionService.currentInputs[1].required"
                (valueEmitter)="souscriptionService.setInputValue($event.inputName, $event.inputValue, $event.isValid)">
            </app-input-field>
        </div>
    </div>

    <div class="quota-reply">
        <div class="center-item mt-2">
            <ng-container *ngIf='errors'>
                <ng-container *ngFor='let error of errors'><i class="fas fa-exclamation-circle"></i> &nbsp; <span
                        class="text-danger">{{
                        error.message }}</span>
                    <br /><br />
                </ng-container>
            </ng-container>

            <app-global-button-small [disabled]='!souscriptionService.isCurrentStepValid'
                *ngIf='souscriptionService.isCurrentStepValid != null' (click)='nextStep()'>Continuer
            </app-global-button-small>
        </div>
    </div>
</div>