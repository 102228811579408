
<div class ="container ShadowBox rounded Dimention overflow-auto">
    <div class="d-inline-block MargTop">
      <img src="../../../assets/spbfamily/oldcpl.jpg" alt="Card image cap">
    </div>
    <div class="d-inline-block">
      <h5 class="CardTitle">Card title</h5>
    </div>
      <p class="CardText">"J’ai découvert Protect habitat sur internet. 
        Depuis ma souscription je suis tranquille car avec cette assurance mon loyer et mes factures sont couverts en cas d’imprévus. "  </p>
</div>

