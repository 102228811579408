export class CallbackForm {
    civilite: string;
    prenom: string;
    nom: string;
    tel: string;
    callbackDate: string;
    preference: string;
    optim: string;
    date: string;
    utm_source: string;
    utm_campaign: string;
    utm_medium: string;
    site_id: string;

    constructor() {
        this.civilite = "";
        this.prenom = "";
        this.nom = "";
        this.tel = "";
        this.callbackDate = "";
        this.preference = "";
        this.optim = "";
        this.date = (new Date().getTime() / 1000).toString();
        this.utm_source = "";
        this.utm_campaign = "";
        this.utm_medium = "";
        this.site_id = "";
    }
}