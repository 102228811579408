import { ThrowStmt } from '@angular/compiler';
import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { slideInAnimation } from './animations';
import { Souscription } from './_models/Souscription';
import { CacheService } from './_services/cache.service';
import { SouscriptionService } from './_services/souscription.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInAnimation
  ]
})
export class AppComponent {
  /*
  title = 'octopus';
  isComeback: boolean = null;
  souscription: Souscription;

  constructor(private souscriptionService: SouscriptionService, private router: Router, private cacheService: CacheService) {
    this.souscription = this.souscriptionService.getSouscription();

    this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationEnd
          && (event.url == '/' || (event.url.split('/')[3] == "recapulatif")))
          this.isComeback = true;
      }
    )
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  changes(value) {
    if (value === false) {
      localStorage.clear();
      window.location.href = '/demande-devis/identite';
    } else {
      let url: string;
      switch (this.souscriptionService.currentStep) {
        case 0:
          url = '/demande-devis/identite';
          break;
        case 1:
          url = '/demande-devis/adresse';
          break;
        case 2:
          url = '/demande-devis/logement';
          break;
        case 3:
          url = '/demande-devis/situation-professionnelle';
          break;
        case 4:
          url = '/demande-devis/naissance';
          break;
        case 5:
          url = '/demande-devis/preference-produit';
          break;
        case 6:
          url = '/demande-devis/informations-de-contact';
          break;
        case 7:
          url = '/demande-devis/resultats';
          break;
        case 8:
          url = '/souscription/documents';
          break;
        case 9:
          url = '/souscription/paiement';
          break;
        case 10:
          url = '/souscription/paiement';
          break;
      }
      this.router.navigate([url]);
    }

    this.isComeback = value;
  }
  */
}