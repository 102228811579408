<div class="spinner-wrapper">

  <div class="center-box">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

</div>