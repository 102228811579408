import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-g',
  templateUrl: './button-g.component.html',
  styleUrls: ['./button-g.component.scss']
})
export class ButtonGComponent implements OnInit {

  @Input() name: string;
  @Input() link: string;

  constructor() { }

  ngOnInit() {
  }

}
