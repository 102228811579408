<div class="container position-relative overflow-hidden p-3">
    <div class="close link" (click)="close()"><span class="d-none d-md-inline">Fermer </span><img
            src="/assets/spbfamily/close.svg" alt="Fermer"></div>
    <div class="text-center title primary-color my-3" *ngIf='!success'> Je souhaite être rappelé gratuitement </div>
    <div class="text-center title primary-color my-3" *ngIf='success'> Votre demande a été prise en compte </div>
    <div class="text-center second-title">Un conseiller SPB Family vous rappelle pour répondre à vos questions ! </div>
    <div class="text-center pt-3 description">Nous sommes ouverts du lundi au vendredi de 9h à 18h sauf les week-end
        et
        jours fériés.</div>

    <div class="overflow" *ngIf='!success'>
        <p class="text-center mt-3 mb-2"><strong>1 - Vérifiez vos informations</strong></p>
        <div class="row w-100 m-0">
            <div class="col-sm-12 col-md-4 p-1 mx-auto w-100">
                <app-input-field [placeholder]="souscriptionService.currentInputs[0].placeholder"
                    [name]="souscriptionService.currentInputs[0].name"
                    [value]="souscriptionService.currentInputs[0].value"
                    [pattern]="souscriptionService.currentInputs[0].pattern"
                    [required]="souscriptionService.currentInputs[0].required"
                    (valueEmitter)="souscriptionService.setInputValue($event.inputName, $event.inputValue, $event.isValid)">
                </app-input-field>
            </div>
            <div class="col-sm-12 col-md-4 p-1 mx-auto w-100">
                <app-input-field [placeholder]="souscriptionService.currentInputs[1].placeholder"
                    [name]="souscriptionService.currentInputs[1].name"
                    [value]="souscriptionService.currentInputs[1].value"
                    [pattern]="souscriptionService.currentInputs[1].pattern"
                    [required]="souscriptionService.currentInputs[1].required"
                    (valueEmitter)="souscriptionService.setInputValue($event.inputName, $event.inputValue, $event.isValid)">
                </app-input-field>
            </div>
            <div class="col-sm-12 col-md-4 p-1 mx-auto w-100">
                <app-input-field [placeholder]="souscriptionService.currentInputs[2].placeholder"
                    [name]="souscriptionService.currentInputs[2].name"
                    [value]="souscriptionService.currentInputs[2].value"
                    [pattern]="souscriptionService.currentInputs[2].pattern"
                    [required]="souscriptionService.currentInputs[2].required"
                    (valueEmitter)="souscriptionService.setInputValue($event.inputName, $event.inputValue, $event.isValid)">
                </app-input-field>
            </div>



            <p class="text-center mt-3 mb-2 w-100"><strong>2 - Spécifiez votre date de préférence pour le rappel</strong></p>
            <input type="date" [(ngModel)]='pref1' class="input-control col-sm-12 col-md-6 mx-auto my-1" name="dp">

            <select [(ngModel)]='pref2' class="input-control col-sm-12 col-md-6 mx-auto my-1">
                <option value="le matin">Le matin</option>
                <option value="le midi">Le midi</option>
                <option value="l'après-midi">L'après-midi</option>
            </select>


            <div class="center-item mt-2 text-center">
                <ng-container *ngIf='errors'>
                    <ng-container *ngFor='let error of errors'><i class="fas fa-exclamation-circle"></i> &nbsp; <span
                            class="text-danger">{{
                            error.message }}</span>
                        <br /><br />
                    </ng-container>
                </ng-container>

                <app-global-button-small
                    [disabled]='!souscriptionService.isCurrentStepValid && (pref1 == "dans quelques jours" && isValidDate(precisDate))'
                    *ngIf='souscriptionService.isCurrentStepValid != null' (click)='nextStep()'>
                    être rappelé.e
                </app-global-button-small>
            </div>
        </div>


        <div class="condition-generale">
            Conformément à la réglementation, les informations demandées sont nécessaires au traitement de votre
            demande.
            Ces informations sont destinées à SPB Family, responsable du traitement, à des fins de conseil et de
            gestion de
            votre demande. Nous vous rappelons que vous disposez d’un droit d’accès, de rectification, d’effacement,
            d’opposition, et de portabilité des données vous concernant, d’un droit d’opposition et de limitation aux
            traitements effectués par SPB Family à l’adresse suivante SPB Family Courtage 208 Quai de Paludate – 33800
            BORDEAUX ou par email :
            serviceclient@spbfamily.eu

            (1) En cliquant sur “Être rappelé”, j’accepte d’être recontacté par SPB Family pour qu’il puisse répondre à
            ma
            demande et me proposer un conseil personnalisé du produit d’assurance adapté à mes besoins.

            SPB Family Courtage : SAS de courtage d’assurance au capital de 50.000 euros dont le siège social est situé
            208
            Quai de Paludate, 33800 Bordeaux – www.spbfamily.eu, immatriculée au RCS de Bordeaux sous le n°821 784 840
            et à
            l’ORIAS sous le n° 16004724 (www.orias.fr). SPB Family Courtage ne détient aucune participation directe ou
            indirecte d’une compagnie d’assurance. Aucune entreprise d’assurance ne détient de participation directe ou
            indirecte dans la société SPB Family Courtage. Elle ne réalise pas plus de 33% de son chiffre d’affaires
            avec
            Oradéa Vie, CNP, IMA. Elle exerce comme courtier en assurance conformément aux dispositions de l’article
            L521-2,
            II b et travaille ou est susceptible de travailler avec les compagnies d’assurances suivantes : Prévoir
            Risques
            Divers, IMA Assurances, Auxia Assurances, CFDP Assurances, CNP Assurances SPB Family Courtage ne fournit pas
            de
            service de recommandation personnalisée. SPB Family Courtage est rémunérée sous forme de commissions.

            WAKAM S.A. au capital de 4 514 512 € – 562 117 085 R.C.S Paris – 120-122, rue Réaumur – 75002 PARIS –
            Entreprise
            régie par le code des assurances, Numéro SIREN 562 117 085.
        </div>
    </div>


</div>