import { Injectable } from '@angular/core';
import { CacheService } from './cache.service';

@Injectable({ providedIn: 'root' })

export class TranscriptService {

    constructor(private cacheService: CacheService) { }

    transform(messageValue: string): any {
        let regex = /\{\S+\}/gm;
        let messageReturned = messageValue;
        let occurences = messageReturned.match(regex)

        if (occurences)
            occurences.forEach(occurence => {
                occurence = occurence.substr(1, occurence?.length - 2); // removes { }
                let [params, filter] = occurence.split('|', 2); // occurenceFilter[0] => param1.param2.param3 // occurenceFilter[1] => filter 
                let occurenceParameters = params.split('.'); // occurenceParameters[0] => param1 // occurenceParameters[1] => param2 // ...
                let key = occurenceParameters[0]; // occurenceParameters[0] => param1
                let value = this.cacheService.get(key);
                if (value) {
                    occurenceParameters.forEach(parameter => { value = (value.hasOwnProperty(parameter)) ? value[parameter] : value; })
                    if (filter == 'uppercase')
                        value = value.toUpperCase();
                } else
                    value = 'undefined'

                messageReturned = messageReturned.replace('{' + occurence + '}', value)
            })

        return messageReturned;
    }

}