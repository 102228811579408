<div class="gradient-background pt-5">
    <app-steps-bar [steps]="['réalisation de votre devis' , 'DOCUMENTATION  PRECONTRACTUELLE' , 'CHOIX ET PAIEMENT  DE VOS COTISATIONS' , 'CONFIRMATION
        D\'ADHéSION' ]" [currentStep]="2"></app-steps-bar>
</div>

<div class="block-item">
    <app-agent imageSRC="../../../assets/spbfamily/Image 2@2x.png" agentSpeech="
        {{souscriptionService.getSouscription()?.prenom}}, vous avez choisi le règlement de vos cotisations par mandat SEPA. Nous allons le remplir ensemble.
        <br/>
        <br/>
        Ce mandat vaut pré-notification pour les prochains prélèvements SEPA qui seront effectués dans <br />les conditions
         décrites dans votre bulletin d'adhésion ou certificat d'adhésion et dans votre<br /> Notice d'Information.
        <br/>
        <br/>
        {{textPrice}}." textPadding="90-padding">
    </app-agent>

    <div class="container">

        <div class="row">
            <div class="col-sm-12 col-md-8 pt-2">
                <app-input-field [placeholder]="souscriptionService.getInputByName('iban').placeholder"
                    [name]="souscriptionService.getInputByName('iban').name"
                    [value]="souscriptionService.getInputByName('iban').value"
                    [pattern]="souscriptionService.getInputByName('iban').pattern"
                    [required]="souscriptionService.getInputByName('iban').required"
                    (valueEmitter)="souscriptionService.setInputValue($event.inputName, $event.inputValue, $event.isValid)">
                </app-input-field>
            </div>
            <div class="col-sm-6 col-md-4 pt-2">
                <app-input-field [placeholder]="souscriptionService.getInputByName('bic').placeholder"
                    [name]="souscriptionService.getInputByName('bic').name"
                    [value]="souscriptionService.getInputByName('bic').value"
                    [pattern]="souscriptionService.getInputByName('bic').pattern"
                    [required]="souscriptionService.getInputByName('bic').required"
                    (valueEmitter)="souscriptionService.setInputValue($event.inputName, $event.inputValue, $event.isValid)">
                </app-input-field>
            </div>
        </div>


        <div class="row mb-4">
            <div class="col-sm-12 col-md-4 mt-4">
                <label class="uppercase" for="date">Date de prélévement :</label>
            </div>
            <div class="col-sm-12 col-md-4 mt-4">
                <app-switch-button name="datePrelevement" [checked]='checkPrel5' id="datePrelevement" value="5"
                    class="uppercase" (valueEmitter)='date=5; checkPrel12=false; checkPrel5=true;'>le
                    <strong>5</strong>
                    de chaque mois
                </app-switch-button>
            </div>
            <div class="col-sm-12 col-md-4 mt-4">
                <app-switch-button name="datePrelevement" [checked]='checkPrel12' id="datePrelevement" value="12"
                    class="uppercase" (valueEmitter)='date=12; checkPrel12=true; checkPrel5=false;'>le
                    <strong>12</strong> de chaque mois
                </app-switch-button>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <app-global-checkbox (valueEmitter)='check1 = $event.inputValue' name="check1">En cochant cette case
                    j'autorise SPB Family Courtage à envoyer des instructions à
                    ma
                    banque pour débiter mon compte, et
                    ma banque à débiter mon compte conformément aux instructions de SPB Family Courtage.<br />
                    <br />
                    Je bénéficie du droit d'être remboursé par ma banque selon les conditions décrites dans la
                    convention
                    que j'ai passée
                    avec elle.<br />
                    Une demande de remboursement doit être présentée dans les 8 semaines suivant la date de débit de
                    votre
                    compte pour un
                    prélèvement autorisé. Mes droits concernant le présent mandat sont expliqués dans un document que je
                    peux obtenir auprès
                    de ma banque. Les informations contenues dans le présent document sont destinées à n'être utilisées
                    que
                    par SPB Family
                    Courtage pour la gestion de la relation avec son client. Elles pourront donner lieu à l'exercice par
                    ce
                    dernier de ses
                    droits d'opposition, d'accès et de rectification tels que prévus aux articles 38 et suivants de la
                    Loi
                    n°78-17 du 6
                    janvier 1978 modifiée relative à l'informatique, aux fichiers et aux libertés.*
                </app-global-checkbox>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <app-global-checkbox (valueEmitter)='check2 = $event.inputValue' name="check2">J'accepte de démarrer les
                    garanties pendant le délai de renonciation de 14 jours
                    tel
                    que prévu dans la Notice
                    d'Information.*</app-global-checkbox>
            </div>
        </div>

        <br />
        <br />
        <small>* Champs obligatoires.</small>



        <div class="center-item my-2">
            <ng-container *ngIf='errors'>
                <ng-container *ngFor='let error of errors'><i class="fas fa-exclamation-circle"></i> &nbsp; <span
                        class="text-danger">{{
                        error.message }}</span>
                </ng-container>
                <br /><br />
            </ng-container>

            <app-global-button-small [disabled]='!souscriptionService.isCurrentStepValid || !check1 || !check2'
                (click)='nextStep()' icon="/assets/spbfamily/secure.svg">
                JE VALIDE MA SOUSCRIPTION
            </app-global-button-small>
        </div>
    </div>

</div>