<div class="gradient-background pt-5">
    <app-steps-bar [steps]="['réalisation de votre devis' , 'DOCUMENTATION  PRECONTRACTUELLE' , 'CHOIX ET PAIEMENT  DE VOS COTISATIONS' , 'CONFIRMATION
        D\'ADHéSION' ]" [currentStep]="2"></app-steps-bar>
</div>

<div class="container pt-4">
    <app-agent imageSRC="../../../assets/spbfamily/Image 2@2x.png" agentSpeech="{{souscription?.prenom}}, comment allez-vous régler vos cotisations ?<br/>
                    <br/>
                    Vous avez le choix entre le paiement par prélèvement mensuel<br />
                    ou le règlement de votre cotisation en une seule fois pour une année entière.<br />
                    Les cotisations d'assurance seront prélevées par SPB Family Courtage." textPadding="90-padding">
    </app-agent>

    <div class="row">
        <div class="col-sm-12 col-md-6 pt-2 text-center">
            <app-price-tag (click)="openPayment('mensuel')" [price]="souscription?.tarifMensuel" small="PAR MOIS" name="method" value="monthly">PAIEMENT MENSUEL</app-price-tag>
        </div>
        <div class="col-sm-12 col-md-6 pt-2 text-center">
            <app-price-tag (click)="openPayment('annuel')" [price]="souscription?.tarifAnnuel" small="PAR AN" name="method" value="annually">PAIEMENT ANNUEL</app-price-tag>
        </div>
    </div>

</div>