<div class="card-box" [ngClass]="{'extra-box': extra}">
  <div class="extra" *ngIf="extra" [innerHTML]="extra"></div>
  <div class="imageC d-inline-block ShadowBox">
    <img src="{{ image }}" alt="{{ title }}">
  </div>
  <div class="card-title d-inline-block">
    <p> &nbsp; {{ title }}</p>
  </div>
  <div class="card-text">
    <p>{{testimonial}}</p>
  </div>
</div>