<app-global-header></app-global-header>
<div class="gradient-background" style="height: 23%;">
    <div class="container text-center">
        <h1 class="title text-center my-1 pt-5">{{souscription?.prenom}}, voici votre devis</h1>
        <p class="second-title text-center  ">Nous vous l'avons envoyé par mail avec votre Fiche d'Information et de
            Conseil
            Personnalisée.</p>
        <p class="second-title text-center primary-color">D'après l'expression de votre besoin nous vous recommandons
            :</p>

        <div class="row center-display text-center" *ngIf='product'>
            <div class="col center-item"><img [src]="imgSRC" height="50px" [alt]='product?.name' *ngIf='imgSRC'></div>
            <div class="col center-item pt-4">
                <div class="circle center-item">
                    seulement <span class="price">{{decimalValue(product?.tarifMensuel)}}€</span> <br> par mois
                </div>
                <div class="caption center-item"> ou {{decimalValue(product?.tarifAnnuel)}} € / AN</div>
            </div>
        </div>

        <div class="row center-display text-center pt-5">
            <div class="col center-item d-block">
                <app-global-button-invert routerLink='/souscription/documents' class="normal">JE SOUSCRIS MAINTENANT
                </app-global-button-invert>
            </div>
            <div class="col center-item">
                <app-global-button-invert class="invert" (click)='openCallMeBackModal()'>JE PRéfère qu'un CONSEILLER ME
                    RAPPELLE
                </app-global-button-invert>
            </div>
        </div>

        <div *ngIf="decimalValue(product?.tarifMensuel) == '13.90'">
            <div class="second-title text-center fs-1 pt-5">Qu’est-ce qui est assuré ?</div>

            <div class="small-container text-center mt-5 mx-auto  ">
                <img class="text-center py-3" src="../../../../assets/spbfamily/locataire.png" height="150px">
                <div class="primary-color text-center pt-2">Les factures mensuelles <br> du loyer de votre résidence
                    principale.</div>
                <div class="hint-text text-center pt-2 pb-4"> dans la limite de 9600 € TTC par année d’assurance.</div>
            </div>

            <img class="pt-3" src="../../../../assets/spbfamily/plus in circle.svg" height="50px">
        </div>


        <div *ngIf="decimalValue(product?.tarifMensuel) != '13.90'">

            <div class="text-center title-icon">
                <a> Qu’est-ce qui est assuré ? </a>
            </div>
        </div>

        <div *ngIf="decimalValue(product?.tarifMensuel) == '13.90'">
            <div class="text-center title-icon mt-3 pt-3">
                <img src="../../../../assets/spbfamily/logo protect factures@2x.png" height="50px">
            </div>
        </div>


        <div class="icon-container mb-3">
            <div class="row mx-auto p-5 text-center">
                <div class="col-sm-12 col-md-6 col-lg-3 pt-3">
                    <img src="../../../../assets/spbfamily/facture-gaz.svg">
                    <div class="icon-title primary-color">Les factures mensuelles de gaz.</div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 pt-3">
                    <img src="../../../../assets/spbfamily/facture-electricité.svg">
                    <div class="icon-title primary-color">Les factures mensuelles d'électricité.</div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 pt-3">
                    <img src="../../../../assets/spbfamily/facture-d'eau.svg">
                    <div class="icon-title primary-color">Les factures mensuelles d'eau.</div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 pt-3">
                    <img src="../../../../assets/spbfamily/facture-fioul.svg">
                    <div class="icon-title primary-color">Les factures mensuelles de fioul.</div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 text-center">
                    <p class="hint-text"> dans la limite de 3 000 € TTC par année d’assurance. </p>
                </div>
            </div>
        </div>
        <br />
        <br />

    </div>
</div>