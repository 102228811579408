import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-card',
  templateUrl: './simple-card.component.html',
  styleUrls: ['./simple-card.component.scss']
})
export class SimpleCardComponent implements OnInit {

  @Input() image: string;
  @Input() title: string;
  @Input() testimonial: string;
  @Input() extra: string;

  constructor() { }

  ngOnInit() {
  }

}
