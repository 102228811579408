<div class="birthdate clearfix">
    <label for="day" class="main-label">
        <img [src]="icon" [alt]="name">
        <p>{{ name }}</p>
    </label>

    <div class="select-section">
        <div class="select">
            <label for="day">JJ</label>
            <select name="day" id="day">
                <option
                    *ngFor="let number of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]"
                    [value]="number">{{number}}</option>
            </select>
        </div>

        <div class="select">
            <label for="month">MM</label>
            <select name="month" id="month">
                <option *ngFor="let number of [1,2,3,4,5,6,7,8,9,10,11,12]" [value]="number">{{number}}</option>
            </select>
        </div>

        <div class="select" *ngIf="!disableYear">
            <label for="year">AAAA</label>
            <select name="year" id="year">
                <option *ngFor='let number of [].constructor(46); let i = index' [value]="(currentYear-18-i)">
                    {{((currentYear-18-i))}}</option>
            </select>
        </div>
    </div>

</div>