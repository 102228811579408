import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Souscription } from '../_models/Souscription';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })

export class FormsService {

    constructor(private http: HttpClient) { }

    sendForm(route: string, object: Object): Observable<any> {
        let data = new FormData();
        
        for (const [key, value] of Object.entries(object))
            data.append(key, value);
        
        return this.http.post<any>(`/form/${route}`, data);
    }

}