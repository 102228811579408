import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ModalRappelComponent } from 'src/app/_components/modal-rappel/modal-rappel.component';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss']
})
export class HelpModalComponent implements OnInit {

  FAQs: any[] = [];

  constructor(public dialogRef: MatDialogRef<ModalRappelComponent>, private httpClient: HttpClient) { }

  ngOnInit() {
    this.httpClient.get<any[]>('/faq').subscribe(
      (faqs) => this.FAQs = faqs,
      () => {},
    )
  }

  close() {
    this.dialogRef.close();
  }


}
