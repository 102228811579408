<div class="gray-background text p-5">
    <p>
        *En cas de dommages corporels accidentels importants et permanents survenus au cours de la vie privée.
        ** Source : www.lelynx.fr
        <br />
        <br />
        Conformément à la réglementation, les informations demandées sont nécessaires au traitement de votre demande.
        Ces
        informations sont destinées à SPB Family, responsable du traitement, à des fins de conseil et de gestion de
        votre
        demande. Nous vous rappelons que vous disposez d’un droit d’accès, de rectification, d’effacement, d’opposition,
        et de
        portabilité des données vous concernant, d’un droit d’opposition et de limitation aux traitements effectués par
        SPB
        Family ainsi que d’un droit d’opposition à la prospection commerciale auprès de SPB Family Courtage à l’adresse
        suivante
        208 Quai de Paludate – 33800 BORDEAUX ou par email : serviceclient@spbfamily.eu
        <br />
        <br />
        SPB Family Courtage : SAS de courtage d’assurance au capital de 50.000 euros dont le siège social est situé 208
        Quai de
        Paludate, 33800 Bordeaux – www.spbfamily.eu, immatriculée au RCS de Bordeaux sous le n°821 784 840 et à l’ORIAS
        sous le
        n° 16004724 (www.orias.fr). SPB Family Courtage ne détient aucune participation directe ou indirecte d’une
        compagnie
        d’assurance. Aucune entreprise d’assurance ne détient de participation directe ou indirecte dans la société SPB
        Family
        Courtage. Elle ne réalise pas plus de 33% de son chiffre d’affaires avec Oradéa Vie, CNP, IMA. Elle exerce comme
        courtier en assurance conformément aux dispositions de l’article L521-2, II b et travaille ou est susceptible de
        travailler avec les compagnies d’assurances suivantes : Prévoir Risques Divers, IMA Assurances, Auxia
        Assurances, CFDP
        Assurances, CNP Assurances SPB Family Courtage ne fournit pas de service de recommandation personnalisée. SPB
        Family
        Courtage est rémunérée sous forme de commissions.
        <br />
        <br />
        ©{{ date | date:'yyyy'}} - SPB Family - <a href="#">mentions légales</a> - <a href="#">Conditions générales</a>.
    </p>
</div>