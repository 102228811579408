import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Partner } from 'src/app/_models/Partner';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Form } from '../_models/Form';


@Injectable({ providedIn: 'root' })

export class EmbedService {

    constructor(private http: HttpClient) { }

    getForm(uid: string, token: string, host:string): Observable<Form> {
        return this.http.get<Form>(`/embed?partner=` + uid + `&token=` + token + `&origin=` + host +  `|form`)
    }

}