import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorPageComponent } from './_pages/error-page/error-page.component';
import { SpbfamilyPageComponent } from './_partners/_spbfamily/_pages/spbfamily-page/spbfamily-page.component';
import { QuoteContainerComponent } from './_partners/_spbfamily/_modules/_quote/quote-container/quote-container.component';
import { QuoteFirstStepComponent } from './_partners/_spbfamily/_modules/_quote/quote-first-step/quote-first-step.component';
import { QuoteSecondStepComponent } from './_partners/_spbfamily/_modules/_quote/quote-second-step/quote-second-step.component';
import { QuoteThirdStepComponent } from './_partners/_spbfamily/_modules/_quote/quote-third-step/quote-third-step.component';
import { QuoteFourthStepComponent } from './_partners/_spbfamily/_modules/_quote/quote-fourth-step/quote-fourth-step.component';
import { QuoteFifthstepComponent } from './_partners/_spbfamily/_modules/_quote/quote-fifthstep/quote-fifthstep.component';
import { QuoteSixthStepComponent } from './_partners/_spbfamily/_modules/_quote/quote-sixth-step/quote-sixth-step.component';
import { QuoteSeventhStepComponent } from './_partners/_spbfamily/_modules/_quote/quote-seventh-step/quote-seventh-step.component';
import { SouscriptionContainerComponent } from './_partners/_spbfamily/_modules/_souscription/souscription-container/souscription-container.component';
import { SouscriptionFirstStepComponent } from './_partners/_spbfamily/_modules/_souscription/souscription-first-step/souscription-first-step.component';
import { SouscriptionSecondStepComponent } from './_partners/_spbfamily/_modules/_souscription/souscription-second-step/souscription-second-step.component';
import { QuoteEitherStepComponent } from './_partners/_spbfamily/_modules/_quote/quote-either-step/quote-either-step.component';
import { SouscriptionThirdStepComponent } from './_partners/_spbfamily/_modules/_souscription/souscription-third-step/souscription-third-step.component';
import { SouscriptionThirdStepBankInfosComponent } from './_partners/_spbfamily/_modules/_souscription/souscription-third-step-bank-infos/souscription-third-step-bank-infos.component';
import { SouscriptionThirdStepSignatureComponent } from './_partners/_spbfamily/_modules/_souscription/souscription-third-step-signature/souscription-third-step-signature.component';
import { SouscriptionFinalStepComponent } from './_partners/_spbfamily/_modules/_souscription/souscription-final-step/souscription-final-step.component';
import { SouscriptionEchecComponent } from './_partners/_spbfamily/_modules/_souscription/souscription-echec/souscription-echec.component';
import { OneyPageComponent } from './_partners/_oney/oney-page/oney-page.component';
import { SouscriptionComponent } from './_pages/souscription/souscription.component';
import { ValidationSectionComponent } from './_sections/validation-section/validation-section.component';
import { DocumentsSectionComponent } from './_sections/documents-section/documents-section.component';
import { ModalitePaiementSectionComponent } from './_sections/modalite-paiement-section/modalite-paiement-section.component';
import { SepaSectionComponent } from './_sections/sepa-section/sepa-section.component';
import { RecapulatifSectionComponent } from './_sections/recapulatif-section/recapulatif-section.component';
import { ResultSectionComponent } from './_sections/result-section/result-section.component';
import { ResultKoSectionComponent } from './_sections/result-ko-section/result-ko-section.component';
import { OneyFormPageComponent } from './_partners/_oney/oney-form-page/oney-form-page.component';


const routes: Routes = [
  // PROD ROUTES ;
  {
    path: '',
    component: OneyFormPageComponent,
    data: { animation: 'HomePage' }
  },
  /*
  {
    path: '',
    component: SpbfamilyPageComponent,
    data: { animation: 'HomePage' }
  },
  {
    path: 'politique-confidentialite',
    component: SpbfamilyPageComponent
  },
  {
    path: 'demande-devis',
    component: QuoteContainerComponent,
    children: [
      {
        path: 'identite',
        component: QuoteFirstStepComponent,
        data: { animation: '1' }
      },
      {
        path: 'adresse',
        component: QuoteSecondStepComponent,
        data: { animation: '2' }
      },
      {
        path: 'logement',
        component: QuoteThirdStepComponent,
        data: { animation: '3' }
      },
      {
        path: 'situation-professionnelle',
        component: QuoteFourthStepComponent,
        data: { animation: '4' }
      },
      {
        path: 'naissance',
        component: QuoteFifthstepComponent,
        data: { animation: '5' }
      },
      {
        path: 'preference-produit',
        component: QuoteSixthStepComponent,
        data: { animation: '6' }
      },
      {
        path: 'informations-de-contact',
        component: QuoteSeventhStepComponent,
        data: { animation: '7' }
      },
      
    ]
  },
  {
    path: 'demande-devis/resultats',
    component: QuoteEitherStepComponent,
    data: { animation: 'QuoteResult' }
  },
  {
    path: 'souscription',
    component: SouscriptionContainerComponent,
    children: [
      {
        path: 'documents',
        component: SouscriptionSecondStepComponent,
        data: { animation: 'SouscriptionStep2' }
      },
      {
        path: 'paiement',
        component: SouscriptionThirdStepComponent,
        data: { animation: 'SouscriptionStep3' }
      },
      {
        path: ':uid/paiement',
        component: SouscriptionThirdStepComponent,
        data: { animation: 'SouscriptionStep3' }
      },
      {
        path: 'paiement/banque/:type',
        component: SouscriptionThirdStepBankInfosComponent,
        data: { animation: 'SouscriptionStep3Bank' }
      },
      {
        path: 'paiement/signature',
        component: SouscriptionThirdStepSignatureComponent,
        data: { animation: 'SouscriptionStep3Sign' }
      },
      {
        path: 'final',
        component: SouscriptionFinalStepComponent,
        data: { animation: 'SouscriptionFinal' }
      },
      {
        path: ':id/recapulatif/success',
        component: SouscriptionFinalStepComponent,
        data: { animation: 'SouscriptionFinal' }
      },
      {
        path: ':id/recapulatif/cancel',
        component: SouscriptionEchecComponent,
        data: { animation: 'SouscriptionFinal' }
      },
      {
        path: ':id/recapulatif/fail',
        component: SouscriptionEchecComponent,
        data: { animation: 'SouscriptionFinal' }
      },
    ]
  },*/

  // ONEY
  {
    path: 'souscription/:uid',
    component: SouscriptionComponent,
    children: [
      {
        path: 'validation',
        component: ValidationSectionComponent
      },
      {
        path: 'documents',
        component: DocumentsSectionComponent
      },
      {
        path: 'modalites',
        component: ModalitePaiementSectionComponent
      },
      {
        path: 'sepa',
        component: SepaSectionComponent
      },
      {
        path: 'recapulatif',
        component: RecapulatifSectionComponent,
        children: [
          {
            path: 'success',
            component: ResultSectionComponent
          },
          {
            path: 'cancel',
            component: ResultKoSectionComponent
          },
          {
            path: 'failed',
            component: ResultKoSectionComponent
          },
        ]
      },
    ]
  },

  {
    path: 'error',
    component: ErrorPageComponent
  },

  {
    path: '**',
    redirectTo: '/error',
    pathMatch: 'full'
  }
];






@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
