import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SouscriptionService } from 'src/app/_services/souscription.service';

@Component({
  selector: 'app-payment-method-modal',
  templateUrl: './payment-method-modal.component.html',
  styleUrls: ['./payment-method-modal.component.scss']
})
export class PaymentMethodModalComponent implements OnInit {

  step: number = 0; // Step 0 = Modal choice // Step 1 = Credit card // IBAN is a redirection
  price: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<PaymentMethodModalComponent>, private souscriptionService:SouscriptionService,
  private router:Router) {
  }

  ngOnInit() {
    if (this.data.type == 'mensuel')
      this.price = Number.parseFloat(this.souscriptionService.getSouscription().tarifMensuel);
      else
      this.price = Number.parseFloat(this.souscriptionService.getSouscription().tarifAnnuel);
  }

  navigateToBank() {
    this.router.navigate(["/souscription/paiement/banque/" + this.data.type]);
    this.dialogRef.close()
  }
}
