import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SouscriptionService } from 'src/app/_services/souscription.service';

@Component({
  selector: 'app-validation-section',
  templateUrl: './validation-section.component.html',
  styleUrls: ['./validation-section.component.scss']
})
export class ValidationSectionComponent implements OnInit {

  souscription;
  error: string;

  constructor(
    private souscriptionService: SouscriptionService,
    private httpClient: HttpClient,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit() {
    this.souscription = this.souscriptionService.getSouscription();
  }

  startEdit() {
    window.location.href = '/?mode=edit#formulaire';
  }

  nextStep() {
    let data = new FormData();
    data.append('uid', this.souscription.id);
    this.souscriptionService.getSouscription().step = 2;

    this.httpClient.post<any>(`/souscription/senddocs`, data).subscribe(
      () => this.router.navigate(['/souscription/' + this.souscription.id + '/documents']),
      () => this.error = "Un problème est survenu",
    );
  }

}
