<div class="quota-wrapper">
    <div class="quota-question">
        <app-agent imageSRC="/assets/spbfamily/Image 2@2x.png"
            agentSpeech="Super {{souscription?.prenom}} ! J'ai toutes les informations pour votre devis.<br/>Pour vous l'envoyer j'ai juste besoin de votre email et de votre numéro de téléphone. ">
        </app-agent>
    </div>

    <div class="quota-reply" *ngFor='let input of souscriptionService.currentInputs'>
        <div class="flex-100">
            <app-input-field [icon]="input.icon" [placeholder]="input.placeholder" [name]="input.name"
                [value]="input.value" [pattern]="input.pattern" [required]="input.required"
                (valueEmitter)="souscriptionService.setInputValue($event.inputName, $event.inputValue, $event.isValid)" heightIcon="25px" widthIcon="19px">
            </app-input-field>
        </div>
    </div>

    <div class="quota-reply px-3">
        <div class="flex-100">
            <app-global-checkbox name="optim_ipid" (valueEmitter)='checked($event.inputName, $event.inputValue)'>J’accepte de recevoir par email la Notice, l’IPID et la Fiche d’Information
                et de Conseil personnalisée du produit d’assurance adapté à mes besoins*.</app-global-checkbox>
            <app-global-checkbox name="optim_emailing" (valueEmitter)='checked($event.inputName, $event.inputValue)'>J’accepte que la société SPB Family me propose par courriel de nouveaux produits ou
                services. Je pourrai me désinscrire
                à tout moment à travers les liens de désinscription**.*</app-global-checkbox>
        </div>
    </div>

    <div class="quota-reply px-3 mb-2">
        <div class="center-item">
            <ng-container *ngIf='errors'>
                <ng-container *ngFor='let error of errors'><i class="fas fa-exclamation-circle"></i> &nbsp; <span
                        class="text-danger">{{
                        error.message }}</span>
                </ng-container>
                <br /><br />
            </ng-container>

            <app-global-button-small [disabled]='!souscriptionService.isCurrentStepValid || !optimIPIDSelected || !optimSubscribedSelected'
                *ngIf='souscriptionService.isCurrentStepValid != null' (click)='nextStep()'>Continuer</app-global-button-small>
        </div>
    </div>

    <div class="quota-reply px-3 mb-2">
        <div class="flex-100">
            <small>
                * Champs obligatoires<br />
                **Conformément à la réglementation, les informations demandées sont nécessaires au traitement de
                votre
                demande. Ces
                informations sont destinées à SPB Family, responsable du traitement, à des fins de conseil et de
                gestion de votre
                demande.
            </small>
        </div>
    </div>

</div>