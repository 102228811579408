import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spbfamily-page',
  templateUrl: './spbfamily-page.component.html',
  styleUrls: ['./spbfamily-page.component.scss']
})
export class SpbfamilyPageComponent implements OnInit {

  ImgLoaded: boolean = false;
  constructor() { }

  ngOnInit() {
  }

}
