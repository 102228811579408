<app-global-header returnBtnOption='home' routerLink='/'></app-global-header>

<div class="quota-wrapper">
    <div class="quota-question">
        <app-agent imageSRC="/assets/spbfamily/Image 2@2x.png" agentSpeech="Bonjour {{souscription?.prenom}},
            <br/>Voulez-vous reprendre depuis votre dernier point enregistré ?">
        </app-agent>
    </div>

    <div class="row center-display text-center pt-5">
        <div class="col center-item d-block">
            <app-global-button-invert class="normal" (click)='valueEmitter.emit(true)'>
                Reprendre
            </app-global-button-invert>
        </div>
        <div class="col  center-item">
            <app-global-button-invert class="invert" (click)='valueEmitter.emit(false)'>
                Recommencer à nouveau
            </app-global-button-invert>
        </div>
    </div>
</div>