import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Error } from 'src/app/_models/Error';
import { Input } from 'src/app/_models/Input';
import { Souscription } from 'src/app/_models/Souscription';
import { SouscriptionService } from 'src/app/_services/souscription.service';

@Component({
  selector: 'app-quote-first-step',
  templateUrl: './quote-first-step.component.html',
  styleUrls: ['./quote-first-step.component.scss']
})
export class QuoteFirstStepComponent implements OnInit {

  constructor(public souscriptionService: SouscriptionService, private router: Router) { }

  errors: Error[];
  souscription: Souscription;

  ngOnInit() {
    this.souscription = this.souscriptionService.getSouscription();

    this.souscriptionService.setCurrentInputs([
      new Input("firstname", "Prénom", (this.souscription) ? this.souscription.prenom : null, true, "[a-zA-Z ]+", "101000"),
      new Input("lastname", "Nom", (this.souscription) ? this.souscription.nom : null, true, "[a-zA-Z ]+", "102000")
    ])
  }

  nextStep() {
    if (this.souscriptionService.isCurrentStepValid)
      this.souscriptionService.nextStep(0).subscribe(
        () => { this.errors = []; this.router.navigate(['/demande-devis/adresse']) },
        err => { this.errors = err },
      );
  }

}
