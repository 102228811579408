<div class="gradient-background">
    <div class="container-fluid wrapper">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4-plus mt-3">
                <app-simple-card 
                    title="Prénom Nom ville" image="https://picsum.photos/200"
                    testimonial="&quot;Lorsque Julien a été licencié, nous étions très inquiets, nous avions la maison à finir de payer, nos filles qui poursuivaient leurs études… Heureusement, Protect Factures nous a permis de souffler un peu et de rebondir.&quot;"
                    extra="En 2020<br/>
                    les Français ont<br/> dépensé<br />
                    <strong class='bigger-text'>1684€</strong><br />
                    pour chauffer<br />
                    leur logement !**"></app-simple-card>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4-plus mt-3">
                <app-simple-card title="Marc Moreau de Dijon" image="https://picsum.photos/201"
                    testimonial="&quot;Je voulais une assurance pour m’aider à payer mes factures en cas de mauvaises surprises. ça prend en charge le paiement de mes factures et de mon loyer pour un prix franchement plus qu’abordable. Je vous conseille cette assurance si vous voulez assurer vos arrières.&quot;">
                </app-simple-card>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4-plus mt-sm-7">
                <app-simple-card title="Noémie Martin de Bordeaux" image="https://picsum.photos/202"
                    testimonial="&quot;J’ai découvert Protect habitat sur internet. Depuis ma souscription je suis tranquille car avec cette assurance mon loyer et mes factures sont couverts en cas d’imprévus.&quot;"
                    extra="Transparent,<br/>simple et rapide :<br/><br />
                    obtenez<br/>
                    votre devis gratuit<br/>
                    en 2 minutes !">
                </app-simple-card>
            </div>
        </div>
    </div>
    <h2 class="mt-5 pt-5 px-2 text-center">Il y a des mois où tout va et des mois où tout s'en va…<br />
        sauf les factures à payer et pour certains le loyer à verser.</h2>
</div>