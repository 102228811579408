<div class="container" *ngIf='false'>
    <div class="section">
        <div class="highlight">
            <div class="icon"><img src="./assets/euro.svg" width="30px"/></div>
            <p>Plusieurs choix s'offrent à vous !</p>
        </div>
    </div>

    <div class="section">
        <h1>Je choisi mes modalités de paiement !</h1>

        <p><strong>Vous avez le choix entre un paiement par prélévement mensuel<br/>ou de régler votre cotisation en une seule fois pour l'année entière.</strong></p>
        
        <p><strong>Information importante :</strong> les cotisations d'assurance seront prélevées par SPB Family Courtage. Courtier gestionnaire partenaire d'Oney.</p>


        <label>
            <input type="checkbox" [checked]="isMensuel === true"
            (change)="isMensuel = !isMensuel">
            Je choisis la formule annuelle avec un paiement mensuel de {{ souscription?.tarifMensuel }} &euro;
        </label>

        <br/>

        <label>
            <input type="checkbox" [checked]="isMensuel === false"
            (change)="isMensuel = (isMensuel === null)? false : !isMensuel">
            Je choisis la formule annuelle prépayée à {{ souscription?.tarifAnnuel }} &euro; (12 x {{ souscription?.tarifMensuel }} &euro; / mois).
        </label>
    </div>
    
</div>

<ng-container *ngIf='true'>
    <p class="text-center pt-3">
        <strong>
            Formule annuelle avec un paiement mensuel de {{ souscription?.tarifMensuel }} &euro;
        </strong>
    </p>
    <app-sepa-section></app-sepa-section>
</ng-container>

<ng-container *ngIf="loading">
    <div class="text-center p-5">
        <img src="./assets/loading.gif" alt="loading">
    </div>
    <div class="button-section">
        <button class="secondary" routerLink='../documents'><i class="fas fa-caret-left"></i>&nbsp; Retour</button>
        <button class="primary" disabled>Je continue &nbsp; <i class="fas fa-caret-right"></i></button>
    </div>
</ng-container>

<ng-container *ngIf="!loading">

    <div class="w-100 pt-3 text-center" *ngIf='error'>
        <span class="text-danger">{{ error }}</span>
    </div>
    
    <div class="button-section row">
 
        <button class="col-sm-12 col-md-3 secondary" routerLink='../documents'><i class="fas fa-caret-left"></i>&nbsp; Retour</button>
        <button class="col-sm-12 col-md-3 primary" (click)='next()'>Je continue &nbsp; <i class="fas fa-caret-right"></i></button>
    </div>
</ng-container>

