import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-steps-bar',
  templateUrl: './steps-bar.component.html',
  styleUrls: ['./steps-bar.component.scss']
})
export class StepsBarComponent implements OnInit {
  @Input() steps: Array<string>;
  @Input() currentStep: number;

  constructor() {

  }

  ngOnInit() {
  }

  public calculWidth(): any {
    let styles = {
      'width': 100 / this.steps?.length + '%',
    };
    
    return styles;
  }
}