import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })

export class CacheService {

  private cache: any = {};
  private isStarted: boolean = false;

  constructor(private httpClient: HttpClient) { }

  public start() {
    if (this.getFromLocalMemory('data')) {
      this.isStarted = true;
      return;
    }

    this.httpClient.get<any>(`/loader|data`)
      .subscribe(loaderResponse => {
        this.set('data', loaderResponse);
        this.isStarted = true;
      })
  }

  // #TODO : After securring data here we should decrypt it !
  public get(key: string, id?: string) {    
    return (null != this.getFromRuntimeMemory(key, id))
      ? this.getFromRuntimeMemory(key, id)
      : this.getFromLocalMemory(key, id);
  }

  // Runtime Memory Storage
  getFromRuntimeMemory(key: string, id?: string) {
    // If global key is requested (global key = data)
    /*if (key == 'data') {
      // If it has enough elements
      if (Object.keys(this.cache).length >= 14)
        return this.cache;
    }*/
    // Any other key : check the existance of the key
    if (this.cache.hasOwnProperty(key)) {
      // If a specific ID is being requested return only the object with the ID
      if (id) {
        this.cache[key][this.cache[key].map(function (e) { return e.id.toString() }).indexOf(id.toString())];
      }
      // else return the object related to the key
      else
        return this.cache[key];
    }
    // if nothing is found null is returned
    return null;
  }

  // Local Memory Storage
  getFromLocalMemory(key: string, id?: string) {
    // Get local storage by global key : data
    let dataStr: string = localStorage.getItem('data');
    // Parse to JSON
    let dataObj = null;
    try {
      dataObj = JSON.parse(dataStr);
    } catch (e) {
    }
    // If JSON is not null
    if (dataObj) {
      // Save local memory into runtime memory
      this.cache = dataObj;
      return this.getFromRuntimeMemory(key, id);
    }
    return null;
  }

  set(key: string, value: any, id?: string, force?: boolean) {
    // #TODO : Encrypt
    if (!force && !this.isStarted)
      return;

    // Memory Storage
    if (key == 'data')
      Object.assign(this.cache, value);
    else
      this.cache[key] = value;

    // Local Storage
    localStorage.setItem('data', JSON.stringify(this.cache));
  }


  addItem(key: string, value: any, force?: boolean) {
    if (!force && !this.isStarted)
      return;

    let items: any[] = this.get(key);

    if (Array.isArray(items) && value.hasOwnProperty('id')) {
      // check if element already exists
      let idx = items.map(function (i) { return i.id.toString() }).indexOf(value.id.toString());

      if (idx == -1) {
        // item is new
        items.push(value);
        this.set(key, items, null, force);
      }
    }
  }

  editItem(key: string, value: any, id: string, force?: boolean) {
    if (!force && !this.isStarted)
      return;

    let items: any[] = this.get(key);

    if (Array.isArray(items)) {
      let idx = items.map(function (e) { return e.id.toString() }).indexOf(id.toString());

      if (idx != -1) {
        items[idx] = value;
        this.set(key, items, null, force);

        return true;
      } else
        // If not found create and add the item
        this.addItem(key, value, force);
    }

    return false;
  }

  removeItem(key: string, id: string, force?: boolean) {
    if (!force && !this.isStarted)
      return;

    let items: any[] = this.get(key);

    if (items) {
      let idx = items.map(function (e) { return e.id.toString() }).indexOf(id.toString());

      if (idx != -1) {
        items.splice(idx, 1)
        this.set(key, items, null, force)
        return true;
      }
    }

    return false;
  }

  clear() {
    // Save Globals
    let globals = this.get('globals');

    // Clear others
    this.cache = {};
    localStorage.removeItem('data');

    // Set Globals again
    if (globals)
      this.set('globals', globals, null, true);
  }

}