<div *ngIf='false' id="cookie-law-info-bar" data-nosnippet="true"
    style="background-color: rgb(255, 255, 255); color: rgb(0, 0, 0); font-family: inherit; bottom: 0px; position: fixed; display: block;">
    <span>

        <p style="font-size:12px">En continuant à naviguer sur ce site, vous agréez à l’utilisation de cookies en vue de
            réaliser un suivi des statistiques de visites. Si vous désirez en connaître davantage et accéder aux
            paramètres des cookies

            <a class="cp mentionsLegal" data-bs-toggle="modal" data-bs-target="#mentionsLegalsModal"
                style="display: inline-block; color: rgb(68, 68, 68);">cliquez sur ce
                lien.</a>
        </p>

        <div class="row">
            <div class="col text-center">
                <a (click)='acceptCookies()' role="button"
                    class="p-2"
                    style="color: rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                    J'ACCEPTE
                </a>
                &nbsp;
                <a role="button" (click)='configCookies()' 
                    class="p-2"
                    style="color: rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                    JE CHOISIS
                </a>
            </div>
        </div>

    </span>
</div>


<div class="picture_accueil position-relative" id="top" (click)="toFormulaire()">
    <div class="container">
        <a href="https://assurances-factures-oney.souscription.eu/">
            <img src="/../../../assets/oney/oney-lg.png"
                alt="Assurances Factures et Loyer Factures Oney" id="logo"
                width="156px">
        </a>
    </div>
</div>

<div style="position:fixed; bottom: 15%; left: 0; z-index: 200;">
    <a>
        <div class="contactbubble" (click)="callBack()">
            <img src="/../../../assets/phone.png" alt="Téléphone" />
            <p class="contacttext">
                Être rappelé
            </p>
        </div>
    </a>
    <a>
        <div class="contactbubble" (click)="contactForm()">
            <img src="/../../../assets/email-1.png" alt="Email" />
            <p class="contacttext">
                Nous contacter
            </p>
        </div>
    </a>
</div>

<div class="backToTop" (click)="toTop()">
    <i class="fas fa-chevron-up"></i>
</div>


<div>
    <div>
        <div class="title_color fs-2 lh-sm text-center  mt-4 t_family">
            <strong>Restez serein avec</strong><br>l’Assurance Factures Oney et l’Assurance Loyer Factures Oney
            <p class="line ml5"></p>
        </div>

        <div class="text-center t_family title_color lh-base p-3">
            Chaque mois vous recevez des factures d’électricité,
            de gaz ou de fioul et d’eau tout ceci en plus d’un loyer à payer.<br>
            Un coup dur est si vite arrivé, piocher dans ses économies ou vous retrouver
            en difficulté de paiement n’est vraiment pas l’idéal.<br>
            Alors pour ne plus se soucier des tracas de la vie, le mieux,
            c’est d’être bien protégé.
        </div>
    </div>
</div>


<div class="container pt-5">
    <div class="row">
        <div class="col-sm-12 col-md-6">
            <div class="container zoom-in">

                <table class="shadow p-3 d-md-none" style="font-size: 10px;" bgcolor="#ffffff">
                    <tbody>
                        <tr>
                            <td colspan="2" align="center" bgcolor="#81bc00"><strong
                                    style="color: #ffffff; font-size: 12px;">ASSURANCE
                                    FACTURES Oney</strong></td>
                        </tr>
                        <tr>
                            <td class="tc_p fw-bold ps-3"><strong>Cotisation mensuelle</strong></td>
                            <td align="center">5,50€</td>
                        </tr>
                        <tr>
                            <td class="tc_p fw-bold ps-3"><strong>Indemnités</strong></td>
                            <td align="center"><em>Jusqu'à</em><br>250€/mois<br><em>pendant 1 an</em></td>
                        </tr>
                        <tr>
                            <td colspan="2" align="center" class="tc_p fw-bold ps-3"><strong>Risques couverts</strong>
                            </td>
                        </tr>
                        <tr>
                            <td class="tc_p fw-bold ps-3"><strong>Loyer</strong></td>
                            <td style="text-align: center;"><strong style="color: #ff0000;">X </strong></td>
                        </tr>
                        <tr>
                            <td class="tc_p fw-bold ps-3"><strong>Factures eau, gaz, électricité, fioul</strong></td>
                            <td align="center"><span style="color: #7cda24;"><strong>✓</strong></span></td>
                        </tr>
                    </tbody>
                </table>

                <table class="shadow p-3 d-md-none" style="font-size: 10px;" bgcolor="#ffffff">
                    <tbody>
                        <tr>
                            <td colspan="2" align="center" bgcolor="#81bc00"><strong
                                    style="color: #ffffff; font-size: 12px;">ASSURANCE
                                    LOYER FACTURES Oney</strong></td>
                        </tr>
                        <tr>
                            <td class="tc_p fw-bold ps-3"><strong>Cotisation mensuelle</strong></td>
                            <td align="center">13,90€</td>
                        </tr>
                        <tr>
                            <td class="tc_p fw-bold ps-3"><strong>Indemnités</strong></td>
                            <td align="center"><em>Jusqu'à</em><br>1050€/mois<br><em>pendant 1 an</em></td>
                        </tr>
                        <tr>
                            <td colspan="2" align="center" class="tc_p fw-bold ps-3"><strong>Risques couverts</strong>
                            </td>
                        </tr>
                        <tr>
                            <td class="tc_p fw-bold ps-3"><strong>Loyer</strong></td>
                            <td align="center"><span style="color: #7cda24;"><strong>✓</strong></span></td>
                        </tr>
                        <tr>
                            <td class="tc_p fw-bold ps-3"><strong>Factures eau, gaz, électricité, fioul</strong></td>
                            <td align="center"><span style="color: #7cda24;"><strong>✓</strong></span></td>
                        </tr>
                    </tbody>
                </table>

                <table class="d-none d-md-block shadow mb-5 bg-white rounded">
                    <tbody>
                        <tr>
                            <td class="tc_p">&nbsp;</td>
                            <td class="green_background tc_p">
                                <p class="text_Wcolor fw-bold ">ASSURANCE FACTURES Oney</p>
                            </td>
                            <td class="green_background tc_p">
                                <p class="text_Wcolor fw-bold">ASSURANCE LOYER FACTURES Oney</p>
                            </td>
                        </tr>
                        <tr>
                            <td class="BC_white T_alignC tc_p fw-bold ps-3 fs-6">Cotisation mensuelle</td>
                            <td class="tc_p T_alignC">5,50€</td>
                            <td class="tc_p T_alignC">13,90€</td>
                        </tr>
                        <tr class="BC_white T_alignC">
                            <td class="tc_p fw-bold ps-3">Indemnités</td>
                            <td class="T_alignC tc_p">
                                <em>Jusqu’à</em><br>250€/mois<br><em>pendant 1 an</em>
                            </td>
                            <td class="T_alignC tc_p">
                                <em>Jusqu’à</em><br>1050€/mois<br><em>pendant 1 an</em>
                            </td>
                        </tr>
                        <tr class="BC_white T_alignC">
                            <td class="tc_p">&nbsp;</td>
                            <td class="tc_p fw-bold ps-3">Risques couverts</td>
                            <td class="tc_p">&nbsp;</td>
                        </tr>
                        <tr class="BC_white T_alignC">
                            <td class="tc_p fw-bold ps-3">Loyer</td>
                            <td class="tc_p fw-bold TC_red">X</td>
                            <td class="tc_p fw-bold TC_green">✓</td>
                        </tr>
                        <tr class="BC_white T_alignC">
                            <td class="tc_p fw-bold ps-3">Factures eau, gaz, électricité,
                                fioul
                            </td>
                            <td class="tc_p fw-bold TC_green">✓</td>
                            <td class="tc_p fw-bold TC_green">✓</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="col-sm-12 col-md-6">
            <div class="px_dc_in_txt">
                <div class="px_dc_in_txt_inner">
                    <h2 class="px_dc_in_txt_inner_h">À chaque situation, une solution :</h2>
                </div>
            </div>
            <div class=".px_dc_pg_txt">
                <div class="px_dc_pg_txt_inner">
                    <p class="px_dc_pg_txt_inner_p">Licenciement&nbsp;ou accident de la vie, préserver son
                        budget&nbsp;et sa
                        famille c’est possible !
                        <br><strong>L’Assurance Factures Oney</strong><b>&nbsp;assure le paiement de vos
                            factures</b>
                        d’eau,<br> d’électricité, de gaz et de fioul pour 5,50 € / mois.
                        <br><strong>Vous en voulez plus? Il y a encore mieux.</strong>
                        <br>L’Assurance Loyer Factures Oney<b> garantit, en plus des factures, votre loyer</b>
                        pour 13,90 € / mois.
                    </p>
                </div>
            </div>
            <div class="T_alignC">
                <button class="D_button FS_13 " target="_blank" data-icon="E" (click)="toFormulaire()">
                    <i class="fas fa-caret-right"></i> Je découvre</button>
            </div>
        </div>
    </div>
</div>



<div class="BG_Icon pt-5">

    <h2 class="title_stl">Les événements ouvrant droit à l’indemnisation sont :</h2>
    <p class="line ml5"></p>

    <div class="container">
        <div class="row text-center fw-bold">
            <div class="col-md-3 col-sm-6 col-xs-12">
                <img class="icon_size" src="../../../assets/deces-accidentel.png" alt="" title="deces-accidentel">
                <p class="primary-color pt-4">Le décès<br>accidentel</p>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
                <img class="icon_size" src="../../../assets/ITT.png" alt="" title="deces-accidentel">
                <p class="primary-color pt-4">L'Incapacité Totale<br>de Travail(ITT)</p>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
                <img class="icon_size" src="../../../assets/PTIA.png" alt="" title="deces-accidentel">
                <p class="primary-color pt-4">La Perte Totale et Irréversible d'autonomie(PTIA)</p>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
                <img class="icon_size" src="../../../assets/PE.png" alt="" title="deces-accidentel">
                <p class="primary-color pt-4">La Perte<br>d'Emploi (PE)</p>
            </div>
        </div>

        <div class="row mt-3">

            <div class="col-md-6 col-sm-12 p-4">
                <div class="BG_grey flex_container h_50">
                    <div class="p_text text_Wcolor"> <strong> ASSURANCE FACTURES Oney</strong></div>
                </div> <!-- .et_pb_text -->
                <div class="BG_Litegrey p_24">
                    <div class="text-center TC_black T_small m-4">Le document d’information et la notice
                        d’information Assurance Factures Oney</div>
                    <div class="text-center">
                        <a href="./assets/20210325 Notice-IPID-Assurance Factures LP.pdf" target="_blank"
                            class="D_button fw-bold" target="_blank" data-icon="E">
                            <small><i class="fas fas fa-caret-right"></i></small> Je télécharge</a>
                    </div>
                </div> <!-- .et_pb_text -->
            </div>


            <div class="col-md-6 col-sm-12 p-4">
                <div class="BG_grey flex_container h_50">
                    <div class="p_text text_Wcolor"> <strong> ASSURANCE LOYER FACTURES Oney</strong></div>
                </div> <!-- .et_pb_text -->
                <div class="BG_Litegrey p_24">
                    <div class="text-center TC_black T_small m-4">Le document d’information et la notice
                        d’information Assurance Loyer Factures Oney</div>
                    <div class="text-center">
                        <a href="./assets/20210325 Notice-IPID-Assurance-Loyer-Factures LP.pdf" target="_blank"
                            class="D_button fw-bold" target="_blank" data-icon="E">
                            <small><i class="fas fa-caret-right"></i></small> Je télécharge</a>
                    </div>
                </div> <!-- .et_pb_text -->
            </div>
        </div>
    </div>
</div>



<div class="p-5 pt-0" id="formulaire">
    <div>
        <h2 class="T_alignC font-family  icon_text_color title-p">L’offre vous intéresse ?</h2>
    </div>
    <p class="line ml5 mr_bottomplus"></p>
    <div class="container p-4 pt-0">

        <div class="row">
            <div class="col-md">
                <span class="w_100 mx_8 ">
                    <th> <label class="TC_Litegrey">Ma civilité*</label> </th>
                    <select [(ngModel)]="souscription.civilite" class="BG_Litegrey" type="text" name="Civilite"
                        id="Civilite" aria-required="true" aria-invalid="false" [class.input-error]="errno === 1">
                        <option></option>
                        <option value="M.">M.</option>
                        <option value="Mme">Mme</option>
                    </select>
                </span>
            </div>
            <div class="col-md">
                <span class="w_100 mx_8">
                    <th> <label class="TC_Litegrey">Mon prénom*</label> </th>
                    <input [(ngModel)]="souscription.prenom" class="BG_Litegrey" type="text" name="firstname" id="name"
                        required minlength="3" [class.input-error]="errno === 2">

                </span>
            </div>
            <div class="col-md">
                <span class="w_100 mx_8">
                    <th> <label class="TC_Litegrey">Mon nom*</label></th>
                    <input [(ngModel)]="souscription.nom" class="BG_Litegrey" type="text" name="lastename"
                        id="lastename" aria-required="true" aria-invalid="false" [class.input-error]="errno === 3">
                </span>
            </div>
        </div>

        <div class="row">
            <div class="col-md">
                <span class="w_100 mx_8">
                    <th><label class="TC_Litegrey pt-5">Mon adresse*</label></th>
                    <input [(ngModel)]="souscription.adresse" class="BG_Litegrey" [class.input-error]="errno === 4"
                        type="text" name="adress" id="adress" aria-required="true">
                </span>
            </div>
        </div>

        <div class="row">
            <div class="col-md">
                <span class="w_100 mx_8">
                    <th>
                        <label class="TC_Litegrey">Ma ville*</label>
                    </th>
                    <input [(ngModel)]="souscription.ville" [class.input-error]="errno === 5" type="text" name="ville"
                        class="BG_Litegrey" id="ville" aria-required="true">
                </span>
            </div>
            <div class="col-md">
                <span class="w_100 mx_8">
                    <th>
                        <label class="TC_Litegrey">Mon code postal*</label>
                    </th>
                    <input [(ngModel)]="souscription.codePostal" type="text" name="codePostal" class="BG_Litegrey"
                        id="codePostal" aria-required="true" maxlength="5" pattern="\d*"
                        [class.input-error]="errno === 6">
                </span>
            </div>
        </div>

        <div class="row">
            <div class="col-md">
                <span class="w_100 mx_8">
                    <th>
                        <label class="TC_Litegrey">Ma date de naissance*</label>
                    </th>

                    <input [class.input-error]="errno === 7" [ngModel]="souscription.dateNaissance|date:'yyyy-MM-dd'"
                        (change)="changeDate($event)" type="date" name="dateDeNaissance"
                        class="form-control BG_Litegrey w-100" id="dateDeNaissance" aria-required="true">

                </span>
            </div>
            <div class="col-md">
                <span class="w_100 mx_8">
                    <th>
                        <label class="TC_Litegrey">Mon lieu de naissance*</label>
                    </th>
                    <input [class.input-error]="errno === 8" [(ngModel)]="souscription.lieuNaissance" type="text"
                        name="lieuNaissance" class="BG_Litegrey" id="lieuNaissance" aria-required="true">
                </span>
            </div>
        </div>

        <div class="row">
            <div class="col-md">
                <span class="w_100 mx_8">
                    <th>
                        <label class="TC_Litegrey">Mon n° de téléphone*</label>
                    </th>
                    <input [class.input-error]="errno === 9" [(ngModel)]="souscription.mobile" class="BG_grey"
                        type="text" name="Numéro" class="BG_Litegrey" id="numero" aria-required="true" maxlength="10"
                        pattern="\d*">
                </span>
            </div>
            <div class="col-md">
                <span class="w_100 mx_8">
                    <th>
                        <label class="TC_Litegrey">Mon e-mail*</label>
                    </th>
                    <input [class.input-error]="errno === 10" [(ngModel)]="souscription.email" type="text" name="email"
                        class="BG_Litegrey" id="email" aria-required="true">
                </span>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12" [class.input-error]="errno === 11">
                <h5 class="TC_Litegrey m-2"><strong>Êtes-vous en situation d'invalidité ?*</strong></h5>
                <label class="checkbox">
                    <input class="apple-switch" type="radio" name="radio-invalidite" id="invalidite" [value]="true"
                        [(ngModel)]="souscription.invalidite">
                    <p class="m-3">Oui</p>
                </label>
                <br>
                <label class="checkbox">
                    <input class="apple-switch" type="radio" name="radio-invalidite" id="invalidite" [value]="false"
                        [(ngModel)]="souscription.invalidite">
                    <p class="m-3">Non</p>
                </label>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12" [class.input-error]="errno === 12">
                <h5 class="TC_Litegrey m-2"><strong>Êtes-vous locataire ?*</strong></h5>
                <label class="checkbox">
                    <input class="apple-switch" type="radio" name="radio-locataire" id="locataire" [value]="true"
                        [(ngModel)]="souscription.locataire">
                    <p class="m-3">Oui</p>
                </label>
                <br>
                <label class="checkbox">
                    <input class="apple-switch" type="radio" name="radio-locataire" id="locataire" [value]="false"
                        [(ngModel)]="souscription.locataire">
                    <p class="m-3">Non</p>
                </label>
            </div>


            <div class="col-lg-4 col-md-6 col-sm-12" [class.input-error]="errno === 13">
                <h5 class="TC_Litegrey m-2"><strong>Êtes-vous salarié.e ?*</strong></h5>
                <Label class="checkbox">
                    <input class="apple-switch" type="radio" name="radio-salarie" id="salarie" [value]="true"
                        [(ngModel)]="souscription.salarie">
                    <p class="m-3">Oui</p>
                </Label>
                <br>
                <Label class="checkbox">
                    <input class="apple-switch" type="radio" name="radio-salarie" id="salarie" [value]="false"
                        [(ngModel)]="souscription.salarie">
                    <p class="m-3">Non</p>
                </Label>
            </div>
        </div> <!-- row title -->


        <div class="row">
            <div class="col-12" [class.input-error]="errno === 14">
                <h5 class="TC_Litegrey m-2"><strong>Que voulez-vous garantir ?*</strong></h5>
                <label class="checkbox">
                    <input class="apple-switch" type="radio" name="facture" id="facture" value="8c5af5ca"
                        [(ngModel)]="souscription.product">
                    <p class="m-3">Les factures de fluide pour 5,50 €/ mois ***</p>
                </label>
                <br>
                <label class="checkbox">
                    <input class="apple-switch" type="radio" name="facture" id="facture" value="981cb9ee"
                        [(ngModel)]="souscription.product">
                    <p class="m-3">Le loyer de votre résidence principale et les factures de fluide pour 13,90€ /
                        mois ***</p>
                </label>
            </div>
        </div>

        <br />

        <p class="mention_legal_text">***En cas de décès accidentel, perte d'emploi involontaire, ITT ou PTIA.</p>
        <hr class="linexd">


        <div>
            <p class="mention_text">
                ONEY utilise votre adresse postale pour vous adresser des publicités sur ses produits.
                Si vous ne souhaitez pas recevoir de telles sollicitations, cochez la case ci-dessous :
            </p>

            <label class="checkbox">
                <input class="checkbox" type="checkbox" name="Checkrefusedemarche" id="checkrefuse" [value]="false"
                    [(ngModel)]="souscription.optimOney">
                <p class="m-1 mention_text mx_8">Je refuse que la société ONEY me propose par courrier postal des
                    produits ou services.</p>
            </label>



            <p class="mention_text_plus">
                Ces données peuvent également permettre à la société ONEY et à son partenaire SPB Family de
                vous adresser par courriel
                d’autres publicités. Pour le permettre, vous devez cocher la case ci-dessous:
            </p>


            <label class="checkbox">
                <input class="checkbox" type="checkbox" name="CheckAccept" id="CheckAccept" [value]="true"
                    [(ngModel)]="souscription.optimSpbf">
                <p class="m-1 mention_text mx_8">J’accepte que la société ONEY et son partenaire SPB Family me
                    proposent
                    par courriel de
                    nouveaux produits ou services.<br>
                    Vous pouvez vous désinscrire à tout moment à travers les liens de désinscription.**.</p>
            </label>


            <p class="mention_text">
                * Les champs suivis d'une astérisque sont obligatoires.
            </p>
        </div>


        <div class="text-center alert alert-danger" *ngIf='error'>
            {{ error }}
        </div>

        <div class="T_alignC">
            <button class="T_alignC D_buttonXL" target="_blank" data-icon="E" (click)='validateForm()'>
                Je continue <sup><small>(2)</small></sup>
            </button>
        </div>

    </div>

</div>

<div class="BG_Icon">
    <div class="container">
        <div class="T_alignC mr_bottom">
            <h2 class="T_alignC p_24 icon_text_color pt-5 Strong fonts30">Vous avez des questions ?
            </h2>
        </div>
        <p class="line ml5"></p>
        <div class="T_alignC mb-2">
            Nos conseillers sont à votre écoute pour répondre<br>
            à toutes vos interrogations et vous apporter les solutions les plus adaptées<br>
            à votre situation.
        </div>

        <div class="T_alignC">
            <button class="T_alignC D_button" target="_blank" data-icon="E" (click)="callBack()">
                <i class="fas fa-caret-right"></i><strong> Demander à être rappelé</strong></button>
            <br>
            <button class="T_alignC D_button_X" target="_blank" data-icon="E" (click)="contactForm()">
                <i class="fas fas fa-caret-right"></i> Nous contacter</button>
        </div>
    </div> <!-- .et_pb_row -->
</div>

<div class="container border_text">
    <div class="mention_legal_text">
        <p>
            ** Conformément à la réglementation, les informations demandées sont nécessaires au
            traitement de votre demande. Ces informations
            sont destinées à Oney et à son mandataire d’intermédiaire en assurance SPB Family,
            responsables du traitement, à des fins de conseil
            et de gestion de votre demande.
        </p>
        <p>
            Nous vous rappelons que vous disposez d’un droit d’accès, de rectification, d’effacement,
            d’opposition, et de portabilité des données
            vous concernant, d’un droit d’opposition et de limitation aux traitements effectués par Oney
            ou son mandataire d’intermédiaire en
            assurance SPB Family ainsi que d’un droit d’opposition à la prospection commerciale auprès
            de Oney à l’adresse suivante
            Oney – CS 60006 – 59895 Lille Cedex 9 ou encore auprès de SPB Family Courtage à l’adresse
            suivante 208 Quai de
            Paludate – 33800 BORDEAUX ou par email : serviceclient@spbfamily.eu
        </p>
        <p>
            (1) Sous réserve de respecter les conditions d’adhésion et de prise en charge indiquées dans
            les notices d’information des assurances
            présentées. Contrats d’assurance de groupe souscrits par l’Association Souscription Avenir
            Family auprès de Wakam entreprise régie
            par le Code des assurances.
        </p>
        <p>
            En validant votre adhésion en ligne à Assurance Factures Oney ou Assurance Loyer Factures,
            SPB Family Courtage vous enverra,
            sur un support durable, la Fiche d’information et de conseil, la Notice d’assurance et le
            Certificat d’adhésion. Ces documents
            précontractuels peuvent vous être transmis sur support papier dès l’envoi d’une demande à
            l’adresse serviceclient@spbfamily.eu.
        </p>
        <p>
            Vos déclarations doivent être sincères et exactes, à défaut cela pourrait entraîner une
            diminution des indemnités ou une nullité
            du contrat (article L 113-8 et L113-9 du code des assurances).
            Les données personnelles collectées dans le cadre du présent formulaire sont nécessaires à
            la proposition d’adhésion,
            à sa validation, à l’enregistrement et à la gestion de votre adhésion au contrat Assurance
            Factures Oney ou Assurance Loyer Factures
            Oney. Ces informations sont destinées exclusivement à ONEY, courtier, à SPB Family Courtage
            gestionnaire du contrat et à Wakam,
            assureur du contrat, chacun responsable de son traitement de données correspondant. Ces
            données seront utilisées pour gérer votre
            adhésion au contrat et elles seront conservées pendant la durée de votre adhésion et
            au-delà, pendant une durée n’excédant pas celle
            nécessaire au regard des finalités pour lesquelles elles sont traitées.
        </p>
        <p>
            (2) En cliquant sur “Je continue”, je poursuis mon adhésion et j’accepte de recevoir par
            email la Notice, l’IPID et la Fiche d’Information
            et de Conseil personnalisée du produit d’assurance adapté à mes besoins.
        </p>
        <p>
            SPB Family Courtage : SAS de courtage d’assurance au capital de 50.000 euros dont le siège
            social est situé 208 Quai de Paludate,
            33800 Bordeaux – www.spbfamily.eu, immatriculée au RCS de Bordeaux sous le n°821 784 840 et
            à l’ORIAS sous le n° 16004724
            (www.orias.fr). SPB Family Courtage ne détient aucune participation directe ou indirecte
            d’une compagnie d’assurance. Aucune
            entreprise d’assurance ne détient de participation directe ou indirecte dans la société SPB
            Family Courtage. Elle ne réalise pas plus
            de 33% de son chiffre d’affaires avec Oradéa Vie, CNP, IMA. Elle exerce comme courtier en
            assurance conformément aux dispositions
            de l’article L521-2, II b et travaille ou est susceptible de travailler avec les compagnies
            d’assurances suivantes : Prévoir Risques
            Divers, IMA Assurances, Auxia Assurances, CFDP Assurances, CNP Assurances SPB Family
            Courtage ne fournit pas de service de recommandation
            personnalisée. SPB Family Courtage est rémunérée sous forme de commissions.
        </p>
        <p>
            WAKAM S.A. au capital de 4 514 512 € – 562 117 085 R.C.S Paris – 120-122, rue Réaumur –
            75002 PARIS – Entreprise régie par le code des assurances,
            Numéro SIREN 562 117 085.
        </p>
        <p>
            Oney Bank – Société de courtage d’assurance – SA au capital de 51 286 585 € – Siège social :
            34 avenue de Flandre 59170 Croix – RCS Lille Métropole 546 380 197 – N° ORIAS 07 023
            261(www.orias.fr).
        </p>
        <p>
            Oney Bank, SPB Family Courtage et Wakam sont soumises au contrôle de l’Autorité de Contrôle
            Prudentiel et de Résolution – 4 Place de Budapest, CS 92459, 75436 Paris Cedex 09.
        </p>
        <p>
            <!-- Button trigger modal -->
            Copyright © SPB Family – Oney Bank 2020 – <a class="cp mentionsLegal" data-bs-toggle="modal"
                data-bs-target="#mentionsLegalsModal">Mentions légales</a>
        </p>


        <!-- Modal -->
        <div class="modal fade overflow-auto" id="mentionsLegalsModal" tabindex="-1"
            aria-labelledby="mentionsLegalsModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl overflow-auto">
                <div class="modal-content overflow-auto">
                    <div class="modal-header">
                        <h5 class="modal-title " id="mentionsLegalsModalLabel">Mention legal</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <p>En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans
                            l’économie numérique, il est précisé aux Utilisateurs du Site <a
                                href="https://assurances-factures-oney.souscription.eu/">assurances-factures-oney.souscription.eu</a>
                            l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi :
                        </p>
                        <p><strong>Propriétaire</strong> : SPB Family Courtage – 801263500 – 208 Quai de Paludate –
                            33800 Bordeaux<br>
                            <strong>Créateur</strong> : SPB Family Courtage<br>
                            <strong>Responsable publication</strong> : Mirelys REYES – mreyes@spbfamily.eu<br>
                            Le responsable publication est une personne physique ou une personne morale.<br>
                            <strong>Webmaster</strong> : Marie CHABANNE – mchabanne@spbfamily.eu<br>
                            <strong>Hébergeur</strong> : Gandi SAS RCS de Paris N° 423 093 459 – Gandi SAS 63-65
                            boulevard Masséna 75013 Paris.
                        </p>
                        <h2>Conditions générales d’utilisation du Site et des Services proposés. (CGU)</h2>
                        <p>L’utilisation du Site <a
                                href="https://assurances-factures-oney.souscription.eu/">assurances-factures-oney.souscription.eu</a>
                            implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après
                            décrites. Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées
                            à tout moment, les Utilisateurs du Site <a
                                href="https://assurances-factures-oney.souscription.eu/">assurances-factures-oney.souscription.eu</a>
                            sont donc invités à les consulter de manière régulière.</p>
                        <p>Ce Site est normalement accessible à tout moment aux Utilisateurs. Une interruption pour
                            raison de maintenance technique peut être toutefois décidée par SPB Family Courtage, qui
                            s’efforcera alors de communiquer préalablement aux Utilisateurs les dates et heures de
                            l’intervention.</p>
                        <p>Le Site <a
                                href="https://assurances-factures-oney.souscription.eu/">assurances-factures-oney.souscription.eu</a>
                            est mis à jour régulièrement par Mirelys REYES. De la même façon, les mentions légales
                            peuvent être modifiées à tout moment : elles s’imposent néanmoins à l’Utilisateur qui
                            est invité à s’y référer le plus souvent possible afin d’en prendre connaissance.</p>
                        <ol>
                            <li>Définitions</li>
                        </ol>
                        <p>Les termes listés ci-dessous utilisés dans les présentes CGU et débutant par une
                            majuscule s’entendront comme suit, indifféremment au singulier et au pluriel, selon le
                            contexte de leur emploi :</p>
                        <ul>
                            <li><strong>CGU</strong>: Désigne les présentes conditions générales d’utilisation
                                destinées à encadrer les modalités d’utilisation du Site par l’Utilisateur</li>
                            <li><strong>Contenu</strong>: Désigne l’ensemble des articles, photographies,
                                illustrations, fiches, questionnaires, fonctionnalités et plus largement tout
                                élément existant au sein du Site.</li>
                            <li><strong>Site</strong>: Désigne le site web édité par SPB Family Courtage et
                                l’ensemble des Services et Contenus qu’il met à la disposition des Utilisateurs.
                            </li>
                            <li><strong>Service </strong>: Désigne l’ensemble des Services proposés et mis à la
                                disposition des Utilisateurs au sein du Site tels que décrits dans les CGU. Les
                                Services sont fournis via le Site soit par SPB Family Courtage, soit par des
                                sociétés tierces sous leur responsabilité exclusive ce que reconnaît et accepte
                                l’Utilisateur.</li>
                            <li><strong>Utilisateur </strong>: Désigne la personne physique, domiciliée en France
                                métropolitaine, ayant accès au Site.</li>
                        </ul>
                        <ol start="2">
                            <li>Description des Services fournis.</li>
                        </ol>
                        <p>Le Site <a
                                href="https://assurances-factures-oney.souscription.eu/">assurances-factures-oney.souscription.eu</a>
                            a pour objet de fournir une information concernant l’ensemble des activités de la
                            société.</p>
                        <p>SPB Family Courtage s’efforce de fournir sur le Site <a
                                href="https://assurances-factures-oney.souscription.eu/">assurances-factures-oney.souscription.eu</a>
                            des informations aussi précises que possible. Toutefois, il ne pourra être tenue
                            responsable des omissions, des inexactitudes et des carences dans la mise à jour,
                            qu’elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces
                            informations.</p>
                        <p>Toutes les informations indiquées sur le Site <a
                                href="https://assurances-factures-oney.souscription.eu/">assurances-factures-oney.souscription.eu</a>
                            sont données à titre indicatif, et sont susceptibles d’évoluer. Par ailleurs, les
                            renseignements figurant sur le Site <a
                                href="https://assurances-factures-oney.souscription.eu/">assurances-factures-oney.souscription.eu</a>
                            ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications ayant été
                            apportées depuis leur mise en ligne.</p>
                        <ol start="3">
                            <li>Limitations contractuelles sur les données techniques.</li>
                        </ol>
                        <p>Le Site utilise la technologie JavaScript.<br>
                            Le Site Internet ne pourra être tenu responsable de dommages matériels liés à
                            l’utilisation du Site. De plus, l’Utilisateur du Site s’engage à accéder au Site en
                            utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de
                            dernière génération mis-à-jour.</p>
                        <ol start="4">
                            <li>Propriété intellectuelle et contrefaçons.</li>
                        </ol>
                        <p>SPB Family Courtage est propriétaire des droits de propriété intellectuelle ou détient
                            les droits d’usage sur tous les éléments accessibles sur le Site, notamment les textes,
                            images, graphismes, logo, icônes, sons, logiciels.</p>
                        <p>Toute reproduction, représentation, modification, publication, adaptation de tout ou
                            partie des éléments du Site, quel que soit le moyen ou le procédé utilisé, est
                            interdite, sauf autorisation écrite préalable de : SPB Family Courtage.</p>
                        <p>Toute exploitation non autorisée du Site ou de l’un quelconque des éléments qu’il
                            contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément
                            aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.
                        </p>
                        <ol start="5">
                            <li>Limitations de responsabilité.</li>
                        </ol>
                        <p>SPB Family Courtage ne pourra être tenue responsable des dommages directs et indirects
                            causés au matériel de l’Utilisateur, lors de l’accès au Site
                            protect-habitat.souscription.eu, et résultant soit de l’utilisation d’un matériel ne
                            répondant pas aux spécifications indiquées au point 3, soit de l’apparition d’un bug ou
                            d’une incompatibilité.</p>
                        <p>SPB Family Courtage ne pourra également être tenue responsable des dommages indirects
                            (tels par exemple qu’une perte de marché ou perte d’une chance) consécutifs à
                            l’utilisation du Site <a
                                href="https://assurances-factures-oney.souscription.eu/">assurances-factures-oney.souscription.eu</a>.
                        </p>
                        <p>Des espaces interactifs (possibilité de poser des questions dans l’espace contact) sont à
                            la disposition des Utilisateurs. SPB Family Courtage se réserve le droit de supprimer,
                            sans mise en demeure préalable, tout Contenu déposé dans cet espace qui contreviendrait
                            à la législation applicable en France, en particulier aux dispositions relatives à la
                            protection des données. Le cas échéant, SPB Family Courtage se réserve également la
                            possibilité de mettre en cause la responsabilité civile et/ou pénale de l’Utilisateur,
                            notamment en cas de message à caractère raciste, injurieux, diffamant, ou
                            pornographique, quel que soit le support utilisé (texte, photographie…).</p>
                        <p>L’Utilisateur est informé des contraintes et limites du réseau Internet, tout
                            particulièrement en terme de :</p>
                        <ul>
                            <li>transmission des données et d’atteintes possibles aux données ;</li>
                            <li>continuité non garantie dans l’accès au Site ou aux différents Services offerts par
                                le Site ;</li>
                            <li>contraintes techniques qui ne sont pas sous le contrôle et la responsabilité de SPB.
                            </li>
                        </ul>
                        <p>En aucun cas, SPB Family Courtage ne saurait être tenue responsable de ces risques et de
                            leurs conséquences, préjudiciables, quelle qu’en soit l’étendue, pour l’Utilisateur.</p>
                        <p>Il appartient à l’Utilisateur de prendre toutes les mesures appropriées de façon à
                            protéger ses propres données, logiciels et matériels de la contamination par des virus
                            ou autres formes d’attaques circulant éventuellement à travers le Site.</p>
                        <p>L’Utilisateur ne saurait se prévaloir d’aucune difficulté d’accès au Site pour accéder à
                            l’information ou aux Services en fraude des présentes CGU.</p>
                        <p>Des liens peuvent renvoyer vers d’autres applications, sites internet ou autres réseaux
                            sociaux ou Services fournis par des sociétés tierces à SPB Family Courtage. La
                            responsabilité de SPB Family Courtage ne saurait être engagée dans le cas où le contenu
                            desdits autres applications, sites internet ou réseaux sociaux ou Services
                            contreviendrait aux droits de tiers et à toutes dispositions légales ou réglementaires
                            en vigueur. Ces autres applications, sites internet ou autres réseaux sociaux ou
                            Services sont fournis sous la responsabilité exclusive des sociétés qui les éditent, ce
                            que reconnaît et accepte expressément l’Utilisateur.</p>
                        <p>L’Utilisateur est informé du défaut de fiabilité du réseau Internet, tout spécialement en
                            termes de sécurité relative dans la transmission des données, de continuité non garantie
                            dans l’accès au Site, de performances non garanties en termes de volume et de rapidité
                            de transmission des données et de propagation de virus.</p>
                        <p>A ce titre, SPB Family Courtage ne pourra être tenue pour responsable des dommages
                            résultant de virus quelle qu’en soit la forme, de bug(s), voire de tout programme ou
                            d’application qui serait incompatible avec l’infrastructure utilisée par l’Utilisateur,
                            ni des dommages subis par l’Utilisateur par le fait d’une panne, interruption
                            maintenance, problème technique, coupure du réseau téléphonique ou des réseaux de
                            télécommunications, surcharge, négligence ou faute de tiers ou de l’Utilisateur ou d’une
                            incompatibilité de la configuration de l’ordinateur de l’Utilisateur.</p>
                        <p>L’Utilisateur est responsable de la mise en œuvre au sein de son ordinateur d’une
                            solution et des mesures de sécurité de nature à prévenir la propagation de virus.</p>
                        <p>Il appartient à l’Utilisateur de vérifier l’adéquation des Services et Contenus proposés
                            via le Site à ses besoins. L’Utilisateur est seul responsable de toute décision de
                            quelque nature qu’elle soit, prise à partir d’une information fournie au sein des
                            Contenus ou les Services proposés via le Site.</p>
                        <ol start="6">
                            <li>Politique des données personnelles</li>
                        </ol>
                        <p><strong>Sur la gestion des données personnelles :</strong></p>
                        <p>L’Utilisateur est informé des réglementations concernant la communication marketing, la
                            loi du 21 Juin 2014 pour la confiance dans l’Economie Numérique, la Loi Informatique et
                            Liberté du 6 Janvier 1978 modifiée par la loi du 20 juin 2018 et conforme au Règlement
                            Général sur la Protection des Données personnelles (RGPD : n° 2016-679).</p>
                        <p><strong>Sur les responsables de la collecte des données personnelles :</strong></p>
                        <p>Pour les Données Personnelles collectées dans le cadre navigation sur le Site, le
                            responsable du traitement des Données Personnelles est : SPB Family Courtage. <a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu/</a>
                            est représenté par Mirelys REYES, son représentant légal.</p>
                        <p>En tant que responsable du traitement des données qu’il collecte, <a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu/</a>
                            s’engage à respecter le cadre des dispositions légales en vigueur. Il lui appartient
                            notamment au Site protect-habitat.souscription.eu d’établir les finalités de ses
                            traitements de données, de fournir à ses prospects et clients, à partir de la collecte
                            de leurs consentements, une information complète sur le traitement de leurs données
                            personnelles et de maintenir un registre des traitements conforme à la réalité.</p>
                        <p>Chaque fois que <a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu/</a>
                            traite des Données Personnelles, <a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu</a>
                            prend toutes les mesures raisonnables pour s’assurer de l’exactitude et de la pertinence
                            des Données Personnelles au regard des finalités pour lesquelles <a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu</a>
                            les traite.</p>
                        <p><strong>Sur la finalité des données collectées :</strong></p>
                        <p><a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu</a>
                            est susceptible de traiter tout ou partie des données :</p>
                        <ul>
                            <li>pour permettre la navigation sur le Site et la gestion et la traçabilité des
                                prestations et Services commandés par l’Utilisateur : données de connexion et
                                d’utilisation du Site, facturation, historique des commandes, etc.</li>
                            <li>pour prévenir et lutter contre la fraude informatique (spamming, hacking…) :
                                matériel informatique utilisé pour la navigation, l’adresse IP, le mot de passe
                                (hashé)</li>
                            <li>pour améliorer la navigation sur le Site : données de connexion et d’utilisation
                            </li>
                            <li>pour mener des enquêtes de satisfaction facultatives sur <a
                                    href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu/</a>
                                : adresse email</li>
                            <li>pour mener des campagnes de communication (sms, mail) : numéro de téléphone, adresse
                                email</li>
                            <li>pour les transmettre aux entreprise assurance pour finaliser l’adhésion au contrat
                            </li>
                        </ul>
                        <p>Au de la de la transmission des données aux entreprises d’assurance partenaires pour la
                            conclusion du contrat d’assurance <a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu/</a>
                            ne commercialise pas vos données personnelles.</p>
                        <p><strong>Sur les droits d’Utilisateurs :</strong></p>
                        <p>Conformément à la réglementation européenne en vigueur, les Utilisateurs de <a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu/</a>
                            disposent des droits suivants :</p>
                        <ul>
                            <li>droit d’accès (article 15 RGPD) et de rectification (article 16 RGPD), de mise à
                                jour, de complétude des données des Utilisateurs, droit de verrouillage ou
                                d’effacement des données des Utilisateurs à caractère personnel (article 17 du
                                RGPD), lorsqu’elles sont inexactes, incomplètes, équivoques, périmées, ou dont la
                                collecte, l’utilisation, la communication ou la conservation est interdite</li>
                            <li>droit de retirer à tout moment un consentement (article 13-2c RGPD)</li>
                            <li>droit à la limitation du traitement des données des Utilisateurs (article 18 RGPD)
                            </li>
                            <li>droit d’opposition au traitement des données des Utilisateurs (article 21 RGPD)</li>
                            <li>droit à la portabilité des données que les Utilisateurs auront fournies, lorsque ces
                                données font l’objet de traitements automatisés fondés sur leur consentement ou sur
                                un contrat (article 20 RGPD)</li>
                            <li>droit de définir le sort des données des Utilisateurs après leur mort et de choisir
                                à qui <a
                                    href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu/</a>
                                devra communiquer (ou non) ses données à un tiers qu’ils aura préalablement désigné
                            </li>
                        </ul>
                        <p>Dès que <a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu/</a>
                            a connaissance du décès d’un Utilisateur et à défaut d’instructions de sa part, <a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu/</a>
                            s’engage à détruire ses données, sauf si leur conservation s’avère nécessaire à des fins
                            probatoires ou pour répondre à une obligation légale.</p>
                        <p>Si l’Utilisateur souhaite savoir comment <a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu/</a>
                            utilise ses Données Personnelles, demander à les rectifier ou s’oppose à leur
                            traitement, l’Utilisateur peut contacter <a
                                href="https://protect-habitat.souscription.eu/">https://protect-habitat.souscription.eu</a>
                            par écrit à l’adresse suivante :</p>
                        <p>SPB Family Courtage – DPO, Mirelys REYES</p>
                        <p>208 Quai de Paludate, 33800 Bordeaux.</p>
                        <p>Dans ce cas, l’Utilisateur doit indiquer les Données Personnelles qu’il souhaiterait que
                            <a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu/</a>
                            corrige, mette à jour ou supprime, en s’identifiant précisément avec une copie d’une
                            pièce d’identité (carte d’identité ou passeport).
                        </p>
                        <p>Les demandes de suppression de Données Personnelles seront soumises aux obligations qui
                            sont imposées à <a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu/</a>
                            par la loi, notamment en matière de conservation ou d’archivage des documents. Enfin,
                            les Utilisateurs de <a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu/</a>
                            peuvent déposer une réclamation auprès des autorités de contrôle, et notamment de la
                            CNIL (<a href="https://www.cnil.fr/fr/plaintes">https://www.cnil.fr/fr/plaintes</a>).
                        </p>
                        <p><strong>Sur la non communication des données personnelles :</strong></p>
                        <p><a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu/</a>
                            s’interdit de traiter, héberger ou transférer les Informations collectées sur ses
                            Clients vers un pays situé en dehors de l’Union européenne ou reconnu comme « non
                            adéquat » par la Commission européenne sans en informer préalablement le client. Pour
                            autant, <a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu</a>
                            reste libre du choix de ses sous-traitants techniques et commerciaux à la condition
                            qu’ils présentent les garanties suffisantes au regard des exigences du Règlement Général
                            sur la Protection des Données (RGPD : n° 2016-679).</p>
                        <p><a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu/</a>
                            s’engage à prendre toutes les précautions nécessaires afin de préserver la sécurité des
                            Informations et notamment qu’elles ne soient pas communiquées à des personnes non
                            autorisées. Cependant, si un incident impactant l’intégrité ou la confidentialité des
                            Informations du Client est portée à la connaissance de <a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu/</a>,
                            celle-ci devra dans les meilleurs délais informer le Client et lui communiquer les
                            mesures de corrections prises. Par ailleurs <a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu/</a>
                            ne collecte aucune « données sensibles ».</p>
                        <p>Les Données Personnelles de l’Utilisateur peuvent être traitées par des filiales de <a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu</a>
                            et des sous-traitants (prestataires de Services), exclusivement afin de réaliser les
                            finalités de la présente politique.</p>
                        <p>Dans la limite de leurs attributions respectives et pour les finalités rappelées
                            ci-dessus, les principales personnes susceptibles d’avoir accès aux données des
                            Utilisateurs de <a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu/</a>
                            sont principalement les agents de notre service client.</p>
                        <p><strong>Sur le type de données collectées :</strong></p>
                        <p>Concernant les Utilisateurs d’un Site <a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu/</a>,
                            nous collectons les données suivantes qui sont indispensables au fonctionnement du
                            Service, et qui seront conservées pendant une période maximale de 9 mois après la fin de
                            la relation contractuelle :</p>
                        <p>age, nom, prénom, adresse, code postal, téléphone</p>
                        <p><a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu/</a>
                            collecte en outre des informations qui permettent d’améliorer l’expérience Utilisateur
                            et de proposer des conseils contextualisés :</p>
                        <p>Possibilité de rente, mode de conclusion du contrat.</p>
                        <p>Pour les Utilisateurs qui finalisent leur adhésion sur le Site, ces données sont
                            conservées pour une période maximale de 5 ans après la fin de la relation contractuelle.
                        </p>
                        <p>Pour les Utilisateurs qui ne finalisent pas leur adhésion sur le Site, mais qui peuvent
                            toutefois devenir prospect, ces données sont conservées pour une période maximale de 3
                            ans après la collecte des données.</p>
                        <p><strong> Sur la notification d’incident :</strong></p>
                        <p>Quels que soient les efforts fournis, aucune méthode de transmission sur Internet et
                            aucune méthode de stockage électronique n’est complètement sûre. Nous ne pouvons en
                            conséquence pas garantir une sécurité absolue.</p>
                        <p>Si nous prenions connaissance d’une brèche de la sécurité, nous avertirions les
                            Utilisateurs concernés afin qu’ils puissent prendre les mesures appropriées. Nos
                            procédures de notification d’incident tiennent compte de nos obligations légales,
                            qu’elles se situent au niveau national ou européen. Nous nous engageons à informer
                            pleinement nos clients de toutes les questions relevant de la sécurité de leur compte et
                            à leur fournir toutes les informations nécessaires pour les aider à respecter leurs
                            propres obligations réglementaires en matière de reporting.</p>
                        <p>Aucune information personnelle de l’Utilisateur du Site <a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu/</a>
                            n’est publiée à l’insu de l’Utilisateur, échangée, transférée, cédée ou vendue sur un
                            support quelconque à des tiers. Seule l’hypothèse du rachat de <a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu/</a>
                            et de ses droits permettrait la transmission des dites informations à l’éventuel
                            acquéreur qui serait à son tour tenu de la même obligation de conservation et de
                            modification des données vis à vis de l’Utilisateur du Site <a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu/</a>.
                        </p>
                        <p><strong>Sur la sécurité et la confidentialité des données :</strong></p>
                        <p>Pour assurer la sécurité et la confidentialité des Données Personnelles et des Données
                            Personnelles de Santé, <a
                                href="https://protect-habitat.souscription.eu/">https://protect-habitat.souscription.eu</a>
                            utilise des réseaux protégés par des dispositifs standards tels que par pare-feu, la
                            pseudonymisation, l’encryption et mot de passe.</p>
                        <p>Lors du traitement des Données Personnelles prend, <a
                                href="https://assurances-factures-oney.souscription.eu/">https://assurances-factures-oney.souscription.eu</a>
                            toutes les mesures raisonnables visant à les protéger contre toute perte, utilisation
                            détournée, accès non autorisé, divulgation, altération ou destruction.</p>
                        <ol start="7">
                            <li>Liens hypertextes et cookies.</li>
                        </ol>
                        <p>La navigation sur le Site <a
                                href="https://assurances-factures-oney.souscription.eu/">assurances-factures-oney.souscription.eu</a>
                            est susceptible de provoquer l’installation de cookie(s) sur l’ordinateur de
                            l’Utilisateur. Un cookie est un fichier de petite taille, qui ne permet pas
                            l’identification de l’Utilisateur, mais qui enregistre des informations relatives à la
                            navigation d’un ordinateur sur un site. Les données ainsi obtenues visent à faciliter la
                            navigation ultérieure sur le Site, et ont également vocation à permettre diverses
                            mesures de fréquentation.</p>
                        <p>Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains
                            Services. L’Utilisateur peut toutefois configurer son ordinateur de la manière suivante,
                            pour refuser l’installation des cookies :</p>
                        <p>Sous Internet Explorer : onglet outil (pictogramme en forme de rouage en haut a droite) /
                            options internet. Cliquez sur Confidentialité et choisissez Bloquer tous les cookies.
                            Validez sur Ok.</p>
                        <p>Sous Firefox : en haut de la fenêtre du navigateur, cliquez sur le bouton Firefox, puis
                            aller dans l’onglet Options. Cliquer sur l’onglet Vie privée.<br>
                            Paramétrez les Règles de conservation sur : utiliser les paramètres personnalisés pour
                            l’historique. Enfin décochez-la pour désactiver les cookies.</p>
                        <p>Sous Safari : Cliquez en haut à droite du navigateur sur le pictogramme de menu
                            (symbolisé par un rouage). Sélectionnez Paramètres. Cliquez sur Afficher les paramètres
                            avancés. Dans la section “Confidentialité”, cliquez sur Paramètres de contenu. Dans la
                            section “Cookies”, vous pouvez bloquer les cookies.</p>
                        <p>Sous Chrome : Cliquez en haut à droite du navigateur sur le pictogramme de menu
                            (symbolisé par trois lignes horizontales). Sélectionnez Paramètres. Cliquez sur Afficher
                            les paramètres avancés. Dans la section “Confidentialité”, cliquez sur préférences. Dans
                            l’onglet “Confidentialité”, vous pouvez bloquer les cookies.</p>
                        <p>Concernant les liens hypertextes, leur mise en place par l’Utilisateur de tous liens de
                            ce type vers tout ou partie du Site est strictement interdite.</p>
                        <ol start="8">
                            <li>Droit applicable et attribution de juridiction.</li>
                        </ol>
                        <p>Tout litige en relation avec l’utilisation du Site <a
                                href="https://assurances-factures-oney.souscription.eu/">assurances-factures-oney.souscription.eu</a>
                            est soumis au droit français. Il est fait attribution exclusive de juridiction aux
                            tribunaux compétents de Paris.</p>
                        <ol start="9">
                            <li>Les principales lois concernées.</li>
                        </ol>
                        <p>Loi n° 78-17 du 6 janvier 1978, notamment modifiée par la loi n° 2004-801 du 6 août 2004
                            relative à l’informatique, aux fichiers et aux libertés modifiée par la loi du 20 juin
                            2018 et conforme au Règlement Général sur la Protection des Données personnelles (RGPD :
                            n° 2016-679).</p>
                        <p>Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique.</p>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="m-5 p-1"></div>
</div>