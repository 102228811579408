import { Component, OnInit } from '@angular/core';
import { SouscriptionService } from 'src/app/_services/souscription.service';

@Component({
  selector: 'app-result-ko-section',
  templateUrl: './result-ko-section.component.html',
  styleUrls: ['./result-ko-section.component.scss']
})
export class ResultKoSectionComponent implements OnInit {

  souscription;

  constructor(private souscriptionService: SouscriptionService) { }

  ngOnInit(): void {
    this.souscription = this.souscriptionService.getSouscription();
  }

  tryAgain() {
    this.souscriptionService.getSignatureUrl().subscribe(
      (res) => {
        window.location.href = res.url;
      },
      () => {},
    )
  }

}
