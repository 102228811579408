import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-global-checkbox',
  templateUrl: './global-checkbox.component.html',
  styleUrls: ['./global-checkbox.component.scss']
})
export class GlobalCheckboxComponent implements OnInit {

  @Input() name: string;
  @Output() valueEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onChange(value: boolean) {   
    this.valueEmitter.emit({ 'inputName': this.name, 'inputValue': value })
  }

}
