import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
  
export class PanelComponent implements OnInit {

  @Input() items: any;

  constructor() { }

  ngOnInit() {
  }

}
