import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Souscription } from 'src/app/_models/Souscription';
import { SouscriptionService } from 'src/app/_services/souscription.service';

@Component({
  selector: 'app-souscription-echec',
  templateUrl: './souscription-echec.component.html',
  styleUrls: ['./souscription-echec.component.scss']
})
export class SouscriptionEchecComponent implements OnInit {

  souscription: Souscription;
  id: string;

  constructor(public souscriptionService: SouscriptionService, private route: ActivatedRoute, private router: Router) {
    this.id = this.route.snapshot.params.id;
  }

  ngOnInit(): void {
    this.souscriptionService.getSouscriptionByUID(this.id).subscribe(
      (souscription) => {
        this.souscription = souscription;
      },
      () => this.router.navigate(['error'])
    )
  }

}
