<div class="container">
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>

    <div class="et_pb_module et_pb_text et_pb_text_17  et_pb_text_align_left et_pb_bg_layout_light">
        <div class="et_pb_text_inner">
            <h3 style="text-align: center;"><strong>Contactez-nous</strong></h3>
            <p style="text-align: center;">Pour toutes questions concernant les garanties
                Assurance
                Factures
                Oney et
                Assurance Loyer Factures Oney, n’hésitez pas à nous envoyer un message
                ci-dessous.</p>
        </div>
    </div>
</div>

<div class="overflow-auto" style="height: 500px;">

    <div class="et_pb_module et_pb_dcgd_gravity_divi_module et_pb_dcgd_gravity_divi_module_1">
        <div class="container">
            <div class="row">
                <div class="col-sm">
                    <span class="w_100 mx_8">
                        <td> <label class="TC_Litegreen">Prénom :*</label> </td>
                        <input class="BG_Litegrey" type="text" name="firstname" id="name" aria-required="true"
                            aria-invalid="false" [(ngModel)]="contact.prenom">
                    </span>
                </div>
                <div class="col-sm">
                    <span class="w_100 mx_8">
                        <td> <label class="TC_Litegreen">Nom :*</label></td>
                        <input class="BG_Litegrey" type="text" name="lastename" id="lastename" aria-required="true"
                            aria-invalid="false" [(ngModel)]="contact.nom">
                    </span>

                </div>
            </div>

            <input class="d-none" type="text" [(ngModel)]="hp" />

            <div class="row">
                <div class="col-sm">
                    <!-- col two-->
                    <span class="w_100 mx_8">
                        <td> <label class="TC_Litegreen">E-mail:*</label> </td>
                        <input class="BG_Litegrey" type="text" name="firstname" id="name" aria-required="true"
                            aria-invalid="false" [(ngModel)]="contact.email">
                    </span>
                </div>
                <div class="col-sm">
                    <span class="w_100 mx_8">
                        <td> <label class="TC_Litegreen">Numéro de téléphone :*</label></td>
                        <input class="BG_Litegrey" type="text" name="lastename" id="lastename" aria-required="true"
                            aria-invalid="false" [(ngModel)]="contact.tel">
                    </span>

                </div>
            </div>
            <div class="row">
                <span class="w_100">
                    <td> <label class="TC_Litegreen">Message:*</label></td>
                    <textarea [(ngModel)]="contact.message" class="BG_Litegrey" name="input_5" id="input_8_5"
                        aria-required="true" aria-invalid="false" rows="5" cols="90" spellcheck="false"
                        data-gramm="false"></textarea>
                    <small
                        [ngClass]="{'text-danger': contact.message?.trim()?.length > 1000 || contact.message?.trim()?.length < 120,
                     'text-success': contact.message?.trim()?.length < 1000 || contact.message?.trim()?.length > 120}">Votre
                        message doit comporter au moins 120 caractères et 1000 caractères max. <strong>({{
                            contact.message|length }}/1000 caractères)</strong></small>
                </span>
            </div>

            <div class="row">
                <label class="checkbox">
                    <input class="apple-switch" type="radio" name="confirmRadio" id="confirm" [value]="true"
                        [(ngModel)]="contact.optim">
                    <p class="text_Toggel" for="confirm">En cliquant sur Envoyer, j'accepte
                        d'être
                        recontacté par un
                        conseiller SPB Family
                        partenaire de la société Oney.*</p>
                </label>
            </div>

            <label class="gfield_label TC_Litegreen mx_up">Champs obligatoires*</label>

            <div class="text-danger text-center" *ngIf='error && !ok'>
                {{ error }}
            </div>

            <div class="text-success text-center" *ngIf='ok'>
                <strong>Votre message a été envoyé avec succès.</strong>
                <br />
                <br />
            </div>

            <div class="bt_soft_tr">
                <button class="T_alignC D_button" target="_blank" data-icon="E" (click)="send()" *ngIf='!ok'>
                    Envoyer
                </button>
            </div>

        </div>
    </div>
    <div class="text_ml">


        <div class="text_ml">
            <p>** Conformément à la réglementation, les informations demandées sont nécessaires
                au
                traitement de
                votre demande. Ces informations sont destinées à Oney et à son mandataire
                d’intermédiaire en
                assurance SPB Family, responsable du traitement, à des fins de conseil et de
                gestion de
                votre
                demande.<br>Nous vous rappelons que vous disposez d’un droit d’accès, de
                rectification,
                d’effacement, d’opposition, et de portabilité des données vous concernant, d’un
                droit
                d’opposition
                et de limitation aux traitements effectués par Oney ou son mandataire
                d’intermédiaire en
                assurance
                SPB Family ainsi que d’un droit d’opposition à la prospection commerciale auprès
                de Oney
                à
                l’adresse
                suivante Oney – CS 60006 – 59895 Lille Cedex 9 ou encore auprès de SPB Family
                Courtage à
                l’adresse
                suivante 208 Quai de Paludate – 33800 BORDEAUX ou par email :
                serviceclient@spbfamily.eu
            </p>
            <p>(1) En cliquant sur “Envoyer”, &nbsp;j’accepte d’être recontacté par SPB Family
                pour
                qu’il puisse
                répondre à ma demande et me proposer un conseil personnalisé du produit
                d’assurance
                adapté à mes
                besoins.</p>
            <p>SPB Family Courtage : SAS de courtage d’assurance au capital de 50.000 euros dont
                le
                siège social
                est
                situé 208 Quai de Paludate, 33800 Bordeaux – www.spbfamily.eu, immatriculée au
                RCS de
                Bordeaux
                sous
                le n°821 784 840 et à l’ORIAS sous le n° 16004724 (www.orias.fr). SPB Family
                Courtage ne
                détient
                aucune participation directe ou indirecte d’une compagnie d’assurance. Aucune
                entreprise
                d’assurance
                ne détient de participation directe ou indirecte dans la société SPB Family
                Courtage.
                Elle ne
                réalise pas plus de 33% de son chiffre d’affaires avec Oradéa Vie, CNP, IMA.
                Elle exerce
                comme
                courtier en assurance conformément aux dispositions de l’article L521-2, II b et
                travaille ou
                est
                susceptible de travailler avec les compagnies d’assurances suivantes : Prévoir
                Risques
                Divers,
                IMA
                Assurances, Auxia Assurances, CFDP Assurances, CNP Assurances SPB Family
                Courtage ne
                fournit pas
                de
                service de recommandation personnalisée. SPB Family Courtage est rémunérée sous
                forme de
                commissions.</p>
            <p>WAKAM S.A. au capital de 4 514 512 € – 562 117 085 R.C.S Paris – 120-122, rue
                Réaumur –
                75002
                PARIS –
                Entreprise régie par le code des assurances, Numéro SIREN 562 117 085.</p>
            <p>Oney Bank – Société de courtage d’assurance – SA au capital de 51 286 585 € –
                Siège
                social : 34
                avenue de Flandre 59170 Croix – RCS Lille Métropole 546 380 197 – N° ORIAS 07
                023
                261(www.orias.fr).
            </p>
            <p>Oney Bank, SPB Family Courtage et Wakam sont soumises au contrôle de l’Autorité
                de
                Contrôle
                Prudentiel et de Résolution – 4 Place de Budapest, CS 92459, 75436 Paris Cedex
                09.</p>
        </div>
    </div> <!-- .et_pb_text -->
</div> <!-- .et_pb_column -->