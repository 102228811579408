import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Souscription } from 'src/app/_models/Souscription';

@Component({
  selector: 'app-quote-comeback',
  templateUrl: './quote-comeback.component.html',
  styleUrls: ['./quote-comeback.component.scss']
})
export class QuoteComebackComponent implements OnInit {


  @Input() souscription: Souscription;
  @Output() valueEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

}
