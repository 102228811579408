import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-accordion-items',
  templateUrl: './accordion-items.component.html',
  styleUrls: ['./accordion-items.component.scss']
})
export class AccordionItemsComponent implements OnInit {
@Input() itemTitle : string
@Input() itemBody : string
@Input() id : number;
  constructor() { }

  ngOnInit() {
  }

}
