import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Input } from 'src/app/_models/Input';
import { Souscription } from 'src/app/_models/Souscription';
import { SouscriptionService } from 'src/app/_services/souscription.service';

@Component({
  selector: 'app-quote-second-step',
  templateUrl: './quote-second-step.component.html',
  styleUrls: ['./quote-second-step.component.scss']
})
export class QuoteSecondStepComponent implements OnInit {

  constructor(public souscriptionService: SouscriptionService, private router: Router) { }

  errors: Error[];
  souscription: Souscription;
  suggestions: any[] = [];
  selectedIndex: number = 0;

  ngOnInit() {
    this.souscription = this.souscriptionService.getSouscription();
    this.souscriptionService.setCurrentInputs([
      new Input("address", "VOTRE ADRESSE", this.souscription?.adresse, true, "[1-9]*[,]?[a-zA-Z ]+[,]?[1-9]*[a-zA-Z ]+", "201000"),
      new Input("zipcode", null, null, true, null, "201000"),
      new Input("city", null, null, true, null, "201000"),
      new Input("lat", null, null, true, null, "201000"),
      new Input("lng", null, null, true, null, "201000"),
    ])
  }

  nextStep() {
    if (this.souscriptionService.isCurrentStepValid)
      this.souscriptionService.nextStep(1).subscribe(
        () => { this.errors = []; this.router.navigate(['/demande-devis/logement']) },
        err => { this.errors = err },
      );
  }
  suggest(address: string) {
    this.souscriptionService.searchAddress(address).subscribe(
      (res) => {
        this.suggestions = res;
        if (this.suggestions.length) {
          this.changeAddress(0);
          this.souscriptionService.setInputValue('address', address, true);
        }
      }
    )
  }

  changeAddress(index: number) {
    this.souscriptionService.setInputValue('address', this.suggestions[index].address, true);
    this.souscriptionService.setInputValue('zipcode', this.suggestions[index].zipcode, true);
    this.souscriptionService.setInputValue('city', this.suggestions[index].city, true);
    this.souscriptionService.setInputValue('lat', this.suggestions[index].lat, true);
    this.souscriptionService.setInputValue('lng', this.suggestions[index].lng, true);
    this.selectedIndex = index;
  }
}
