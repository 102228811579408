import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-choice-card',
  templateUrl: './choice-card.component.html',
  styleUrls: ['./choice-card.component.scss']
})
export class ChoiceCardComponent implements OnInit {

  @Input() extra: string;
  @Input() iconSrc: string;
  @Input() name: string;
  @Input() iconSelected: boolean = false;
  @Input() value: string;
  @Output() valueEmitter = new EventEmitter<any>();
  checked: boolean = false;

  constructor() { }


  emiteValue(value: string) {
    this.valueEmitter.emit({
      'inputName': this.name,
      'inputValue': value,
      'isValid': true
    });
  }


  ngOnInit() {
  }

}
