import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Souscription } from '../_models/Souscription';
import { catchError, map } from 'rxjs/operators';
import { Observable, Subject, throwError } from 'rxjs';
import { Input } from '../_models/Input';
import { Router } from '@angular/router';
import { Error } from '../_models/Error';
import { CacheService } from './cache.service';
import { MapMarker } from '@angular/google-maps';

@Injectable({ providedIn: 'root' })

export class SouscriptionService {

    currentSouscription: Souscription;
    currentStep: number = 0;
    currentStep$: Subject<number> = new Subject<number>();
    currentInputs: Input[] = [];
    isCurrentStepValid: boolean = false;
    // Address
    currentMarkerFound: any;
    addressFound: string;
    cityFound: string;
    zipCodeFound: string;

    constructor(private http: HttpClient, private router: Router, private cacheService: CacheService) { }

    paymentViaCB() {
        let uid = this.getSouscription().id;
        let data = new FormData();
        data.append('CardNumber', this.getInputByName('CardNumber').value);
        data.append('ExpDate', this.getInputByName('ExpDate').value);
        data.append('Crypto', this.getInputByName('Crypto').value);

        return this.http.post(`/souscription/${uid}/cb/3dsecure|souscription|null|updateonly`, data);
    }

    paymentViaBank(date: number, type: string) {
        let uid = this.getSouscription().id;
        let data = new FormData();
        data.append('iban', this.getInputByName('iban').value);
        data.append('bic', this.getInputByName('bic').value);
        data.append('date', ((date == 5) ? 5 : 12).toString());
        data.append('mode', type);

        return this.http.post(`/souscription/${uid}/bank/iban|souscription|null|updateonly`, data);
    }

    getSouscription(id?: string): Souscription {
        if (!this.currentSouscription) {
            let souscriptionViaCache = this.cacheService.get('souscription')
            if (souscriptionViaCache) {
                this.currentSouscription = souscriptionViaCache;
                this.updateCurrentStep(this.currentSouscription.step);
            } else
                this.getSouscriptionByUID(id);
        }
        return this.currentSouscription;
    }

    getStep(): Observable<any> {
        let uid = this.getSouscription().id;
        return this.http.get(`/souscription/${uid}/step|null|null|noloader`);
    }

    getSignatureUrl(): Observable<any> {
        let uid = this.getSouscription().id;
        return this.http.get(`/souscription/${uid}/bank/signatureurl`);
    }

    getDocuments(): Observable<any> {
        let uid = this.getSouscription().id;
        return this.http.get(`/souscription/${uid}/documents`);
    }

    setDocumentsReceived() {
        let uid = (this.getSouscription()) ? this.getSouscription().id : null;
        return this.http.get(`/souscription/${uid}/documents/read|souscription|null|updateonly`);
    }

    stepBack() {
        if (this.currentStep > 0 && this.currentStep <= 7) {
            this.currentStep--;
            this.currentStep$.next(this.currentStep);
        }
    }

    updateCurrentStep(step: number) {
        this.currentStep = step;
        this.currentStep$.next(this.currentStep);
    }

    getSouscriptionByUID(uid: string): Observable<Souscription> {
        return this.http.get<Souscription>(`/souscription/${uid}|souscription`)
            .pipe(
                map((souscription) => this.currentSouscription = souscription)
            )
    }

    postSouscription(data: FormData): Observable<any> {
        //return this.http.post<Souscription>(`/souscription|souscription`, data)
        return this.http.post<Souscription>(`/souscription`, data)
            .pipe(
                map((souscription) => this.currentSouscription = souscription)
            );
    }

    setCurrentInputs(inputs: Input[]) {
        this.currentInputs = inputs;
    }

    getInputByName(name: string): Input {
        return this.currentInputs.find((input) => {
            return (input.name == name)
        });
    }

    getInputByCode(code: string): Input {
        return this.currentInputs.find((input) => {
            return (input.codeError == code)
        });
    }

    setInputValue(inputName, inputValue, isValid) {
        let input = this.getInputByName(inputName);
        if (input) {
            input.value = inputValue;
            input.isValid = isValid;
        }
        this.setCurrentStepValid();
    }

    searchAddress(address: string) {
        this.setInputValue('address', address, true);

        return this.http.get<any>(`https://api-adresse.data.gouv.fr/search/?q=${address.replace(' ', '+')}&limit=3`)
            .pipe(
                map(
                    (res) => {
                        // Top element
                        this.addressFound = res.features[0].properties.name;
                        this.cityFound = res.features[0].properties.city;
                        this.setInputValue('city', this.cityFound, true);
                        this.zipCodeFound = res.features[0].properties.postcode;
                        this.setInputValue('zipcode', this.zipCodeFound, true);
                        this.currentMarkerFound = {
                            lat: res.features[0].geometry.coordinates[1],
                            lng: res.features[0].geometry.coordinates[0]
                        }

                        let resFormatted = [];

                        res.features.forEach((el) => {
                            resFormatted.push({
                                'address': el.properties.name,
                                'zipcode': el.properties.postcode,
                                'city': el.properties.city,
                                'lat': el.geometry.coordinates[1],
                                'lng': el.geometry.coordinates[0],
                            })
                        });

                        return resFormatted;
                    }))
    }

    setCurrentStepValid() {
        this.isCurrentStepValid = this.currentInputs.find(input => {
            return !input.isValid;
        }) === undefined;
    }

    nextStep(step?: number) {
        // Current step
        this.currentStep = step;
        this.currentStep$.next(step);

        // Data Binding
        let bindData = new FormData();
        if (this.currentInputs) {
            this.currentInputs.forEach(input => {
                bindData.append(input.name, input.value);
            });
        }
        // UID
        if (this.currentSouscription)
            bindData.append('id', this.currentSouscription.id);
        // STEP
        if (step >= 0)
            bindData.append('step', step.toString());

        // Data Sending & Retrieve response
        return this.postSouscription(bindData).pipe(
            catchError(
                (err: HttpErrorResponse) => {
                    let errors: Array<Error> = (Array.isArray(err.error)) ? err.error : [err.error];

                    errors.forEach(e => {
                        let input = this.getInputByCode(e.code);
                        if (input) this.setInputValue(input.name, input.value, false);
                    });

                    return (err.status >= 500)
                        ? throwError([{ 'code': 'global', 'message': 'Erreur lors de la connexion au serveur. Veuillez réessayer plus tard.' }])
                        : throwError(errors);
                }
            )
        );
    }

}