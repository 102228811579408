import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-global-button-invert',
  templateUrl: './global-button-invert.component.html',
  styleUrls: ['./global-button-invert.component.scss']
})
export class GlobalButtonInvertComponent implements OnInit {
  
  @Input() link: string;
  @Input() disabled: boolean;
  @Input() class: string;

  constructor() { }

  ngOnInit() {
  }

  navigate() {
    if (this.link)
      window.location.href = this.link;
  }

}
