export class Contact {
    nom: string;
    prenom: string;
    email: string;
    tel: string;
    message: string;
    optim: boolean;
    date: string;
    utm_source: string;
    utm_campaign: string;
    utm_medium: string;
    site_id: string;

    constructor() {
        this.nom = "";
        this.prenom = "";
        this.email = "";
        this.tel = "";
        this.message = "";
        this.optim = false;
        this.date = (new Date().getTime() / 1000).toString();
        this.utm_source = "";
        this.utm_campaign = "";
        this.utm_medium = "";
        this.site_id = "";
    }
}