import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { SouscriptionService } from 'src/app/_services/souscription.service';


@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss']
})
export class InputFieldComponent implements OnInit {

  @Input() minInputLength: number = null;
  @Input() placeholder: string;
  @Input() icon: string;
  @Input() heightIcon: string = 'auto';
  @Input() widthIcon: string = 'auto';
  @Input() required: boolean;
  @Input() pattern: string;
  @Input() name: string;
  @Input() value: string;
  @Input() isDate: boolean = false;
  @Output() valueEmitter = new EventEmitter<any>();

  public mask = {
    guide: true,
    showMask: true,
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
  };

  emiteValue(value: string, minLength?: number) {
    let dateValid = false;
    if (this.isDate) {
      let dateSplit = value.split('/');
      if (dateSplit.length == 3
        && (parseInt(dateSplit[0]) > 0 && parseInt(dateSplit[0]) <= 31)
        && (parseInt(dateSplit[1]) > 0 && parseInt(dateSplit[1]) <= 12)
        && (parseInt(dateSplit[2]) > ((new Date()).getFullYear() - 100) && parseInt(dateSplit[2]) <= ((new Date()).getFullYear() - 1))
      ) {
        dateValid = true;
      }
    }
    

    if (!minLength || value?.length > minLength)
      this.valueEmitter.emit({
        'inputName': this.name,
        'inputValue': value,
        'isValid': !(
          (this.required && value.trim() == '')
          ||
          (this.pattern && value.trim().match(this.pattern)?.length != 0 && !(value.trim().match(this.pattern)?.pop() === value.trim()))
          ||
          (this.isDate && !dateValid)
        )
      });
  }

  constructor(public souscriptionService: SouscriptionService) {

  }

  ngOnInit() {
    if (this.value)
      this.emiteValue(this.value);
  }

  inputCursorPos(input) {
    if (input.value == '__/__/____')
      input.setSelectionRange(0, 0);
  }
}
