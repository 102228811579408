import { Component, OnInit, Input, AfterViewChecked, OnChanges, ViewEncapsulation } from '@angular/core';
import { fadeInAnimation } from '../../_animations/fade';
import { MessengerService } from '../../_services/messenger.service'
import { ActivatedRoute, Router } from '@angular/router';
import { Partner } from 'src/app/_models/Partner';
import { Step } from 'src/app/_models/Step';
import { Field } from 'src/app/_models/Field';
import { Form } from 'src/app/_models/Form';
import { EmbedService } from 'src/app/_services/embed.service';
import { Message } from 'src/app/_models/Message';
import { TranscriptService } from 'src/app/_services/transcript.service';

@Component({
  selector: 'app-messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['./messenger.component.scss'],
  animations: [fadeInAnimation],
  host: { '[@fadeInAnimation]': '' },
  encapsulation: ViewEncapsulation.None,
})
export class MessengerComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private messengerService: MessengerService,
    private transcriptService: TranscriptService,
    private router: Router,
    private embedService: EmbedService) { }

  form: Form;
  step: Step;
  fields: Field[];
  messages: Message[] = [];

  // Partner
  id: string; // Partner's ID

  // Data
  product: string;
  firstname: string;
  lastname: string;
  email: string;
  birthdate: string;
  hometown: string;
  mobile: string;
  address: string;
  zipcode: string;
  city: string;
  uid: string;

  error: string;

  backTo(step:any) { }
  
  ngOnInit() {
    // No Partner provided
    if (!(this.id = this.route.snapshot.queryParamMap.get('id')))
      this.router.navigate(['error'])

    // No IFRAME detected
    if (top.location === self.location)
      this.router.navigate(['error'])

    // Waiting for message of postMessage() from the intgrated iframe script
    addEventListener("message", (e) => {
      // Ignore WebPack's messages and empty messages
      if (!e.data || e.data === '' || e.data.type === 'webpackOk')
        return;

      // e.origin : Parent's Host
      // e.data : Form's Token
      this.embedService.getForm(this.id, e.data, e.origin).subscribe(
        form => {
          this.form = form;
          this.messages = form.defaultStep.messages;
          this.messages.forEach((message, index) => {
            this.messages[index].value = this.transcriptService.transform(message.value)
          })
          this.step = form.defaultStep;
          this.fields = form.defaultStep.fields;
        },
        // Verification failed
        () => this.router.navigate(['error'])
      )
    }, false);
  }

  next(fields?: any) {
    let data = new FormData();

    // Retrieve data
    if (fields) {
      fields = JSON.parse(fields);
      Object.keys(fields).forEach(key => data.append(key, fields[key]));
    } else {
      fields = {
        'prospect': {
          
        }
      };
    }

    // Send data
    this.messengerService.reply(this.step.id, false, data).subscribe(
      (step) => {
        this.step = step;
        this.fields = step.fields;
        this.messages = this.messages.concat(step.messages
          .sort((firstMsg, secondMsg) => {
            return firstMsg.weigth - secondMsg.weigth
          }))
      })
  }

}