import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SouscriptionService } from 'src/app/_services/souscription.service';

@Component({
  selector: 'app-recapulatif-section',
  templateUrl: './recapulatif-section.component.html',
  styleUrls: ['./recapulatif-section.component.scss']
})
export class RecapulatifSectionComponent implements OnInit {

  constructor() {
   }

  ngOnInit() {
    localStorage.removeItem('data');
  }

}
