<div class="gradient-background mb-5">

    <div class="button-negative">
        <app-button-g link="/demande-devis/identite">OBTENIR UN DEVIS</app-button-g>
    </div>

    <div class="container" style="max-width: 800px;">
        <h2 class="primary-title text-center">Les plus des garanties</h2>

        <div class="container pl-3 px-0 text-center-xs">


            <div class="row pl-2">

                <div class="col-sm-12 col-md-6 mt-5">
                    <div class="d-inline-block mx-4">
                        <svg id="Composant_2_1" data-name="Composant 2 – 1" xmlns="http://www.w3.org/2000/svg"
                            width="55" height="55" viewBox="0 0 55 55">
                            <circle id="Ellipse_1" data-name="Ellipse 1" cx="27.5" cy="27.5" r="27.5" fill="#17ab88" />
                            <rect id="Rectangle_19" data-name="Rectangle 19" width="10" height="39" rx="5"
                                transform="translate(22.5 8)" fill="#fff" />
                            <rect id="Rectangle_20" data-name="Rectangle 20" width="10" height="39" rx="5"
                                transform="translate(47 22.5) rotate(90)" fill="#fff" />
                        </svg>
                    </div>
                    <div class="d-inline-block align-middle">
                        <span class="titre-icon">Une cotisation fixe</span> <br>
                        <span class="text-icon">qui n'évoluera pas avec votre âge</span>
                    </div>
                </div>

                <div class="col-sm-12 col-md-6 mt-5">
                    <div class="d-inline-block mx-4">
                        <svg id="Composant_2_1" data-name="Composant 2 – 1" xmlns="http://www.w3.org/2000/svg"
                            width="55" height="55" viewBox="0 0 55 55">
                            <circle id="Ellipse_1" data-name="Ellipse 1" cx="27.5" cy="27.5" r="27.5" fill="#17ab88" />
                            <rect id="Rectangle_19" data-name="Rectangle 19" width="10" height="39" rx="5"
                                transform="translate(22.5 8)" fill="#fff" />
                            <rect id="Rectangle_20" data-name="Rectangle 20" width="10" height="39" rx="5"
                                transform="translate(47 22.5) rotate(90)" fill="#fff" />
                        </svg>
                    </div>
                    <div class="d-inline-block align-middle">
                        <span class="titre-icon">Une couverture adaptée</span> <br>
                        <span class="text-icon">à votre situation et à vos besoins</span>
                    </div>

                </div>

            </div>

            <div class="row">
                <div class="col-sm-12 col-md-6 mt-5">
                    <div class="d-inline-block mx-4">
                        <svg id="Composant_2_1" data-name="Composant 2 – 1" xmlns="http://www.w3.org/2000/svg"
                            width="55" height="55" viewBox="0 0 55 55">
                            <circle id="Ellipse_1" data-name="Ellipse 1" cx="27.5" cy="27.5" r="27.5" fill="#17ab88" />
                            <rect id="Rectangle_19" data-name="Rectangle 19" width="10" height="39" rx="5"
                                transform="translate(22.5 8)" fill="#fff" />
                            <rect id="Rectangle_20" data-name="Rectangle 20" width="10" height="39" rx="5"
                                transform="translate(47 22.5) rotate(90)" fill="#fff" />
                        </svg>
                    </div>
                    <div class="d-inline-block align-middle" style="min-width: 210px;">
                        <span class="titre-icon">Une adhésion simple</span> <br>
                        <span class="text-icon">quelques clics suffisent</span>
                    </div>
                </div>

                <div class="col-sm-12 col-md-6 mt-5">
                    <div class="d-inline-block mx-4">
                        <svg id="Composant_2_1" data-name="Composant 2 – 1" xmlns="http://www.w3.org/2000/svg"
                            width="55" height="55" viewBox="0 0 55 55">
                            <circle id="Ellipse_1" data-name="Ellipse 1" cx="27.5" cy="27.5" r="27.5" fill="#17ab88" />
                            <rect id="Rectangle_19" data-name="Rectangle 19" width="10" height="39" rx="5"
                                transform="translate(22.5 8)" fill="#fff" />
                            <rect id="Rectangle_20" data-name="Rectangle 20" width="10" height="39" rx="5"
                                transform="translate(47 22.5) rotate(90)" fill="#fff" />
                        </svg>
                    </div>
                    <div class="d-inline-block align-middle">
                        <span class="titre-icon">Pas de délais de carence</span> <br>
                        <span class="text-icon font-size-xs">pour le décès accidentel, l'ITT et la PTIA</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row text-icon text-center my-5 mx-3">
            Retrouvez toutes les informations sur les contrats Protect Factures et Protect Habitat dans les documents et
            notices d'information ci-dessous :
        </div>

        <div class="row text-center">
            <div class="col">
                &nbsp;
                <a href="/Notice-Ipid-Protect-Factures.pdf" target="_blank">
                    <img src="/assets/spbfamily/Logo-Protect-Factures-en-blanc.gif" alt="Protect Factures" width="160px"
                        class="button px-4 pt-2 pb-1 mt-2">
                </a>
                &nbsp;

                <a href="/Notice-Ipid-Protect-Habitat.pdf" target="_blank">
                    <img src="/assets/spbfamily/Logo-Protect-Habitat-en-blanc.gif" alt="Protect Habitat" width="160px"
                        class="button px-4 pt-2 pb-1 mt-2">
                </a>
            </div>
        </div>
    </div>

</div>