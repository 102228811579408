import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Souscription } from 'src/app/_models/Souscription';
import { SouscriptionService } from 'src/app/_services/souscription.service';

@Component({
  selector: 'app-souscription-final-step',
  templateUrl: './souscription-final-step.component.html',
  styleUrls: ['./souscription-final-step.component.scss']
})
export class SouscriptionFinalStepComponent implements OnInit {

  souscription: Souscription;
  id: string;
  list: any[];

  constructor(public souscriptionService: SouscriptionService, private route: ActivatedRoute, private router: Router) {
    this.id = this.route.snapshot.params.id;
  }

  ngOnInit() {
    localStorage.removeItem('data');
    this.souscriptionService.getSouscriptionByUID(this.id).subscribe(
      (souscription) => {
        this.souscription = souscription;
        let monthInt = ((new Date()).getMonth()) + 1;
        let month = (monthInt < 10) ? '0' + monthInt : monthInt;
        let day = ((new Date()).getDate() < 10) ? '0' + (new Date()).getDate() : (new Date()).getDate();
        let date = day + '/' + month + '/' + (new Date()).getFullYear();
        let method = (this.souscription.method == 'CB') ? 'carte' : 'compte';
        let debit = (this.souscription.debit) ? this.souscription.debit : (new Date()).getDate();
        let montant = (this.souscription.prelevement == 'mensuel')
          ? Number.parseFloat(this.souscription.tarifMensuel).toFixed(2)
          : Number.parseFloat(this.souscription.tarifAnnuel).toFixed(2);
        let text = 'Vous avez opté pour <strong>le prélèvement ' + this.souscription.prelevement
          + '</strong> sur votre <strong>' + method + ' bancaire</strong>.';
        text = (this.souscription.prelevement == 'annuel') ? text + ' Celui-ci sera effectué chaque année d\'un montant de <strong>' + montant + ' €</strong>.'
          : text + ' Celui-ci sera effectué <strong>le ' + debit + ' de chaque mois</strong> d\'un montant de <strong>' + montant + ' €</strong>.';
        text = (this.souscription.method == 'CB') ? text
          : text + '<br/><br/><strong>Compte de prélèvement:</strong><br>Titulaire : '
          + this.souscription.nom + ' ' + this.souscription.prenom + '<br>IBAN : ' + this.souscription.iban;
        this.list = [
          {
            'icon': '/assets/spbfamily/Composant2259@2x.png',
            'text': 'Vous avez choisi l\'assurance <strong>' + this.souscription.nameProduct + '</strong>.',
            'parameters': []
          },
          {
            'icon': '/assets/spbfamily/Composant2259@2x.png',
            'text': text,
            'parameters': []
          },
          {
            'icon': '/assets/spbfamily/Composant2259@2x.png',
            'text': 'Votre contrat prendra effet le <strong>' + date + '</strong> conformément a votre souhait de démarrer les garanties pendant le délai de renonciation du 14 jours tel que prévu dans la Notice d\'Information.',
            'parameters': []
          }
        ]
      },
      () => this.router.navigate(['error'])
    );
  }
}
