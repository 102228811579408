import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Input } from 'src/app/_models/Input';
import { Error } from 'src/app/_models/Error';
import { Souscription } from 'src/app/_models/Souscription';
import { SouscriptionService } from 'src/app/_services/souscription.service';

@Component({
  selector: 'app-quote-fourth-step',
  templateUrl: './quote-fourth-step.component.html',
  styleUrls: ['./quote-fourth-step.component.scss']
})
export class QuoteFourthStepComponent implements OnInit {

  souscription: Souscription;
  selectedInputValue: number;
  errors: Error[] = [];

  constructor(public souscriptionService: SouscriptionService,
    private router: Router) { }

  ngOnInit() {
    this.souscription = this.souscriptionService.getSouscription();
   
    this.souscriptionService.setCurrentInputs([
      new Input("salarie", null,  (this.souscription.salarie === true) ? '1' : ((this.souscription.salarie === false) ? '2': null), true, null, "401000"),
    ])

    this.souscriptionService.isCurrentStepValid = (this.souscription.salarie !== null); 
  }

  setInputValue(value: any) {
    this.selectedInputValue = Number.parseInt(value);
    this.souscriptionService.setInputValue('salarie', value, true);
    this.souscriptionService.isCurrentStepValid = true;
  }

  nextStep() {
    if (this.souscriptionService.isCurrentStepValid)
      this.souscriptionService.nextStep(3).subscribe(
        () => { this.errors = []; this.router.navigate(['/demande-devis/naissance']); },
        err => { this.errors = err },
      );
    else
      this.errors.push(new Error('global', 'Merci de faire un choix'));
  }

}
