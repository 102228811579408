<div class="box-title" [ngClass]="{'extra-box': extra}">{{extra}}</div>
<div class="box-content text-center">
    <img src="{{iconSrc}}" height="40px">

    <ng-content></ng-content>

    <img src="/assets/spbfamily/Composant2259@2x.png" alt="Choisi" height="30px"
        [ngClass]="{'d-inline-block': checked, 'd-none': !iconSelected}">

    <div class="d-table w-100 text-center" style="table-layout: fixed">
        <label>
            <span class="button link" [ngClass]="{'checked': iconSelected}" style="max-width: max-content;">
                <input type="radio" [name]="name" (change)="emiteValue(value)" [value]="value" />
                Choisir
            </span>
        </label>
    </div>
</div>