<div class="top-bar" [ngClass]="{'d-none': isModalOpened}">
    <div class="left-item">

        <div class="button" (click)="back()" *ngIf='returnBtnOption != "home"'>
            <svg xmlns="http://www.w3.org/2000/svg" width="21.333" height="15" viewBox="0 0 21.333 15">
                <path id="right-arrow"
                    d="M14.365,4.9A.756.756,0,1,0,13.29,5.964l5.451,5.451H.753A.749.749,0,0,0,0,12.169a.757.757,0,0,0,.753.763H18.742L13.29,18.373a.772.772,0,0,0,0,1.075.753.753,0,0,0,1.075,0l6.742-6.742a.74.74,0,0,0,0-1.064Z"
                    transform="translate(21.333 19.674) rotate(180)" fill="#1ead8b" />
            </svg>
            <span class="d-none d-lg-inline-block text"> &nbsp; Retour</span>
        </div>

        <div class="button" (click)="returnBtnEmitter.emit(true)" *ngIf='returnBtnOption == "home"'>
            <svg xmlns="http://www.w3.org/2000/svg" width="21.333" height="15" viewBox="0 0 21.333 15">
                <path id="right-arrow"
                    d="M14.365,4.9A.756.756,0,1,0,13.29,5.964l5.451,5.451H.753A.749.749,0,0,0,0,12.169a.757.757,0,0,0,.753.763H18.742L13.29,18.373a.772.772,0,0,0,0,1.075.753.753,0,0,0,1.075,0l6.742-6.742a.74.74,0,0,0,0-1.064Z"
                    transform="translate(21.333 19.674) rotate(180)" fill="#1ead8b" />
            </svg>
            <span class="d-none d-lg-inline-block text"> &nbsp; Revenir à l'accueil</span>
        </div>

    </div>

    <div class="center-item button">
        <a routerLink="/" title="Accueil | SPB Family Protect Habitat & Facture"><img
                src="/assets/spbfamily/logo-PROTECT-HABITAT-FACTURES-GLOBAL@2x.png"
                alt="SPB Family - Portect Habitat & Protect Factures"></a>
    </div>

    <div class="right-item">
        <div class="button" (click)='openHelpModal()'>
            <span class="text d-none d-lg-inline-block">
                Besoin d'aide &nbsp;</span>
            <img class="help-icon" src="/assets/spbfamily/help.svg" alt="Besoin d'aide">
        </div>
    </div>
</div>
<ng-container *ngIf='!isModalOpened && isShowProgress'>
    <app-progress-bar [progress]="progress"></app-progress-bar>
</ng-container>