<div class="title" *ngIf='step < 4'>
  <p>Merci de votre intérêt pour</p>
  <p class="bold">l'{{ souscription?.nameProduct }}</p>
</div>


<div class="container d-none d-sm-none d-md-block">
  <div class="title" *ngIf='step < 4'>
    <p class="bold">
      Encore quelques minutes et vous compterez parmi nos adhérents !
    </p>
  </div>

  <div class="md-stepper-horizontal orange">
    <div class="md-step" [ngClass]="{ 'active': step >= 1, 'finish': step >= 2 }">
      <div class="md-step-circle"><span>1</span></div>
      <div class="md-step-title">Validation  des vos coordonnées</div>
      <div class="md-step-bar-left"></div>
      <div class="md-step-bar-right"></div>
    </div>
    <div class="md-step" [ngClass]="{ 'active': step >= 2, 'finish': step >= 3 }">
      <div class="md-step-circle"><span>2</span></div>
      <div class="md-step-title">La documentation  précontractuelle</div>
      <div class="md-step-optional"></div>
      <div class="md-step-bar-left"></div>
      <div class="md-step-bar-right"></div>
    </div>
    <div class="md-step" [ngClass]="{ 'active': step >= 3, 'finish': step >= 4 }">
      <div class="md-step-circle"><span>3</span></div>
      <div class="md-step-title">
        Choix et paiement 
        de vos cotisations
      </div>
      <div class="md-step-bar-left"></div>
      <div class="md-step-bar-right"></div>
    </div>
    <div class="md-step" [ngClass]="{ 'active': step >= 4 }">
      <div class="md-step-circle"><span>4</span></div>
      <div class="md-step-title">
        Récapitulatif
      </div>
      <div class="md-step-bar-left"></div>
      <div class="md-step-bar-right"></div>
    </div>
  </div>
</div>
