<div class="container">
  <div class="icon"><img src="./assets/doc.svg" width="25px" /></div>

  <h1 class="d-none d-md-block">
    Je prends connaissance et garde préciseusement mes documents !
  </h1>

  <div style="position: relative; min-height: 500px">
    <ng-container *ngIf="url">
      <div class="d-block d-md-none">
        <p class="text-center pt-5">
          Votre navigateur ne prend pas en charge l'affichage des PDF.
          <br />
          <br />
          <button (click)="downloadBlob()">Télécharger votre document</button>
        </p>
      </div>

      <div class="d-none d-md-block">
      <ng-container *ngIf="isBrowserNotCompatible">
        <p class="text-center pt-5">
          Votre navigateur ne prend pas en charge l'affichage des PDF.
          <br />
          <br />
          <button (click)="downloadBlob()">Télécharger votre document</button>
        </p>
      </ng-container>

      <ng-container *ngIf="!isBrowserNotCompatible">
        <object [data]="url" type="application/pdf" width="100%" height="100%">
          <p class="text-center pt-5">
            Votre navigateur ne prend pas en charge l'affichage des PDF.
            <br />
            <br />
            <button (click)="downloadBlob()">Télécharger votre document</button>
          </p>
        </object>
      </ng-container>
    </div>

    </ng-container>

    <ng-container *ngIf="!url">
      <div class="loadingio-spinner-rolling-l2dtm1pmlhq">
        <div class="ldio-m5m6bybz1ml">
          <div></div>
        </div>
      </div>
    </ng-container>
  </div>

  <label
    ><input
      type="checkbox"
      [checked]="isReadDocs"
      (change)="isReadDocs = isReadDocs === null ? false : !isReadDocs"
    />
    Je confirme avoir bien reçu par mail ou avoir téléchargé et/ou imprimé, et
    avoir bien pris connaissance de ma Fiche d'information et de Conseil
    personnalisée, de la Notice d'information et du Document d'information de
    l'assurance choisie. *</label
  >

  <br />
  <span class="text-danger">
    {{ error }}
  </span>

  <br />
  <br />
  * : Champ obligatoire
</div>

<div class="button-section row">
  <button class="col-sm-12 col-md-3 secondary" routerLink="../validation">
    <i class="fas fa-caret-left"></i>&nbsp; Retour
  </button>
  <button class="col-sm-12 col-md-3 primary" (click)="next()">
    Je continue &nbsp; <i class="fas fa-caret-right"></i>
  </button>
</div>
