import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Error } from 'src/app/_models/Error';
import { Input } from 'src/app/_models/Input';
import { Souscription } from 'src/app/_models/Souscription';
import { SouscriptionService } from 'src/app/_services/souscription.service';

@Component({
  selector: 'app-quote-seventh-step',
  templateUrl: './quote-seventh-step.component.html',
  styleUrls: ['./quote-seventh-step.component.scss']
})
export class QuoteSeventhStepComponent implements OnInit {

  souscription: Souscription;
  optimIPIDSelected: boolean;
  optimSubscribedSelected: boolean;
  errors: Error[] = [];

  constructor(public souscriptionService: SouscriptionService,
    private router: Router) { }

  ngOnInit() {
    this.souscription = this.souscriptionService.getSouscription();
    this.souscriptionService.setCurrentInputs([
      new Input("email", "Adresse courriel", this.souscription.email, true, "[a-zA-Z0-9.-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]+", "701000", null, "/assets/spbfamily/email.svg"),
      new Input("mobile", "Téléphone portable", this.souscription.mobile, true, "0[1-9]\\d{8}", "702000", null, "/assets/spbfamily/mobile.svg"),
    ])
  }

  checked(name, value) {
    if (name == 'optim_ipid')
      this.optimIPIDSelected = value;
    else
      this.optimSubscribedSelected = value;
    
      if(this.optimIPIDSelected && this.optimSubscribedSelected)
      this.errors = [];
  }

  nextStep() {
    if (this.souscriptionService.isCurrentStepValid)
      if (this.optimIPIDSelected && this.optimSubscribedSelected) {
        this.errors = [];
        this.souscriptionService.nextStep(6).subscribe(
          () => { this.errors = []; this.router.navigate(['/demande-devis/resultats']); },
          err => { this.errors = err },
        );
      }
      else
        this.errors = [new Error('global', "Veuillez accepter de recevoir nos emails, car sans votre autorisation nous ne pourrons pas vous envoyer votre devis. Vous pourrez vous désinscrire quand vous le souhaiterez.")];
  }

}
