<ul class="nav nav-tabs" id="panel" role="tablist" *ngIf='items?.length > 1'>
    <li class="nav-item" role="presentation" *ngFor='let item of items; let first = first'>
        <button class="nav-link" [ngClass]="{'active': first}"  id="{{item.id}}-tab" type="button" aria-selected="true" data-bs-toggle="tab"
            attr.data-bs-target="#{{item.id}}" type="button" role="tab" attr.aria-controls="{{item.id}}"
            aria-selected="true">
            <img [src]="item.iconSelected" width="20px"> &nbsp; 
            {{item.title|uppercase}}
        </button>
    </li>
</ul>

<div class="tab-content" id="panelContent">
    <div *ngFor='let item of items; let first = first' class="tab-pane fade show" id="{{item.id}}" role="tabpanel" [ngClass]="{'active': first}">
        <app-file-viewer [source]="item.file"></app-file-viewer>
    </div>
</div>