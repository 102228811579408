import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonials-header',
  templateUrl: './testimonials-header.component.html',
  styleUrls: ['./testimonials-header.component.scss']
})
export class TestimonialsHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
