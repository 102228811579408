<div class="fixed-wrapper">
    <div class="position-relative" *ngIf="showCookies">

        <ng-container *ngIf='!showMore'>
            <div class="cookies-wrapper">
                <div class="cookie-content">

                    <div class="text">
                        <p class="title primary-color">Coucou c'est nous...<br /><span class="bigger">Les Cookies
                                !</span>
                        </p>
                        <p>
                            On a attendu d'être sûrs que le contenu de ce site vous intéresse avant de vous déranger,
                            mais
                            on
                            aimerait
                            bien
                            vous
                            accompagner pendant votre visite…
                            <br />
                            <br />
                            C'est OK pour vous ?
                            <br />
                            <br />
                            <a routerLink="/politique-confidentialite" title="Lire la politique de confidentialité">Lire la politique de confidentialité.</a>
                        </p>
                    </div>

                    <div class="icon">
                        <img src="/assets/spbfamily/cookies.svg" alt="Cookies">
                    </div>
                </div>
            </div>

            <div class="cookie-buttons">
                <button (click)='showMore = true;'>Je choisis</button>
                <button (click)='acceptAll()'>Ok pour moi</button>
            </div>
        </ng-container>

        <ng-container *ngIf='showMore'>
            <div class="cookies-wrapper">
                <div class="cookie-content showMore">
                    <div class="icon">
                        <img src="/assets/spbfamily/cookies.svg" alt="Cookies">
                    </div>
                    <h1 class="primary-color">On vous présente nos cookies !</h1>
                    <p>
                        Sur ce site, nous utilisons des cookies pour mesurer notre audience, entretenir le relation avec
                        vous et vous adresser
                        de temps à autre du contenu qualitatif ainsi que de la publicité. Vous pouvez sélectionner ici
                        ceux
                        que vous autorisez à
                        rester ici.
                    </p>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <h2>Cookies strictement necessaires</h2>
                                </td>
                                <td>
                                    <small class="uppercase">Toujours actif</small>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Ces cookies sont nécessaires au fonctionnement du site et ne peuvent être
                                        désactivés
                                        dans nos systèmes. Ils sont déposés
                                        lorsque vous effectuez le remplissage du formulaire. Vous pouvez configurer
                                        votre
                                        navigateur pour qu'il bloque ou vous
                                        avertisse de l'existence de ces cookies, mais certaines parties du service ne
                                        fonctionneront alors pas.</p>
                                </td>
                                <td>
                                    <app-switch-button [disabled]="true" [checked]="true" [value]="true"
                                        name="essentials">
                                    </app-switch-button>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <h2>Google Analytics</h2>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Permet d'analyser les statistiques de consultation de notre site</p>
                                </td>
                                <td rowspan="2">
                                    <app-switch-button (click)='googleAnalytics = !googleAnalytics' name="google" value='google' [checked]="googleAnalytics" type="checkbox">
                                    </app-switch-button>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <h2>Facebook Pixel</h2>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Permet d'analyser les statistiques de consultation de notre site</p>
                                </td>
                                <td rowspan="2">
                                    <app-switch-button (click)='facebookPixel = !facebookPixel' value="facebook" [checked]="facebookPixel" type="checkbox">
                                    </app-switch-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="cookie-buttons">
                <button (click)='showMore = false' class="showMore">Retour</button>
                <button (click)='acceptAll()' class="showMore">J'accepte tout</button>
                <button (click)='choose()' class="showMore">Terminer</button>
            </div>
        </ng-container>
    </div>

    <div class="link" (click)='showCookies = !showCookies'>
        <img src="/assets/spbfamily/validated-cookies.svg" alt="Cookies" *ngIf='isValidated'>
        <div class="notvalidated" *ngIf='!isValidated'>
            <img src="/assets/spbfamily/cookies.svg" alt="Cookies" class="icon" width="45px" height="45px">
            <img src="/assets/spbfamily/not-validated-cookies.svg" alt="Cookies">
        </div>
    </div>
</div>