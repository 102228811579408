import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Error } from 'src/app/_models/Error';
import { Input } from 'src/app/_models/Input';
import { Souscription } from 'src/app/_models/Souscription';
import { SouscriptionService } from 'src/app/_services/souscription.service';

@Component({
  selector: 'app-quote-fifthstep',
  templateUrl: './quote-fifthstep.component.html',
  styleUrls: ['./quote-fifthstep.component.scss']
})
export class QuoteFifthstepComponent implements OnInit {

  errors: Error[];
  souscription: Souscription;
  selectedInputValue: boolean;
  constructor(public souscriptionService: SouscriptionService, private router: Router) { }

  ngOnInit() {
    this.souscription = this.souscriptionService.getSouscription();

    this.souscriptionService.setCurrentInputs([
      new Input("date_naissance", "Date de naissance", (this.souscription) ? this.souscription.dateNaissance?.toString() : null, true, null, "501000"),
      new Input("ville_naissance", "Ville de naissance", (this.souscription) ? this.souscription.lieuNaissance : null, true, "[a-zA-Z ]*", "502000")
    ])
  }

  nextStep() {
    if (this.souscriptionService.isCurrentStepValid)
      this.souscriptionService.nextStep(4).subscribe(
        () => { this.errors = []; this.router.navigate(['/demande-devis/preference-produit']); },
        err => { this.errors = err; },
      );
    else
      this.errors.push(new Error('global', 'Veillez vérifier tous les champs'));
  }

}
