import { Pipe, PipeTransform } from '@angular/core';
import { TranscriptService } from '../_services/transcript.service';

@Pipe({
    name: 'dynamize',
    pure: false
})
export class DynamizePipe implements PipeTransform {

    constructor(private transcriptService: TranscriptService) { }

    transform(messageValue: string): any {
        return this.transcriptService.transform(messageValue);
    }
}