import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-cookie-modal',
  templateUrl: './side-cookie-modal.component.html',
  styleUrls: ['./side-cookie-modal.component.scss']
})
export class SideCookieModalComponent implements OnInit {

  showCookies: boolean = true;
  showMore: boolean = false;
  isValidated: boolean = false;

  googleAnalytics: boolean = true;
  facebookPixel: boolean = true;


  constructor() { }

  ngOnInit() {
    this.googleAnalytics = (localStorage.getItem('google-analytics') === null || localStorage.getItem('google-analytics') === 'true');
    this.facebookPixel = (localStorage.getItem('facebook-pixel') === null || localStorage.getItem('facebook-pixel') === 'true');
    
    if ((localStorage.getItem('google-analytics') !== null) && (localStorage.getItem('facebook-pixel') !== null)) {
      this.isValidated = true;
      this.showCookies = false;
    }
  }

  acceptAll() {
    this.googleAnalytics = true;
    this.facebookPixel = true;
    localStorage.setItem('google-analytics', 'true');
    localStorage.setItem('facebook-pixel', 'true');
    this.isValidated = true;
    this.showCookies = false;
  }

  choose() {
    localStorage.setItem('google-analytics', this.googleAnalytics?.toString());
    localStorage.setItem('facebook-pixel', this.facebookPixel?.toString());
    this.isValidated = true;
    this.showCookies = false;
  }

}
