<div class="container">
  <div class="row text-center">
    <div class="col-sm-12 col-md-6 mx-auto mt-5">
      <div class="highlight">
        <p>
          Malheureusement votre souscription n'a pas pu aboutir.
          <br />Votre signature est nécessaire pour valider la souscription.
        </p>
        <button (click)="tryAgain()">
          <i class="fas fa-caret-right"></i> &nbsp; Essayer à nouveau
        </button>
      </div>
    </div>
  </div>


</div>