import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Souscription } from 'src/app/_models/Souscription';
import { SouscriptionService } from 'src/app/_services/souscription.service';

@Component({
  selector: 'app-souscription',
  templateUrl: './souscription.component.html',
  styleUrls: ['./souscription.component.scss']
})
export class SouscriptionComponent implements OnInit {

  uid: string;
  souscription: Souscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private souscriptionService: SouscriptionService
  ) {
    this.uid = this.route.snapshot.params.uid;
  }

  ngOnInit() {
    if (!this.souscription) // Load Souscription from server
      this.souscriptionService.getSouscriptionByUID(this.uid).subscribe(
        (souscription) => {
          this.souscription = souscription;
          this.switchToStep();
        },
        (err) =>  // Error Souscription not found : go back to home page
        {
          this.router.navigate(['/'])
        }
        ,
      )
    else
      this.switchToStep();
  }

  switchToStep() {
    let url = '/souscription/' + this.souscription.id;
    switch (this.souscription.step) {
      case 1:
        url = url + '/validation';
        break;
      case 2:
        url = url + '/documents';
        break;
      case 3:
        url = url + '/modalites';
        break;
      case 4:
        url = url + '/recapulatif/success';
        break;
    }
    if (this.souscription.step != 4 && this.souscription.step != -99)
      this.router.navigate([url]);
  }
}
