import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SouscriptionService } from 'src/app/_services/souscription.service';

@Component({
  selector: 'app-global-button-small',
  templateUrl: './global-button-small.component.html',
  styleUrls: ['./global-button-small.component.scss']
})
export class GlobalButtonSmallComponent implements OnInit {

  @Input() action: string;
  @Input() link: string;
  @Input() disabled: boolean;
  @Input() ahref: string;
  @Input() icon: string;

  constructor(private router: Router, public souscriptionService: SouscriptionService) { }

  ngOnInit() {
  }

  onClick() {
    if (this.link && !this.disabled)
      this.router.navigate([this.link]);
    else if (this.action == 'nextStep' && !this.disabled)
      this.souscriptionService.nextStep();
      else if(this.ahref) {
        window.location.href = this.ahref;
      }
  }

}
