import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Souscription } from 'src/app/_models/Souscription';
import { SouscriptionService } from 'src/app/_services/souscription.service';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit {

  product: string; // Nom du produit
  step: number; // Current step
  souscription: Souscription; // Current souscription

  constructor(private router: Router, private souscriptionService: SouscriptionService, private route: ActivatedRoute) {
    this.souscription = this.souscriptionService.getSouscription();
    this.product = this.souscription.product;
    this.step = this.souscription.step;
  }

  ngOnInit() {
    this.makeSwitch();
    this.router.events.subscribe(() => this.makeSwitch());
  }

  makeSwitch() {

    /**
     * validation = 1
     * docuements = 2
     * modalites = 3
     * recapulatif = 4
     */

    switch (this.router.url.split('/')[3]) {
      case 'validation':
        this.step = 1;
        break;

      case 'documents':
        this.step = 2;
        break;

      case 'modalites':
        this.step = 3;
        break;

      case 'recapulatif':
        this.step = 4;
        break;

      default:
        this.router.navigate(['/']);
    }
  }

}
