<ng-container *ngIf='souscription'>

    <div class="gradient-background pt-5">
        <app-steps-bar [steps]="['réalisation de votre devis' , 'DOCUMENTATION  PRECONTRACTUELLE' , 'CHOIX ET PAIEMENT  DE VOS COTISATIONS' , 'CONFIRMATION
        D\'ADHéSION' ]" [currentStep]="2"></app-steps-bar>
    </div>

    <div class="center-item">
        <app-agent imageSRC="../../../assets/spbfamily/Image 2@2x.png"
            agentSpeech="
    {{souscription?.prenom}}, l'autorisation de paiement n'a pas pu être validée.<br/> Nous vous invitons à essayer à nouveau." textPadding="90-padding">
        </app-agent>
    </div>

    <div class="center-item">
        <app-global-button-small routerLink='/souscription/{{souscription.id}}/paiement'>
            réessayer
        </app-global-button-small>
    </div>

</ng-container>