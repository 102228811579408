<button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
</button>

<div class="overflow-auto container" style="height: 500px;">
    <div class="row">
        <table>
            <tr>
                <th></th>
                <th></th>
                <th></th>
            </tr>
            <tr>
                <td class="p-2"></td>
                <th class="p-2"><strong>Cookies essentiels à l'utilisation du site.</strong></th>
                <td class="p-2"><input class="apple-switch" style="opacity:.5; cursor: not-allowed;" type="radio"
                        name="radio-locataire" checked disabled></td>
            </tr>
            <tr>
                <td class="p-2" colspan="3">
                    <u>Cookies essentiels à l'utilisation du site</u> :
                    Sans ces cookies le site ne peut pas fonctionner. Ces cookies ne sont pas de nature commerciale.
                </td>
            </tr>
            <tr>
                <td class="p-2"><img src="./assets/google_icon.png" alt="Google" width="50px" height="50px"></td>
                <th class="p-2">Cookies relatives aux statistiques de Google Analytics.</th>
                <td class="p-2"><input class="apple-switch" type="radio" name="google" [value]="true" checked></td>
            </tr>
            <tr>
                <td class="p-2" colspan="3">
                    <u>Cookies relatives aux statistiques de Google Analytics.</u> :
                    Ces cookies sont totalement anonymes et pour but de faire des statistiques.<br />Ces cookies ne sont
                    pas de nature commerciale.
                </td>
            </tr>
            <tr>
                <td class="p-2"><img src="./assets/facebook_icon.png" alt="Facebook" width="50px" height="50px"></td>
                <th class="p-2">Cookies relatives à l'utilisation de compagnes Facebook.</th>
                <td class="p-2"><input class="apple-switch" type="radio" name="facebook" [value]="true" checked></td>
            </tr>

        </table>
    </div>
</div>

<div class="row">
    <a (click)="accept()" role="button" class="p-3" style="color: rgb(255, 255, 255); background-color: rgb(0, 0, 0);">Tout accepter</a>
    &nbsp;
    <a role="button" class="p-3" style="color: rgb(255, 255, 255); background-color: rgb(0, 0, 0);">Appliquer mes choix</a>
</div>