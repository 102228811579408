<div class="container" [ngClass]='{"d-none": submitted,"d-block": !submitted}'>

    <div class="title">
        <span class="primary-color">
            Montant de votre cotisation<br />{{type}}le à payer :
        </span> {{decimalFormat(price)}} €
    </div>

    <form ngNoForm
        action="{{ env.url }}/souscription/{{souscriptionService.getSouscription()?.id}}/cb/3dsecure"
        method="POST" target="CheckPaymentFrame">
        <div class="center">
            <div class="row p-0 mb-2">
                <div class="position-relative col-12 p-0">
                    <label class="text-uppercase" for="CardNumber">Numéro de la carte*</label>
                    <input  placeholder="1234 1234 1234 1234" id="CardNumber" class="w-100" #cardValue (click)='inputCursorPos(cardValue)' name="CardNumber" [textMask]='maskCard'
                        [value]="souscriptionService.getInputByName('CardNumber').value"
                        [required]="souscriptionService.getInputByName('CardNumber').required"
                        (input)="souscriptionService.setInputValue('CardNumber', cardValue.value, isInputValid(cardValue.value, souscriptionService.getInputByName('CardNumber').pattern))"
                        [ngClass]="{'success': isInputValid(cardValue.value, souscriptionService.getInputByName('CardNumber').pattern)}"
                        >
                </div>
            </div>

            <div class="row p-0">
                <div class="position-relative col-6 mr-1 p-0">
                    <label class="text-uppercase">date d'expiration*</label>
                    <input class="small-inputy " id="ExpDate" type="text" placeholder="MMAA" class="w-100" #expValue  (click)='inputCursorPos(expValue)' [textMask]='maskExpDate'
                        [name]="souscriptionService.getInputByName('ExpDate').name"
                        [value]="souscriptionService.getInputByName('ExpDate').value"
                        [required]="souscriptionService.getInputByName('ExpDate').required"
                        (input)="souscriptionService.setInputValue('ExpDate', expValue.value, isInputValid(expValue.value, souscriptionService.getInputByName('ExpDate').pattern))"
                        [ngClass]="{'success': isInputValid(expValue.value, souscriptionService.getInputByName('ExpDate').pattern)}"
                        >
                </div>
                <div class="position-relative col-6 ml-1 p-0">
                    <label class="text-uppercase">code de sécurité*</label>
                    <input class="small-inputy" id="Crypto" type="text" placeholder="Cryptogramme (CVV / CVC)"
                        class="w-100" #cvcValue name="Crypto" (click)='inputCursorPos(cvcValue)' [textMask]='maskCvv'
                        [value]="souscriptionService.getInputByName('Crypto').value"
                        [required]="souscriptionService.getInputByName('Crypto').required"
                        (input)="souscriptionService.setInputValue('Crypto', cvcValue.value, isInputValid(cvcValue.value, souscriptionService.getInputByName('Crypto').pattern))"
                        [ngClass]="{'success': isInputValid(cvcValue.value, souscriptionService.getInputByName('Crypto').pattern)}"
                        >
                </div>
            </div>

            <div class="row p-0">
                <app-global-checkbox (valueEmitter)='acceptedRenonciation = $event.inputValue'>J'accepte de démarrer
                    les
                    garanties pendant le délai de renonciation de 14 jours tel
                    que
                    prévu dans la Notice d'Information*</app-global-checkbox>
            </div>

            <div class="box px-3 py-1" *ngIf='type == "mensuel"'>
                <ng-container *ngFor="let paiment of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]; let index=index">
                    <span class="fw-bold fs-6 my-3">
                        {{index+1}}<sup>{{ (index==0)? 'ère' : 'ème' }}</sup> échéance le
                        {{dateOf(index)|date:'dd/MM/yy'}}
                    </span>
                    <span style="float: right;" class="fw-bold">
                        {{decimalFormat(price)}} €
                    </span>
                    <br />
                </ng-container>
            </div>

            <div class="mx-auto w-25 mt-2">
                <ng-container *ngIf='errors'>
                    <ng-container *ngFor='let error of errors'><i class="fas fa-exclamation-circle"></i> &nbsp; <span
                            class="text-danger">{{
                            error.message }}</span>
                    </ng-container>
                    <br /><br />
                </ng-container>

                <input type="submit" class="button shadow-box" value="PAYER" (click)="submitted = true" [ngClass]="{'disabled':!acceptedRenonciation || !souscriptionService.isCurrentStepValid}" [disabled]='!acceptedRenonciation || !souscriptionService.isCurrentStepValid'>
            </div>

            <small class="mt-5">*Champs obligatoires</small>
        </div>
    </form>

</div>

<div [ngClass]='{"d-none": !submitted,"d-block": submitted}' style='position: relative; min-width:50vw; min-height: 50vh;'>
    <iframe style="position: absolute; height: 100%; border: none;" width="100%" height="100%" name="CheckPaymentFrame" src="{{ env.url }}/souscription/payment/sandbox/default"></iframe>
</div>