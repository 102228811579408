<div class="container mb-4">
    <div class="primary-title text-center px-2 mt-4 mb-4">
        À chaque situation, une solution !
    </div>

    <div class="row mb-5">
        <div class="col p-2">
            <table class="d-xs-block d-sm-block d-md-none mx-auto"
                style="background-color: rgb(23, 171, 136, 0.1); border-radius: 10pt;">
                <tbody>
                    <tr>
                        <td></td>
                        <td class="pt-4 pr-2">
                            <img src="/assets/spbfamily/logo protect factures@2x.png" alt="Protect Factures" width="171px">
                        </td>
                    </tr>
                    <tr>
                        <td class="text-center p-2 table-text">Facture gaz, eau électricité, fioul</td>
                        <td class="text-center p-2 table-text">Jusqu’à 3000 € <br> par an (250€/mois)</td>
                    </tr>
                    <tr>
                        <td class="text-center p-2 table-text">Loyer résidence principale</td>
                        <td class="text-center p-2 table-text check-invalide">X</td>
                    </tr>
                    <tr>
                        <td class="text-center p-2 table-text">Décés</td>
                        <td class="text-center p-2 table-text check-valide">✓</td>
                    </tr>
                    <tr>
                        <td class="text-center p-2 table-text">PTIA</td>
                        <td class="text-center p-2 table-text check-valide">✓</td>
                    </tr>
                    <tr>
                        <td class="text-center p-2 table-text">ITT</td>
                        <td class="text-center p-2 table-text check-valide">✓</td>
                    </tr>
                    <tr>
                        <td class="text-center p-2 table-text">Perte d'emploi</td>
                        <td class="text-center p-2 table-text check-valide">✓</td>
                    </tr>
                    <tr>
                        <td class="text-center p-2 table-text">Risques couverts</td>
                    </tr>
                </tbody>
            </table>

            <table class="p-3 d-xs-block d-sm-block d-md-none mt-3 mx-auto"
                style="background-color: rgb(23, 171, 136, 0.1); border-radius: 10pt;">
                <tbody>
                    <tr>
                        <td></td>
                        <td class="pt-4 pr-2">
                            <img src="/assets/spbfamily/logo protect habitat@2x.png" alt="Protect Habitat" width="171px">
                        </td>
                    </tr>
                    <tr>
                        <td class="text-center p-2 table-text">Facture gaz, eau électricité, fioul</td>
                        <td class="text-center p-2 table-text">Jusqu’à 3000 € <br> par an (250 €/mois)</td>
                    </tr>
                    <tr>
                        <td class="text-center p-2 table-text">Loyer résidence principale</td>
                        <td class="text-center p-2 table-text">Jusqu'à 9600 € <br> par an (800 €/mois)</td>
                    </tr>
                    <tr>
                        <td class="text-center p-2 table-text">Décès</td>
                        <td class="text-center p-2 table-text check-valide">✓</td>

                    </tr>
                    <tr>
                        <td class="text-center p-2 table-text">PTIA</td>
                        <td class="text-center p-2 table-text check-valide">✓</td>
                    </tr>
                    <tr>
                        <td class="text-center p-2 table-text">ITT</td>
                        <td class="text-center p-2 table-text check-valide">✓</td>
                    </tr>
                    <tr>
                        <td class="text-center p-2 table-text">Perte d'emploi</td>
                        <td class="text-center p-2 table-text check-valide">✓</td>
                    </tr>
                </tbody>
            </table>

            <table class="table mx-auto d-none d-md-block">
                <tbody>
                    <tr>
                        <td class="p-3">&nbsp;</td>
                        <td class="p-3">
                            <img src="/assets/spbfamily/logo protect factures@2x.png" alt="Protect Factures" width="171px">
                        </td>
                        <td class="p-3">
                            <img src="/assets/spbfamily/logo protect habitat@2x.png" alt="Protect Habitat" width="171px">
                        </td>
                    </tr>
                    <tr>
                        <td class="text-center p-2 table-text">Facture gaz, eau <br>électricité, fioul</td>
                        <td class=" text-center p-2 table-text">Jusqu’à 3000 € <br> par an (250 €/mois)</td>
                        <td class=" text-center p-2 table-text">Jusqu’à 3000 € <br> par an (250 €/mois)</td>
                    </tr>
                    <tr>
                        <td class="p-2 table-text">Loyer résidence<br>principale<span class="text-danger">*</span></td>
                        <td class="text-center p-2 check-invalide">X</td>
                        <td class="text-center p-2 table-text">Jusqu’à 9600 € <br> par an (800 €/mois)</td>
                    </tr>
                    <tr>
                        <td class="p-2 table-text">Décès</td>
                        <td class="text-center p-2 check-valide">✓</td>
                        <td class="text-center p-2 check-valide">✓</td>
                    </tr>
                    <tr>
                        <td class="p-2 table-text">PTIA</td>
                        <td class="text-center check-valide">✓</td>
                        <td class="text-center check-valide">✓</td>
                    </tr>
                    <tr>
                        <td class="p-2 table-text">ITT</td>
                        <td class="text-center p-2 check-valide">✓</td>
                        <td class="text-center p-2 check-valide">✓</td>
                    </tr>
                    <tr>
                        <td class="p-2 table-text">Perte d'emploi</td>
                        <td class="text-center p-2 check-valide">✓</td>
                        <td class="text-center p-2 check-valide">✓</td>
                    </tr>
                    <tr>
                        <td class="p-2 table-text" colspan="3"><span class="text-danger">*</span> uniquement si vous êtes locataire</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>