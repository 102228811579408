export class Input {
    name: string;
    placeholder: string;
    value: string;
    pattern: string;
    required: boolean;
    codeError: string;
    isValid: boolean;
    icon: string;
    constructor(
        name: string,
        placeholder?: string,
        value?: string,
        required?: boolean,
        pattern?: string,
        codeError?: string,
        isValid?: boolean,
        icon?: string,
    ) {
        this.name = name;
        this.placeholder = placeholder;
        this.value = value;
        this.required = required;
        this.pattern = pattern;
        this.codeError = codeError;
        this.isValid = isValid;
        this.icon = icon;
    }
}