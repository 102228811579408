import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SouscriptionService } from 'src/app/_services/souscription.service';
import { Input as Input2 } from 'src/app/_models/Input';
import { Error } from 'src/app/_models/Error';
import { NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment'
@Component({
  selector: 'app-creditcard-content-modal',
  templateUrl: './creditcard-content-modal.component.html',
  styleUrls: ['./creditcard-content-modal.component.scss']
})
export class CreditcardContentModalComponent implements OnInit {

  @Input() price: number;
  @Input() type: string;
  acceptedRenonciation: boolean;
  errors: Error[];
  iframeSrc: any = null;
  submitted: boolean;
  env = environment;

  public maskCard = {
    guide: true,
    showMask: true,
    mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]
  };


  public maskExpDate = {
    guide: true,
    showMask: true,
    mask: [/\d/, /\d/, '/', /\d/, /\d/]
  };

  
  public maskCvv = {
    guide: true,
    showMask: true,
    mask: [/\d/, /\d/, /\d/]
  };


  constructor(public souscriptionService: SouscriptionService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.souscriptionService.setCurrentInputs([
      new Input2('CardNumber', null, '', true, '[0-9]{4}[ ]?[0-9]{4}[ ]?[0-9]{4}[ ]?[0-9]{4}', '2202x0001', null),
      new Input2('ExpDate', null, '', true, '[0-3][0-9]/[0-2][0-9]', '2202x0002', null),
      new Input2('Crypto', null, '', true, '[0-9]{3,4}', '2202x0003', null),
    ])
  }

  decimalFormat(decimal: number) {
    return (Math.round(decimal) == decimal) ? decimal : decimal.toFixed(2);
  }

  dateOf(index: number) {
    let date = new Date();
    date.setMonth(date.getMonth() + index);
    return date;
  }


  nextStep() {
    if (this.acceptedRenonciation && this.souscriptionService.isCurrentStepValid) {
      this.souscriptionService.paymentViaCB().subscribe(
        (resp) => {
          this.iframeSrc = this.sanitizer.bypassSecurityTrustHtml(resp.toString());
        },
        err => { },
      );
    }
  }

  isInputValid(value: string, pattern: string): boolean {
    let isValid = (value.match(pattern)?.length > 0) && (value.match(pattern)?.pop() === value);
    return isValid;
  }


  inputCursorPos(input) {
    if (input.value == '__/__' || input.value == '____ ____ ____ ____')
      input.setSelectionRange(0, 0);
  }

}
