import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, finalize, tap, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { OverlayRef, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerComponent } from '../_components/spinner/spinner.component';
import { environment } from 'src/environments/environment'
import { CacheService } from '../_services/cache.service';

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {

  // Pipe to handle congestions and bottleneck
  requestsPipe: HttpRequest<any>[] = [];

  // Returns the current loading status
  private currentStatus: boolean = false;

  // Overlay Reference for spinner
  private overlayRef: OverlayRef = null;

  // Spinner Overlay Portale 
  private spinnerOverlayPortal: ComponentPortal<SpinnerComponent> = null;

  constructor(
    private cacheService: CacheService,
    private overlay: Overlay,
  ) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Split Request.URL into : url | key | id?
    let [url, key, id, option] = request.url.split('|', 4);
    key = (key == 'null') ? null : key;

    // Start loading
    if (option != 'noloader')
      this.startLoading();

    // Add to the pipeline of requests
    this.requestsPipe[request.url] = request;

    // Check if the request is external to the API // Example Geo API
    if (request.url.indexOf('https://') != -1)
      return next.handle(request).pipe(
        catchError(err => {
          // Stop loader
          this.stop(request.url);
          // Throw err
          return throwError(err);
        }),
        finalize(() => {
          this.stop(request.url);
        }))



    // Adding the server URL
    let req = request.clone({
      url: environment.url + url
    })

    // Try cache when the request's method is GET
    if (req.method === 'GET' && key && option != 'updateonly') {

      // Try fetching response from cache
      const cachedResponse = this.cacheService.get(key);

      // Send response from cache
      if (cachedResponse) {

        if (id) {
          let elements = cachedResponse.find((element) => {
            return element.id.toString() == id.toString()
          })
          if (elements) {
            return of(new HttpResponse({
              body: elements,
              status: 200,
              statusText: "OK"
            })).pipe(
              // Finish loading
              finalize(() => {
                this.stop(request.url);
              })
            );
          }
        } else {
          return of(new HttpResponse({
            body: cachedResponse,
            status: 200,
            statusText: "OK"
          })).pipe(
            // Finish loading
            finalize(() => {
              this.stop(request.url);
            })
          );
        }

      }
    }

    // Return the final request with the header
    return next.handle(req).pipe(

      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (key && (event.status >= 200 && event.status < 300))
            this.cacheService.set(key, event.body, null, true)
        }
      }),

      // Request processed : Remove from the pipe
      finalize(() => {
        // Stop loading
        this.stop(request.url);
      })).pipe(shareReplay(1));
  }

  public startLoading() {
    // ONLY IF THE LOADING HAS NOT BEEN STARTED AND NO CONGESTION
    if (!this.currentStatus && Object.keys(this.requestsPipe)?.length == 0) {

      // Set Status
      this.currentStatus = true;

      // Init Spinner
      if (!this.spinnerOverlayPortal)
        this.spinnerOverlayPortal = new ComponentPortal(SpinnerComponent);

      // Init Overlay
      if (!this.overlayRef)
        this.overlayRef = this.overlay.create();

      // Attach Spinner to Overlay
      if (!this.overlayRef.hasAttached())
        this.overlayRef.attach(this.spinnerOverlayPortal);
    }
  }

  public stopLoading() {
    // ONLY IF THE LOADER HAS BEEN STARTED
    if (this.currentStatus && Object.keys(this.requestsPipe)?.length == 0) {
      // Set Status
      this.currentStatus = false;
      // Detach the spinner
      if (!!this.overlayRef)
        this.overlayRef.detach();
    }
  }

  stop(idx) {
    delete this.requestsPipe[idx];
    this.stopLoading();
  }
}