<div class="quota-wrapper">
    <div class="quota-question">
        <app-agent imageSRC="/assets/spbfamily/Image 2@2x.png"
            agentSpeech="Vous préférez assurer uniquement vos factures d'énergie ou également votre loyer ?">
        </app-agent>
    </div>

    <div class="quota-reply row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <app-choice-card name="preference_product" iconSrc="/assets/spbfamily/logo protect factures@2x.png"
                value='8c5af5cb' [iconSelected]="selectedInputValue == '8c5af5cb'"
                (valueEmitter)="setInputValue($event.inputValue)">
                <p class="primary-color fw-bold" style="padding: 32px 0;">
                    J'assure mes factures d'eau,
                    de gaz, d'électricité et de fioul
                </p>
                <p class="secondary-color mt-3">
                    <strong>Indemnités :</strong>
                    <br>Jusqu’à 250€/mois pendant 1 an
                </p>
                <p class="primary-color mt-3 link">
                    <a href="/Notice-Ipid-Protect-Factures.pdf" target="_blank">
                        Document d'information et de
                        conseil
                        Notice d'information</a>
                </p>
            </app-choice-card>
        </div>

        <div class="d-md-block d-lg-none mt-5 w-100"></div>

        <div class="col-sm-12 col-md-6">
            <app-choice-card name="preference_product" iconSrc="/assets/spbfamily/logo protect habitat@2x.png"
                extra="La protection la plus complète pour vous !" value='981ca9ee'
                [iconSelected]="selectedInputValue == '981ca9ee'" (valueEmitter)="setInputValue($event.inputValue)">
                <p class="primary-color fw-bold mt-4">
                    Je couvre mon loyer<br />
                    <img src="/assets/spbfamily/add.png" alt="Plus"><br />
                    mes factures d'eau, de gaz, d'électricité et de fioul
                </p>
                <p class="secondary-color mt-3">
                    <strong>Indemnités :</strong>
                    <br>Jusqu’à 1050€/mois pendant 1 an
                </p>
                <p class="primary-color mt-3 link">
                    <a href="/Notice-Ipid-Protect-Habitat.pdf" target="_blank">
                        Document d'information et de
                        conseil
                        Notice d'information</a>
                </p>
            </app-choice-card>
        </div>
    </div>

    <div class="quota-reply mt-4 mb-4">
        <div class="center-item">
            <ng-container *ngIf='errors'>
                <ng-container *ngFor='let error of errors'><i class="fas fa-exclamation-circle"></i> &nbsp; <span
                        class="text-danger">{{
                        error.message }}</span>
                    <br /><br />
                </ng-container>
            </ng-container>

            <app-global-button-small [disabled]='!souscriptionService.isCurrentStepValid || !selectedInputValue'
                *ngIf='souscriptionService.isCurrentStepValid != null' (click)='nextStep()'>Continuer
            </app-global-button-small>
        </div>
    </div>
</div>