import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EntryPointComponent } from './_middlewares/entry-point/entry-point.component';
import { ErrorPageComponent } from './_pages/error-page/error-page.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SpinnerComponent } from './_components/spinner/spinner.component';
import { HttpClientInterceptor } from './_interceptors/http.interceptor';
import { OverlayModule } from '@angular/cdk/overlay';
import { MessengerComponent } from './_components/messenger/messenger.component';
import { SouscriptionComponent } from './_pages/souscription/souscription.component';
import { HeaderComponent } from './_components/header/header.component';
import { BreadcrumbComponent } from './_components/breadcrumb/breadcrumb.component';
import { StepsComponent } from './_components/steps/steps.component';
import { ValidationSectionComponent } from './_sections/validation-section/validation-section.component';
import { DocumentsSectionComponent } from './_sections/documents-section/documents-section.component';
import { SepaSectionComponent } from './_sections/sepa-section/sepa-section.component';
import { RecapulatifSectionComponent } from './_sections/recapulatif-section/recapulatif-section.component';
import { ResultSectionComponent } from './_sections/result-section/result-section.component';
import { MaterialModule } from './materials.module';
import { FormsModule } from '@angular/forms';
import { DynamizePipe } from './_pipes/dynamize.pipe';
import { OneyPageComponent } from './_partners/_oney/oney-page/oney-page.component';
import { ContactFormComponent } from './_partners/_oney/oney-page/contact-form/contact-form.component';
import { CallBackFormComponent } from './_partners/_oney/oney-page/call-back-form/call-back-form.component';
import { DateValueAccessorModule } from 'angular-date-value-accessor';
import { CookiesModalComponent } from './_partners/_oney/oney-page/cookies-modal/cookies-modal.component';
import { LengthPipe } from './_pipes/length.pipe';
import { SpbfamilyPageComponent } from './_partners/_spbfamily/_pages/spbfamily-page/spbfamily-page.component';
import { HeroHeaderComponent } from './_components/hero-header/hero-header.component';
import { SimpleCardComponent } from './_components/simple-card/simple-card.component';
import { TestimonialsHeaderComponent } from './_components/testimonials-header/testimonials-header.component';
import { FullCardComponent } from './_components/full-card/full-card.component';
import { ButtonGComponent } from './_components/global-button/button-g.component';
import { AssurenceExpoComponent } from './_partners/_spbfamily/_sections/offre-section/assurence-expo.component';
import { CookiesBarComponent } from './_components/cookies-bar/cookies-bar.component';
import { FooterComponent } from './_components/footer/footer.component';
import { ComparisonTableComponent } from './_components/comparison-table/comparison-table.component';
import { SpbfGrantiesComponent } from './_partners/_spbfamily/_sections/avantages-section/spbf-granties.component';
import { GlobalHeaderComponent } from './_components/global-header/global-header.component';
import { AgentComponent } from './_components/agent/agent.component';
import { ProgressBarComponent } from './_components/progress-bar/progress-bar.component';
import { InputFieldComponent } from './_components/input-field/input-field.component';
import { AccordionItemsComponent } from './_components/accordion-items/accordion-items.component';
import { CheckIconComponent } from './_components/check-icon/check-icon.component';
import { GlobalButtonSmallComponent } from './_components/global-button-small/global-button-small.component';
import { QuoteFirstStepComponent } from './_partners/_spbfamily/_modules/_quote/quote-first-step/quote-first-step.component';
import { QuoteContainerComponent } from './_partners/_spbfamily/_modules/_quote/quote-container/quote-container.component';
import { GlobalCheckboxComponent } from './_components/global-checkbox/global-checkbox.component';
import { QuoteSecondStepComponent } from './_partners/_spbfamily/_modules/_quote/quote-second-step/quote-second-step.component';
import { GlobalButtonInvertComponent } from './_components/global-button-invert/global-button-invert.component';
import { SwitchButtonComponent } from './_components/switch-button/switch-button.component';
import { PaymentMethodComponent } from './_components/payment-method/payment-method.component';
import { ChoiceCardComponent } from './_components/choice-card/choice-card.component';
import { AccordionContainerComponent } from './_components/accordion-container/accordion-container.component';
import { PriceTagComponent } from './_components/price-tag/price-tag.component';
import { QuoteThirdStepComponent } from './_partners/_spbfamily/_modules/_quote/quote-third-step/quote-third-step.component';
import { QuoteFourthStepComponent } from './_partners/_spbfamily/_modules/_quote/quote-fourth-step/quote-fourth-step.component';
import { StepsBarComponent } from './_components/steps-bar/steps-bar.component';
import { QuoteFifthstepComponent } from './_partners/_spbfamily/_modules/_quote/quote-fifthstep/quote-fifthstep.component';
import { BirthdateComponent } from './_components/birthdate/birthdate.component';
import { QuoteSixthStepComponent } from './_partners/_spbfamily/_modules/_quote/quote-sixth-step/quote-sixth-step.component';
import { QuoteSeventhStepComponent } from './_partners/_spbfamily/_modules/_quote/quote-seventh-step/quote-seventh-step.component';
import { SouscriptionFirstStepComponent } from './_partners/_spbfamily/_modules/_souscription/souscription-first-step/souscription-first-step.component';
import { SouscriptionContainerComponent } from './_partners/_spbfamily/_modules/_souscription/souscription-container/souscription-container.component';
import { SouscriptionSecondStepComponent } from './_partners/_spbfamily/_modules/_souscription/souscription-second-step/souscription-second-step.component';
import { DownloadButtonComponent } from './_components/download-button/download-button.component';
import { PanelComponent } from './_components/panel/panel.component';
import { OngletComponent } from './_components/onglet/onglet.component';
import { FileViewerComponent } from './_components/file-viewer/file-viewer.component';
import { CommonModule } from '@angular/common';
import { QuoteEitherStepComponent } from './_partners/_spbfamily/_modules/_quote/quote-either-step/quote-either-step.component';
import { CardIconListComponent } from './_components/card-icon-list/card-icon-list.component';
import { SideCookieModalComponent } from './_components/side-cookie-modal/side-cookie-modal.component';
import { ModalRappelComponent } from './_components/modal-rappel/modal-rappel.component';
import { SouscriptionThirdStepComponent } from './_partners/_spbfamily/_modules/_souscription/souscription-third-step/souscription-third-step.component';
import { PaymentMethodModalComponent } from './_partners/_spbfamily/_modals/payment-method-modal/payment-method-modal.component';
import { MatDialog } from '@angular/material';
import { CreditcardContentModalComponent } from './_partners/_spbfamily/_modals/creditcard-content-modal/creditcard-content-modal.component';
import { SouscriptionThirdStepBankInfosComponent } from './_partners/_spbfamily/_modules/_souscription/souscription-third-step-bank-infos/souscription-third-step-bank-infos.component';
import { SouscriptionThirdStepSignatureComponent } from './_partners/_spbfamily/_modules/_souscription/souscription-third-step-signature/souscription-third-step-signature.component';
import { SouscriptionFinalStepComponent } from './_partners/_spbfamily/_modules/_souscription/souscription-final-step/souscription-final-step.component';
import { HelpModalComponent } from './_partners/_spbfamily/_modals/help-modal/help-modal.component';
import { QuoteComebackComponent } from './_partners/_spbfamily/_modules/_quote/quote-comeback/quote-comeback.component';
import { GoogleMapsModule } from '@angular/google-maps'
import { TextMaskModule } from 'angular2-text-mask';
import { SouscriptionEchecComponent } from './_partners/_spbfamily/_modules/_souscription/souscription-echec/souscription-echec.component';
import { ModalitePaiementSectionComponent } from './_sections/modalite-paiement-section/modalite-paiement-section.component';
import { ResultKoSectionComponent } from './_sections/result-ko-section/result-ko-section.component';
import { OneyFormPageComponent } from './_partners/_oney/oney-form-page/oney-form-page.component';

@NgModule({
  declarations: [
    AppComponent,
    EntryPointComponent,
    ErrorPageComponent,
    SpinnerComponent,
    MessengerComponent,
    SouscriptionComponent,
    HeaderComponent,
    BreadcrumbComponent,
    StepsComponent,
    ValidationSectionComponent,
    DocumentsSectionComponent,
    ModalitePaiementSectionComponent,
    SepaSectionComponent,
    RecapulatifSectionComponent,
    ResultSectionComponent,
    DynamizePipe,
    OneyPageComponent,
    ContactFormComponent,
    CallBackFormComponent,
    SouscriptionComponent,
    CookiesModalComponent,
    LengthPipe,
    SpbfamilyPageComponent,
    HeroHeaderComponent,
    SimpleCardComponent,
    FullCardComponent,
    ButtonGComponent,
    AssurenceExpoComponent,
    TestimonialsHeaderComponent,
    FullCardComponent,
    CookiesBarComponent,
    FooterComponent,
    ComparisonTableComponent,
    SpbfGrantiesComponent,
    GlobalHeaderComponent,
    AgentComponent,
    ProgressBarComponent,
    InputFieldComponent,
    AccordionItemsComponent,
    CheckIconComponent,
    GlobalButtonSmallComponent,
    QuoteFirstStepComponent,
    QuoteContainerComponent,
    GlobalCheckboxComponent,
    QuoteSecondStepComponent,
    GlobalButtonInvertComponent,
    SwitchButtonComponent,
    PaymentMethodComponent,
    ChoiceCardComponent,
    AccordionContainerComponent,
    PriceTagComponent,
    QuoteThirdStepComponent,
    QuoteFourthStepComponent,
    StepsBarComponent,
    QuoteFifthstepComponent,
    BirthdateComponent,
    QuoteSixthStepComponent,
    QuoteSeventhStepComponent,
    SouscriptionFirstStepComponent,
    SouscriptionContainerComponent,
    SouscriptionSecondStepComponent,
    DownloadButtonComponent,
    PanelComponent,
    OngletComponent,
    FileViewerComponent,
    QuoteEitherStepComponent,
    CardIconListComponent,
    SideCookieModalComponent,
    ModalRappelComponent,
    SouscriptionThirdStepComponent,
    PaymentMethodModalComponent,
    CreditcardContentModalComponent,
    SouscriptionThirdStepBankInfosComponent,
    SouscriptionThirdStepSignatureComponent,
    SouscriptionFinalStepComponent,
    HelpModalComponent,
    QuoteComebackComponent,
    SouscriptionEchecComponent,
    ResultKoSectionComponent,
    OneyFormPageComponent,
  ],
  entryComponents: [
    SpinnerComponent,
    CallBackFormComponent,
    ContactFormComponent,
    SouscriptionComponent,
    CookiesModalComponent,
    PaymentMethodModalComponent,
    ModalRappelComponent,
    HelpModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    OverlayModule,
    MaterialModule,
    FormsModule,
    DateValueAccessorModule,
    CommonModule,
    GoogleMapsModule,
    TextMaskModule
  ],
  providers: [
    DynamizePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
