import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Contact } from 'src/app/_models/Contact';
import { FormsService } from 'src/app/_services/forms.service';


@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {


  contact: Contact;
  error: string;
  errno: number;
  ok: boolean;
  hp: string;


  constructor(public dialog: MatDialog,
    private route: ActivatedRoute,
    private formsService: FormsService) { }

  ngOnInit() {
    this.contact = new Contact();
  }

  closeModal() {
    this.dialog.closeAll();
  }

  send() {
    if (this.hp)
      return;

    // Valider le prénom
    if (!this.contact.prenom || !this.contact.prenom.match(/[a-zA-Z]+/g)) {
      this.error = 'Le champ "Prénom" est invalide.';
      this.errno = 2;
      return;
    }

    // Valider le nom
    if (!this.contact.nom || !this.contact.nom.match(/[a-zA-Z]+/g)) {
      this.error = 'Le champ "Nom" est invalide.';
      this.errno = 3;
      return;
    }

    // Valider l'email 
    if (!this.contact.email || !this.contact.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g)) {
      this.error = 'Le champ "E-mail" est invalide.';
      this.errno = 4;
      return;
    }

    // Valider le num mobile
    if (!this.contact.tel || !this.contact.tel.match(/^((\+)33|0|0033)[1-9](\d{2}){4}$/g)) {
      this.error = 'Le champ "Téléphone" est invalide.';
      this.errno = 5;
      return;
    }

    // Valider le message
    if (!this.contact.message || this.contact?.message?.trim()?.length < 120 || this.contact?.message?.trim()?.length > 1000) {
      this.error = 'Votre message doit comporter au moins 120 caractères et 1000 caractères max.';
      this.errno = 6;
      return;
    }

    // Valider l'optin
    if (this.contact.optim !== true) {
      this.error = 'Vous devez accepter d\'être recontacté par un conseiller SPB Family (partenaire de la société Oney).';
      return;
    }

    // UTM
    this.route.queryParams.subscribe(
      (params) => {
        this.contact.utm_source = params['utm_source'];
        this.contact.utm_medium = params['utm_medium'];
        this.contact.utm_campaign = params['utm_campaign'];
        this.contact.site_id = params['siteid'];
      },
      () => { },
    )


    // Envoyer la demande
    this.formsService.sendForm('contact', this.contact).subscribe(
      () => this.ok = true,
      (err) => this.error = err.error.error,
    );
  }

}
