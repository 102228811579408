import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalRappelComponent } from 'src/app/_components/modal-rappel/modal-rappel.component';
import { Souscription } from 'src/app/_models/Souscription';
import { SouscriptionService } from 'src/app/_services/souscription.service';

@Component({
  selector: 'app-quote-either-step',
  templateUrl: './quote-either-step.component.html',
  styleUrls: ['./quote-either-step.component.scss']
})
export class QuoteEitherStepComponent implements OnInit {

  imgSRC: string;
  product: any;
  souscription: Souscription;

  constructor(private dialog: MatDialog, private souscriptionService: SouscriptionService) { }

  ngOnInit() {
    this.souscription = this.souscriptionService.getSouscription();
    this.souscriptionService.nextStep(7).subscribe(
      (souscription) => {
        this.product = {
          'id': souscription.product,
          'name': souscription.nameProduct,
          'tarifAnnuel': souscription.tarifAnnuel,
          'tarifMensuel': souscription.tarifMensuel
        }
        if (this.product.id == '981ca9ee')
          this.imgSRC = "/assets/spbfamily/logo protect habitat@2x.png";
        else
          this.imgSRC = "/assets/spbfamily/logo protect factures@2x.png";
      },
      () => { }
    )
  }


  openCallMeBackModal() {
    let dialogRef = this.dialog.open(ModalRappelComponent, {
      height: 'max-content',
      width: 'max-content',
      panelClass: 'noPaddingDialog'
    });
  }

  decimalValue(str: string) {
    return (str?.split('.').length > 1)? Number.parseFloat(str).toFixed(2) : str;
  }

}
