<div class="quota-wrapper">
    <div class="quota-question">
        <app-agent imageSRC="/assets/spbfamily/Image 2@2x.png"
            agentSpeech="{{souscription?.prenom}}, exercez-vous une activité professionnelle ?">
        </app-agent>
    </div>

    <div class="quota-reply">
        <app-check-icon displayTheme="sm-radio-button-with-icon" icon='/assets/spbfamily/Composant2258@2x.png'
            iconCheck='/assets/spbfamily/Composant2259@2x.png' name="emploi"[isChecked]="souscriptionService.getInputByName('salarie').value == '1'" value="1" (valueEmitter)="setInputValue($event.inputValue)">Oui
        </app-check-icon>

        <app-check-icon displayTheme="sm-radio-button-with-icon" icon='/assets/spbfamily/Composant2258@2x.png'
            iconCheck='/assets/spbfamily/Composant2259@2x.png' name="emploi" [isChecked]="souscriptionService.getInputByName('salarie').value == '2'" value="2" (valueEmitter)="setInputValue($event.inputValue)">Non
        </app-check-icon>
    </div>

    <div class="quota-reply">
        <div class="center-item mt-2">
            <ng-container *ngIf='errors'>
                <ng-container *ngFor='let error of errors'><i class="fas fa-exclamation-circle"></i> &nbsp; <span
                        class="text-danger">{{
                        error.message }}</span>
                    <br /><br />
                </ng-container>
            </ng-container>

            <app-global-button-small [disabled]='!souscriptionService.isCurrentStepValid'
                *ngIf='souscriptionService.isCurrentStepValid != null' (click)='nextStep()'>Continuer
            </app-global-button-small>
        </div>
    </div>
</div>