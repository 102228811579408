<div class="gradient-background pt-5">
    <app-steps-bar [steps]="['réalisation de votre devis' , 'DOCUMENTATION  PRECONTRACTUELLE' , 'CHOIX ET PAIEMENT  DE VOS COTISATIONS' , 'CONFIRMATION
        D\'ADHéSION' ]" [currentStep]="2"></app-steps-bar>
</div>

<div class="block-center">
    <app-agent imageSRC="../../../assets/spbfamily/Image 2@2x.png"
        agentSpeech="
    {{souscription?.prenom}},<br/>
    pour finaliser votre souscription vous devez signer électroniquement votre mandat SEPA">
    </app-agent>
</div>

<iframe [src]="url" frameborder="0" *ngIf='url' width="100%" height="450px" style="background: #CCC"></iframe>