import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Error } from 'src/app/_models/Error';
import { Input } from 'src/app/_models/Input';
import { Souscription } from 'src/app/_models/Souscription';
import { SouscriptionService } from 'src/app/_services/souscription.service';

@Component({
  selector: 'app-quote-third-step',
  templateUrl: './quote-third-step.component.html',
  styleUrls: ['./quote-third-step.component.scss']
})
export class QuoteThirdStepComponent implements OnInit {

  souscription: Souscription;
  selectedInputValue: number;
  errors: Error[] = [];

  constructor(public souscriptionService: SouscriptionService,
    private router: Router) { }

  ngOnInit() {
    this.souscription = this.souscriptionService.getSouscription();

    this.souscriptionService.setCurrentInputs([
      new Input("locataire", null, (this.souscription?.locataire === true) ? '1' : ((this.souscription?.locataire === false) ? '2': null), true, null, "301000"),
    ])

    this.souscriptionService.isCurrentStepValid = (this.souscription?.locataire !== null); 
  }

  setInputValue(value: any) {
    this.selectedInputValue = Number.parseInt(value);
    this.souscriptionService.setInputValue('locataire', value, true);
    this.souscriptionService.isCurrentStepValid = true;
  }

  nextStep() {
    if (this.souscriptionService.isCurrentStepValid)
      this.souscriptionService.nextStep(2).subscribe(
        () => { this.errors = []; this.router.navigate(['/demande-devis/situation-professionnelle']); },
        err => { this.errors = err },
      );
    else
      this.errors.push(new Error('global', 'Merci de faire un choix'));
  }

}
