<ng-container *ngIf='souscription'>

    <div class="gradient-background pt-5">
        <app-steps-bar [steps]="['réalisation de votre devis' , 'DOCUMENTATION  PRECONTRACTUELLE' , 'CHOIX ET PAIEMENT  DE VOS COTISATIONS' , 'CONFIRMATION
        D\'ADHéSION' ]" [currentStep]="3"></app-steps-bar>
    </div>

    <div class="center-item">
        <app-agent imageSRC="../../../assets/spbfamily/Image 2@2x.png" agentSpeech="
    {{souscription?.prenom}}, félicitations, vous avez assuré ! Vous êtes à présent protégé.e <img src='/assets/spbfamily/smiley.svg' width='20px'/>
    <br />
    <br />
    Un mail vient de vous être envoyé avec vos identifiants de connexion à votre espace client où vous<br>
    pourrez télécharger vos documents contractuels." textPadding="90-padding">
        </app-agent>
    </div>

    <div class="center-item">
        <app-global-button-small ahref='https://spbfamily.souscription.eu'>accéder à mon espace client
        </app-global-button-small>
    </div>

    <div class="block-center mt-4">
        <app-card-icon-list [items]="list"></app-card-icon-list>
    </div>

    <div class="gradient-background mt-4 border-0">
        <h1 class="primary-color text-center my-4 pt-4">Merci de votre confiance</h1>
        <p class="text-center my-5">
            Une question ? Besoin d'aide ?<br /><br />
            Nous restons à votre écoute au 05 24 44 19 54<br /><br />
            Un conseiller SPB Family prendra votre appel du lundi au vendredi de 9h à 18h.<br /><br />
            Vous pouvez également nous envoyer un mail à 
            <a href="mailto:serviceclient@spbfamily.eu"><u>serviceclient@spbfamily.eu</u></a>
            <br /><br />
           <a href="https://spbfamily.eu">
               <img src="/assets/spbfamily/logo-spb-family-200.png" alt="SPB Family">
           </a>
        </p>
    </div>
</ng-container>