import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-price-tag',
  templateUrl: './price-tag.component.html',
  styleUrls: ['./price-tag.component.scss']
})
export class PriceTagComponent implements OnInit {

  @Input() clientName: string;
  @Input() imgSRC: string;
  @Input() price: string;
  @Input() small: string;
  @Input() name: string;
  @Input() value: string;

  firstPartPrice: string;
  secondPartPrice: string;

  constructor() { }

  ngOnInit() {
    this.price = this.price.replace(',', '.');
    [this.firstPartPrice, this.secondPartPrice] = this.price.split('.');
    this.secondPartPrice = (Number.parseInt(this.secondPartPrice) < 10) ? this.secondPartPrice + '0' : this.secondPartPrice;
  }
}
