<div style="position: relative; min-height: 500px">
    <ng-container *ngIf="source">
                <object [data]="source" type="application/pdf" width="100%" height="500px">
                    <p class="text-center pt-5">
                        Votre navigateur ne prend pas en charge l'affichage des PDF.
                        <br/>Téléchargez vos documents en cliquant sur le bouton ci-dessus.
                    </p>
                </object>
    </ng-container>

    <ng-container *ngIf="!source">
        <div class="loadingio-spinner-rolling-l2dtm1pmlhq">
            <div class="ldio-m5m6bybz1ml">
                <div></div>
            </div>
        </div>
    </ng-container>
</div>