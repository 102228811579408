<div class="quota-wrapper">
    <div class="quota-question">
        <app-agent imageSRC="../../../assets/spbfamily/Image 2@2x.png" [agentSpeech]="
                (souscriptionService.currentMarkerFound != undefined)
                ? (selectedIndex != 0)
                    ? '<strong>' + souscriptionService.currentInputs[0].value +'</strong> ? C\'est noté !'
                    : 'Est-ce votre adresse ?<br/><strong>' + souscriptionService.addressFound + ', '+ souscriptionService.zipCodeFound + ', ' + souscriptionService.cityFound + '</strong>'
                : 'Enchantée '+souscription?.prenom +',<br/>Où habitez-vous ?' 
                ">
        </app-agent>
    </div>

    <div class="quota-reply" *ngIf='souscriptionService.currentMarkerFound != undefined'>
        <google-map height='150' style="border-radius: 15px;" [center]='souscriptionService.currentMarkerFound'>
            <map-marker [position]="souscriptionService.currentMarkerFound">
            </map-marker>
        </google-map>
    </div>

    <div class="quota-reply">
        <div class="flex-100 px-0 pb-0">
            <app-input-field minInputLength='5' icon="/assets/spbfamily/pin.svg"
                [placeholder]="souscriptionService.currentInputs[0].placeholder"
                [name]="souscriptionService.currentInputs[0].name" [value]="souscriptionService.currentInputs[0].value"
                [pattern]="souscriptionService.currentInputs[0].pattern"
                [required]="souscriptionService.currentInputs[0].required" (valueEmitter)="suggest($event.inputValue)">
            </app-input-field>
        </div>

    </div>

    <ng-container *ngIf='suggestions?.length > 0'>
        <div class="quota-reply suggest link" [ngClass]="{'selectedSuggestion': index == selectedIndex}"
            *ngFor='let suggestion of suggestions; let index=index' (click)='changeAddress(index)'>{{
            suggestion.address
            }}, {{ suggestion.zipcode }}, {{ suggestion.city }}</div>
    </ng-container>


    <div class="quota-reply">
        <ng-container *ngIf='errors'>
            <ng-container *ngFor='let error of errors'><i class="fas fa-exclamation-circle"></i> &nbsp; <span
                    class="text-danger">{{
                    error.message }}</span>
            </ng-container>
            <br /><br />
        </ng-container>
    </div>
    <div class="quota-reply center-item mt-2 text-center">
        <app-global-button-small [disabled]='!souscriptionService.isCurrentStepValid'
            *ngIf='souscriptionService.isCurrentStepValid != null' (click)='nextStep()'>
            Continuer
        </app-global-button-small>
    </div>

</div>