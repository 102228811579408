import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Step } from '../_models/Step';
import { map } from 'rxjs/operators';
import { TranscriptService } from './transcript.service';
import { CacheService } from './cache.service';


@Injectable({ providedIn: 'root' })

export class MessengerService {

    constructor(
        private http: HttpClient,
        private transcriptService: TranscriptService,
        private cacheService:CacheService
    ) { }

    reply(step: string, isPrevious?: boolean, data?: FormData) {
        let url = (isPrevious) ? `/reply/${step}/previous` : `/reply/${step}/next`;
        return this.http.post<Step>(url, data).pipe(
            map(step => {
                
                if (step.data) {
                    let firstKey: string = Object.keys(step.data)[0];
                    (firstKey)
                        ? this.cacheService.set(firstKey, step.data[firstKey], null, true)
                        : null;
                }

                step.messages.forEach(
                    (message, index) =>
                        step.messages[index].value = this.transcriptService.transform(message.value))
                    
                return step;
            })
        )
    }

}