<div class="position-relative">

    <ng-container *ngIf='step == 0'>
        <h1 class="primary-color">Mode de paiement</h1>
        <p>Vous optez pour le <strong>paiement {{ data.type }}</strong>, choisissez votre méthode de paiment préféré.</p>
        <app-payment-method icon="/assets/spbfamily/credit-card.svg" (click)="step = 1">Carte de crédit VISA -
            Mastercard</app-payment-method>
        <app-payment-method icon="/assets/spbfamily/iban.svg" (click)="navigateToBank()">Prélèvement bancaire IBAN
        </app-payment-method>
    </ng-container>

    <ng-container *ngIf='step == 1'>
        <app-creditcard-content-modal [price]='price' [type]='data.type'></app-creditcard-content-modal>
    </ng-container>

    <div class="close link" (click)='dialogRef.close()'>
        <img src="/assets/spbfamily/x-mark.png" alt="Fermer" width="20px">
    </div>
</div>