import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Error } from 'src/app/_models/Error';
import { Input } from 'src/app/_models/Input';
import { SouscriptionService } from 'src/app/_services/souscription.service';

@Component({
  selector: 'app-souscription-third-step-bank-infos',
  templateUrl: './souscription-third-step-bank-infos.component.html',
  styleUrls: ['./souscription-third-step-bank-infos.component.scss']
})
export class SouscriptionThirdStepBankInfosComponent implements OnInit {

  errors: Error[];
  type: string;
  check1: boolean;
  check2: boolean;
  checkPrel5: boolean = true;
  checkPrel12: boolean;
  textPrice: string;
  date: number = 5; // date de prélévement; jour préféré (5 ou 12); default = 5

  constructor(public souscriptionService: SouscriptionService, private route: ActivatedRoute, private router:Router) {
    this.type = (this.route.snapshot.params.type == 'mensuel') ? 'mensuel' : 'annuel';
    this.textPrice = (this.type == 'mensuel') ? `Le montant prélevé mensuellement sera de ${this.souscriptionService.getSouscription().tarifMensuel} €` : `Le montant prélevé annuellement sera de ${this.souscriptionService.getSouscription().tarifAnnuel} €`;
  }

  ngOnInit() {
    this.souscriptionService.setCurrentInputs([
      new Input('iban', 'Votre IBAN', '', true, '[a-zA-Z0-9]{25,34}', '1010x0001', null, 'assets/spbfamily/iban_text.svg'),
      new Input('bic', 'Votre BIC', '', true, '[a-zA-Z0-9]{8,11}', '1010x0002', null, 'assets/spbfamily/bic_text.svg'),
    ])
  }

  nextStep() {
    if (this.souscriptionService.isCurrentStepValid) {
      if (this.check1 && this.check2) {
        this.errors = [];
        this.souscriptionService.paymentViaBank(this.date, this.type).subscribe(
          () => { this.router.navigate(['/souscription/paiement/signature']) },
          (err) => {
            this.errors = [
              new Error('global', err.error.error)
          ]},
        );
      } else {
        this.errors = [
          new Error('global', "Merci d'accepter l'autorisation pour pouvoir continuer.")
        ];
      }
    } else {
      this.errors = [
        new Error('global', "Merci de remplir l'IBAN/BIC pour pouvoir continuer.")
      ];
    }

  }

  setDate(checked, value) {
    if (checked)
      this.date = Number.parseInt(value);
  }

}
